
import React from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import "./index.css";
import EventForm from './EventForm';
import HelmetTag from '../Shared/HelmetTag';
import NavbarRedesignForMarketplace from '../Shared/NavbarRedesignForMarketplace';
import { isEventBooking } from '../../constants';
import { getGuestCart } from '../BookingFlowForMarketplace/Shared/helpers';
import { get } from 'lodash';
import GenericAuthModal from '../Shared/GenericAuthModal';

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mainEvent: {},
            showLogin: false,
            isSignin: true,
        };
        this.authModal = this.authModal.bind(this);
    }
    componentDidMount() {
        if (isEventBooking()) {
            const parsedParams = queryString.parse(window.location.search);
            getGuestCart(parsedParams['event-organizer'], (response_1) => {
                const cart_1 = get(response_1, 'data.carts.cart', null);
                if (cart_1) {
                    this.setState({ mainEvent: cart_1, });
                }
            }, (error) => {
                console.log({ error });
            });

        }
        // I need to know what endpoints to use here if
        // TODO should I support 2 cases here (guest and loggedin user)?
        // happens only going backwards in the flow ? or by url param passed
    }
    authModal() {
        if (this.state.showLogin) {
            return (
                <GenericAuthModal
                    next={() => this.setState({ showLogin: false })}
                    closeModal={() => this.setState({ showLogin: false })}
                    isSignin={this.state.isSignin}
                    jul_2024_booking_enhancements={'new_flow'}
                />
            );
        }
        return null;
    }
    render() {
        const loggedIn = get(this.props, 'client.loggedIn', false);
        return (<>
            <div>
                <HelmetTag />

                <NavbarRedesignForMarketplace
                    hideGoBack
                    showMenu={loggedIn}
                    goToAuthentication={(isSignin) => this.setState({ showLogin: true, isSignin })}
                />
                <div className="container mt-big">
                    {/* <div className="maxW-75-vw"> */}
                    <EventForm {...this.state.mainEvent} />
                    {/* TODO container need to be fixed*/}
                    {/* </div> */}
                </div>
            </div>
            {this.authModal()}
        </>
        );
    }
}
const mapStateToProps = (state) => ({
    client: state.client,
});
export default connect(mapStateToProps, {})(Index);