/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import {
  find, get, includes, isEmpty, split,
} from 'lodash';
import axios from 'axios';
import {
  AccountCircle, AccountCircleOutlined, ArrowBack,
  EventAvailable, EventAvailableOutlined, MenuBook, Message, MessageOutlined,
} from '@material-ui/icons';
import { Link, Redirect } from 'react-router-dom';
import {
  Badge, BottomNavigation, BottomNavigationAction, Button, withStyles,

  Menu, MenuItem, IconButton, Toolbar, AppBar,
} from '@material-ui/core';
import queryString from 'query-string';
import { blankHref, hasUuid, ROUTES } from '../../../constants';
import FetchClientContainer from '../../Client/FetchClient';
import { logoutEvent, navigationViewHomepage } from '../WebAnalytics';
import logoBlue from '../../../Assets/Images/Soothe-logo-blue-svg.svg';
import userAvatarPlaceholder from '../../../Assets/Images/User_avatar.png';
import './Assets/Styles/Navbar.css';
import { ACCEPT_LANGUAGE_HEADER, HOST_ROOT } from '../../../apiConfig';
import { loadClient, setChats } from '../../../Actions';
import ForcedTermsModal from '../ForcedTermsModal';
import { getChatsOfCurrentUser } from '../Helpers';
import ResetPasswordPopups from '../ResetPasswordPopups';

const useStyles = (theme) => ({
  root: {
    alignItems: 'center',
    flexGrow: 1,
    '& .MuiAppBar-colorPrimary': {
      backgroundColor: '#FFF',
      color: '#586B94',
    },
    '& .MuiIconButton-label .MuiSvgIcon-root': {
      height: '48px',
      width: '48px',
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
});
class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      scrolledDown: false,
      anchorEl: null,
      redirect: false,
      pathname: '/appointments/',
      navigationValue: get(split(window.location.pathname, '/', 2), '1', ''),
    };
    this.showPopover = this.showPopover.bind(this);
    this.logout = this.logout.bind(this);
    this.relevantHeader = this.relevantHeader.bind(this);
    this.bottomNavigation = this.bottomNavigation.bind(this);
    this.fetchInboxChats = this.fetchInboxChats.bind(this);
    this.hasNewMessagesFlag = this.hasNewMessagesFlag.bind(this);
    this.appBarHeader = this.appBarHeader.bind(this);
  }

  componentDidMount() {
    const self = this;
    window.addEventListener('resize', () => {
      this.setState({ width: window.innerWidth });
    });
    window.addEventListener('scroll', () => {
      if (!self.state.scrolledDown && window.scrollY > 0) {
        self.setState({ scrolledDown: true });
      } else if (window.scrollY === 0 && self.state.scrolledDown) {
        self.setState({ scrolledDown: false });
      }
    });
    if (!includes(window.location.pathname, '/booking/')) {
      this.fetchInboxChats();
    }
  }

  fetchInboxChats() {
    getChatsOfCurrentUser((resp) => {
      this.props.setChats(get(resp, 'data.chat_list', []));
    });
  }

  hasNewMessagesFlag() {
    const hasNewMessages = find(get(this.props, 'chats', []), (el) => !get(el, 'chats.0.seen', false));
    return !isEmpty(hasNewMessages);
  }

  logout() {
    logoutEvent();
    axios.delete(
      `${HOST_ROOT}/users/sign_out`,
      { withCredentials: true },
      ACCEPT_LANGUAGE_HEADER,
    ).then(() => {
      this.props.loadClient({ loggedIn: false });
      window.location.href = `${HOST_ROOT}${ROUTES.index}`;
    }).catch(() => {
      this.props.loadClient({ loggedIn: false });
      window.location.href = `${HOST_ROOT}${ROUTES.index}`;
    });
    this.setState({ anchorEl: null });
  }

  showPopover() {
    const { anchorEl, width } = this.state;
    const { showMenu } = this.props;
    if ((width <= 800 || includes(window.location.pathname, '/booking/')) && !showMenu) {
      return (
        <Menu
          style={{ zIndex: '999999999999' }}
          id="menu-appbar"
          getContentAnchorEl={null}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorEl)}
          onClose={() => this.setState({ anchorEl: null })}
        >
          <MenuItem onClick={() => {
            if (!includes(window.location.pathname, '/booking')) {
              this.setState({ redirect: true, pathname: '/booking/MENU' });
            }
          }}
          >
            Menu
          </MenuItem>
          <MenuItem
            onClick={() => {
              if (!includes(window.location.pathname, '/appointments')) {
                this.setState({ redirect: true, pathname: '/appointments/' });
              }
            }}
            id="appointments"
          >
            Bookings
          </MenuItem>
          <MenuItem
            onClick={() => {
              if (!includes(window.location.pathname, '/inbox')) {
                this.setState({ redirect: true, pathname: '/inbox/' });
              }
            }}
          >
            <Badge overlap="rectangular" color="secondary" variant="dot" invisible={!this.hasNewMessagesFlag()}>
              Inbox
            </Badge>
          </MenuItem>
          <MenuItem onClick={() => { window.location.href = `${HOST_ROOT}/help-center/`; }}>Help</MenuItem>
          <MenuItem onClick={this.logout}>Logout</MenuItem>
        </Menu>
      );
    }
    return (
      <Menu
        style={{ zIndex: '999999999999' }}
        id="menu-appbar"
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={() => this.setState({ anchorEl: null })}
      >
        <MenuItem onClick={this.logout}>Logout</MenuItem>
      </Menu>
    );
  }

  bottomNavigation() {
    const { navigationValue } = this.state;
    const loggedIn = get(this.props, 'client.loggedIn', false);
    const { hideGoBack, showMenu } = this.props;
    return (hideGoBack && !showMenu) ? <>
      <FetchClientContainer />
    </> : (
      <>
        <BottomNavigation
          showLabels
          className="mobile-toolbar br-t-opaque-2 size-12-16 contentTertiary font-weight-bold z-index-2"
          value={navigationValue}
        >
          <BottomNavigationAction
            label="Menu"
            value="booking"
            icon={<MenuBook />}
            component={Link}
            to="/booking/MENU"
            id="bookNow"
          />
          <BottomNavigationAction
            label="Bookings"
            value="appointments"
            icon={navigationValue === 'appointments' ? <EventAvailable /> : <EventAvailableOutlined />}
            component={Link}
            to="/appointments/"
            id="appointments"
          />
          <BottomNavigationAction
            label="Inbox"
            value="inbox"
            icon={(
              <Badge overlap="rectangular" color="secondary" variant="dot" invisible={!this.hasNewMessagesFlag()}>
                {navigationValue === 'inbox' ? <Message /> : <MessageOutlined />}
              </Badge>
            )}
            component={Link}
            to="/inbox/"
          />
          {hasUuid() && !loggedIn ? null : loggedIn
            ? (
              <BottomNavigationAction
                label="Logout"
                value="account"
                icon={<img className="w-h-20 br-rd-50 br-opaque" src={get(this.props, 'client.avatar_url', userAvatarPlaceholder)} alt="User avatar" />}
                component={Button}
                onClick={this.logout}
                id="avatar"
              />
            )
            : (
              <BottomNavigationAction
                label="Login"
                value="sign_on"
                icon={navigationValue === 'sign_on' ? <AccountCircle /> : <AccountCircleOutlined />}
                component={Link}
                to="/sign_on/"
              />
            )}
        </BottomNavigation>
        <FetchClientContainer />
      </>
    );
  }

  appBarHeader() {
    const { classes } = this.props;
    const { scrolledDown } = this.state;

    const navigationValue = get(split(window.location.pathname, '/', 2), '1', '');

    const { hideGoBack, showMenu } = this.props;
    const loggedIn = get(this.props, 'client.loggedIn', false);
    return (
      <div className={classes.root}>
        <AppBar position="fixed" elevation={scrolledDown ? 4 : 0}>
          <Toolbar className="height-80 p-16-64 align-items-center justify-content-spaced br-b-opaque">
            {!hideGoBack && !showMenu ? (
              <div className="w-33">
                <a href={blankHref} onClick={this.props.relevantGoBack} className="d-flex-only align-items-center BluePrimary font-weight-bold size-18-24">
                  <ArrowBack className="mr-12 size-18-28-imp" />
                  {' '}
                  Back
                </a>
              </div>
            ) : null}
            {hideGoBack && !showMenu ?
              <div className="w-33" />
              : null}
            {showMenu
              ? (
                <div className="w-33">
                  <Link className={`BluePrimary font-weight-bold size-24-18 mr-32 ${navigationValue === 'booking' ? 'br-b-blue-2' : ''}`} to="/booking/MENU" id="bookNow">
                    Menu
                  </Link>
                  <Link className={`BluePrimary font-weight-bold size-24-18 mr-32  ${navigationValue === 'appointments' ? 'br-b-blue-2' : ''}`} to="/appointments/" id="appointments">
                    Bookings
                  </Link>
                  <Link className={`BluePrimary font-weight-bold size-24-18 mr-32 ${navigationValue === 'inbox' ? 'br-b-blue-2' : ''}`} to="/inbox/">
                    Inbox
                  </Link>
                </div>
              ) : null}
            <div >
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  navigationViewHomepage("jul_2024_booking_enhancements", get(this.props, "jul_2024_booking_enhancements", ""));
                  window.location.href = `${HOST_ROOT}${ROUTES.index}`;
                }}
                id="sootheLogo"
              >
                <img className="soothe-logo-icon height-44" src={logoBlue} alt="Soothe-small" />
              </button>
            </div>
            {hasUuid() && !loggedIn ? <div className="w-33" /> : loggedIn
              ? (
                <div className="w-33 end">
                  <Link className="BluePrimary font-weight-bold size-24-18  mr-32" to="/help-center/">Help</Link>
                  <IconButton
                    id="avatar"
                    className="p-0-imp"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={(event) => {
                      const { anchorEl } = this.state;
                      if (anchorEl) {
                        this.setState({ anchorEl: null });
                      } else this.setState({ anchorEl: event.currentTarget });
                    }}
                    color="inherit"
                  >
                    {get(this.props, 'client.avatar_url', null)
                      ? <img className="w-h-48 br-rd-50" src={get(this.props, 'client.avatar_url', userAvatarPlaceholder)} alt="User avatar" />
                      : <AccountCircle className="w-h-48" />}
                  </IconButton>
                  {this.showPopover()}
                </div>
              )
              : !this.props.goToAuthentication ? <div className="w-33 end" />
                : (
                  <div className="w-33 end">
                    <button
                      type="button"
                      className="btn small-cta-secondary mr-8"
                      onClick={() => {
                        if (this.props.goToAuthentication) {
                          this.props.goToAuthentication(true);
                        }
                      }}
                    >
                      Login
                    </button>
                    <button
                      type="button"
                      className="btn small-cta-primary"
                      onClick={() => {
                        if (this.props.goToAuthentication) {
                          this.props.goToAuthentication(false);
                        }
                      }}
                    >
                      Sign Up
                    </button>
                  </div>
                )}
          </Toolbar>
        </AppBar>
        <FetchClientContainer />
      </div>
    );
  }

  relevantHeader() {
    if (this.state.redirect) {
      return (
        <Redirect to={{
          pathname: this.state.pathname,
          state: {
            click_source: 'nav_bar',
          },
        }}
        />
      );
    }
    if (this.state.width < 800) {
      return this.bottomNavigation();
    }
    return this.appBarHeader();
  }

  render() {
    const loggedIn = get(this.props, 'client.loggedIn', false);
    const parsedParams = queryString.parse(window.location.search);
    const resetToken = get(parsedParams, 'reset_password_token', null);
    const pageIs = get(split(window.location.pathname, '/', 2), '1', '');
    return (
      <>
        {this.relevantHeader()}
        <ForcedTermsModal />
        {loggedIn || (resetToken && pageIs !== 'sign_on') ? <ResetPasswordPopups /> : null}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  client: state.client,
  appointment: state.appointment,
  upcomingAppointmentId: state.upcomingAppointmentId,
  chats: state.chats,
});

Navbar.propTypes = {
  client: PropTypes.object.isRequired,
};

Navbar.defaultProps = {
  bookingNav: null,
};

export default withStyles(useStyles)(
  withLocalize(connect(mapStateToProps, { loadClient, setChats })(Navbar)),
);
