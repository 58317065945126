/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-undef */
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable camelcase */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { connect } from 'react-redux';
import '../../Assets/Styles/index.css';
import moment from 'moment-timezone';
import {
  compact, concat, flatten, forEach, get,
  groupBy, isEmpty, last, map, size, sortBy, times, uniqBy, values,
} from 'lodash';
import { Close } from '@material-ui/icons';
import LatestModalDesign from '../../../Shared/LatestModalDesign';
import { getTimesByProvider, openChat, requestRescheduleConfirmed } from '../../Shared/helpers';
import BlueButtonsList from '../../../Shared/BlueButtonsList';
import HorizontalCalendar from '../../../Shared/HorizontalCalendar';
import ConfirmChanges from './ConfirmChanges';
import SuccesModal from '../SuccessModal';
import { decode } from '../../Shared/encode';
import { setAppointment, fetchChat } from '../../../../Actions';
import ErrorModalRedesign from '../../../Shared/ErrorModalRedesign';
import { seInboxViewMessage, seProviderContact, seRescheduleComplete } from '../../../Shared/WebAnalytics';
import { defaultErrorMessage, ROUTES, SELECTABLE_HOURS } from '../../../../constants';
import { HOST_ROOT } from '../../../../apiConfig';
import { getSingleChatState } from '../../Shared/constants';

class TimesModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timesList: [],
      selection: null,
      lastAddressTimezone: '',
      mainModalOpen: get(props, 'show', false),
      showConfirmModal: false,
      showSuccessModal: false,
      showErrorModal: false,
      error: '',
      subError: '',
    };
    this.structureTimesList = this.structureTimesList.bind(this);
    this.listOfButtons = this.listOfButtons.bind(this);
    this.submitReschedule = this.submitReschedule.bind(this);
    this.loadData = this.loadData.bind(this);
    this.triggerSuccessfulReschedule = this.triggerSuccessfulReschedule.bind(this);
    this.extendedArr = this.extendedArr.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    const loader = this.props.setLoaderFlag;
    if (loader) {
      loader(true);
    }
    const userId = get(this.props, 'client.user_id', '');
    const providerId = get(this.props, 'appointment.cartProducts.0.pro.id', '');
    const cartId = get(this.props, 'appointment.id');
    getTimesByProvider(userId, providerId, cartId, (resp) => {
      const gnrl = get(resp, 'data.general_availability', []);
      const instnt = get(resp, 'data.instant_availability', []);
      this.setState({ lastAddressTimezone: get(resp, 'data.therapist.last_address.timezone', '') });
      this.structureTimesList(flatten(values(gnrl)), flatten(values(instnt)));
      if (loader) {
        loader(false);
      }
    }, (err) => {
      if (loader) {
        loader(false);
      }
      console.log({ err });
    });
  }

  extendedArr(mainArr) {
    let res = [];
    const lastElt = last(mainArr);
    const zn = get(this.state, 'lastAddressTimezone', '');
    times(53, (ii) => {
      const bf = get(lastElt, 'datetime', '');
      const nw = moment.tz(bf, zn).add(ii + 1, 'day');
      const formattedDate = nw.format('YYYY-MM-DD');
      res = concat(res, map(SELECTABLE_HOURS, (hr) => {
        const formattedTime = moment(`${formattedDate} ${hr}`).format('HH:mm');
        return {
          day: Number(nw.format('D')),
          day_of_week: nw.format('dddd'),
          month: Number(nw.format('M')),
          mon: nw.format('MMM'),
          time: hr,
          datetime: moment.tz(`${formattedDate} ${formattedTime}`, zn).format(),
          high_demand_fee: 0.0,
          category: 'Request',
        };
      }));
    });
    return concat(mainArr, res);
  }

  structureTimesList(general_availability, instant_availability) {
    if (!isEmpty(general_availability)) {
      forEach(general_availability, (el, itr) => {
        general_availability[itr].category = 'Request';
        general_availability[itr].mon = moment(general_availability[itr].datetime).format('MMM');
      });
    }
    if (!isEmpty(instant_availability)) {
      forEach(instant_availability, (el, itr) => {
        instant_availability[itr].category = 'Instant Book';
        instant_availability[itr].mon = moment(instant_availability[itr].datetime).format('MMM');
      });
    }

    const sortedArr = sortBy(uniqBy(compact(concat(instant_availability, general_availability)), 'datetime'), 'datetime');
    this.setState({ timesList: this.extendedArr(sortedArr) });
  }

  listOfButtons(grpList) {
    return map(grpList, (list, ind) => {
      const grpgrpList = groupBy(list, 'day');
      return (
        <div key={`time-list-${ind}`}>
          {map(grpgrpList, (subList, itr) => (
            <div key={`time-sublist-${itr}`} className="mb-50">
              <BlueButtonsList
                small
                customAnchor="extraHeightAnchor"
                selection={this.state.selection}
                title={moment(subList[0].datetime).format('ddd, MMM D')}
                buttonsList={subList}
                customId={`${itr}-${get(subList, '0.mon', '')}`}
                globalAction={(selection) => {
                  this.setState({ selection });
                }}
              />
            </div>
          ))}
        </div>
      );
    });
  }

  triggerSuccessfulReschedule(oldApt, changedCC = false) {
    const apt = get(this.props, 'appointment', {});
    seRescheduleComplete({
      appointment_id: get(oldApt, 'cartProducts.0.id', ''),
      state: get(oldApt, 'info_fields.tracker.state', ''),
      client_id: get(this.props, 'client.user_id', ''),
      service_category: get(apt, 'cartProducts.0.product.title', '').toLowerCase(),
      service_modality: get(apt, 'cartProducts.0.cart_product_main_option.title', '').toLowerCase(),
      same_day: moment(get(this.state, 'selection.datetime', '')).isSame(get(oldApt, 'date.utc'), 'day'),
      ic_utilized: get(apt, 'info_fields.tracker.pending_response_details.reschedule.instant_booking', false),
      late_reschedule: Boolean(get(oldApt, 'fees.reschedule.amount', 0)),
      cart_product_count: size(get(oldApt, 'cartProducts', [])),
      changed_payment_method: changedCC,
      different_pro: false,
      price_adjustment: Boolean(get(this.props, 'selection.high_demand_fee', 0)),
      number_of_requested_pros: size(get(this.props, 'appointment.therapist_preferences', [])),
    });
  }

  submitReschedule(message, changedCC) {
    const { selection } = this.state;
    const oldApt = get(this.props, 'appointment', {});
    if (selection) {
      requestRescheduleConfirmed(
        get(this.props, 'appointment.cartProducts.0.id'),
        { datetime: [selection.datetime] },
        message,
        get(this.props, 'appointment.fees.reschedule.amount', 0),
        selection.category === 'Instant Book',
        get(this.props, 'appointment.billing.credit_card.id', ''),
        (resp) => {
          this.props.setAppointment(decode(resp.data.cart));
          this.triggerSuccessfulReschedule(oldApt, changedCC);
          this.setState({ showSuccessModal: true, mainModalOpen: false, showConfirmModal: false });
        },
        (err) => {
          const message = get(err, 'response.data.message', defaultErrorMessage);
          const error = get(err, 'response.data.errors.0.message', message);
          this.setState({
            showErrorModal: true, mainModalOpen: false, showConfirmModal: false, error, subError: error ? ' ' : '',
          });
        },
      );
    }
  }

  render() {
    const { timesList, selection } = this.state;
    const grpList = groupBy(timesList, 'mon');
    const proFirstName = get(this.props, 'appointment.cartProducts.0.pro.first_name', '');
    const proId = get(this.props, 'appointment.cartProducts.0.pro.id', '');
    const sessionLength = get(this.props, 'appointment.cartProducts.0.session_length');
    const isInstantBook = Boolean(get(this.state, 'selection.category', '') === 'Instant Book');
    if (!isEmpty(timesList)) {
      return (
        <>
          <LatestModalDesign
            isOpen={this.state.mainModalOpen}
            hideFooter
            hideBackArrow
            contentExtraClass="pt-0"
          >
            <div className="top-sticky bg-primary pt-24">
              <div><a className="back-arrow-icon" onClick={this.props.hideModal}><Close /></a></div>
              <div className="modal-title">
                Reschedule with
                {' '}
                {proFirstName}
              </div>
              <div className="mx-minus-24">
                <HorizontalCalendar
                  daysCount={60}
                  startDate={timesList[0].datetime}
                />
                <div className="br-btm-black-1 txt-center size-16-20 medium-font p-16 contentPrimary">
                  {get(this.props, 'appointment.info_fields.title', '')}
                </div>
              </div>
            </div>
            <div className="mb-24">
              <div data-spy="scroll" data-target="#time-scroller" data-offset="0">
                {this.listOfButtons(grpList)}
              </div>
              <div className="mt-36 bg-secondary p-24-16 border-radius-8">
                <div className="size-16-20 color-black medium-font mb-4">Not finding a time?</div>
                <div className="size-12-20 color-gray mb-20">Contact your Provider directly to see if they can accomodate you or you can find a new provider at a different time.</div>
                <button
                  type="button"
                  className="btn bg-blue color-white medium-font padding-10-12 border-radius-1000"
                  onClick={() => {
                    this.setState({ mainModalOpen: false, showConfirmModal: false });
                    seProviderContact('initiate_reschedule');
                    openChat(compact([proId]), (resp) => {
                      this.props.fetchChat(get(resp, 'data', {}));
                      seInboxViewMessage({
                        state: getSingleChatState(get(resp, 'data.chats', [])),
                        click_source: 'appointment_details',
                        cart_product_count: size(get(resp, 'data.cart.display_helpers.v0.cart_products', [])),
                        booking_status: get(resp, 'data.cart.info_fields.tracker.state', 'pending'),
                        is_rebook: get(resp, 'data.cart.info_fields.rebook', false),
                        service_category: get(resp, 'data.cart.display_helpers.v0.cart_products.0.product.title', ''),
                        service_modality: get(resp, 'data.cart.display_helpers.v0.cart_products.0.cart_product_main_option.title', ''),
                      });
                      window.location.href = `${HOST_ROOT}${ROUTES.inbox}?chat_id=${get(this.props, 'currentChat.chat_id', '')}`;
                    }, (err) => {
                      console.log({ err });
                    });
                  }}
                >
                  Contact
                  {' '}
                  {proFirstName}
                </button>
                <a
                  className="DarkBluePrimary medium-font p-24-16 cursor-pointer"
                  onClick={this.props.switchModal}
                >
                  Find new provider
                </a>
              </div>
            </div>
            {selection ? (
              <div className="bottom-sticky bottom-minus-25 bg-primary p-16 br-t-opaque row mx-minus-24">
                <div className="col-xs-12 col-sm-4">
                  <div className="size-16-20 font-weight-bold contentPrimary mb-4 xs-txt-align-center">
                    {moment().day(selection.day_of_week).format('ddd')}
                    ,
                    {' '}
                    {moment().month(selection.month - 1).format('MMM')}
                    {' '}
                    {selection.day}
                  </div>
                  {sessionLength ? (
                    <div className="size-10-15 contentSecondary xs-txt-align-center">
                      {selection.time}
                      {' '}
                      -
                      {' '}
                      {moment(selection.time, 'hh:mm A').clone().add(sessionLength, 'minutes').format('hh:mm A')}
                    </div>
                  ) : null}
                </div>
                <div className="col-xs-12 col-sm-8">
                  <button
                    type="button"
                    className="btn btn-action border-radius-30 full-w-100 medium-font"
                    onClick={() => {
                      this.setState({ showConfirmModal: true, mainModalOpen: false });
                    }}
                  >
                    Confirm details
                  </button>
                </div>
              </div>
            ) : null}
          </LatestModalDesign>
          <ConfirmChanges
            show={this.state.showConfirmModal}
            selection={this.state.selection}
            ctaAction={this.submitReschedule}
            hideModal={() => {
              this.setState({ showConfirmModal: false, mainModalOpen: true });
            }}
          />
          {this.state.showSuccessModal ? (
            <SuccesModal
              isOpen
              title={isInstantBook ? 'Appointment successfully rescheduled' : `Change request sent to ${proFirstName}`}
              subtitle={isInstantBook ? ' ' : 'If they decline or do not respond, your appointment will remain as originally booked.'}
              ctaAction={() => {
                this.setState({
                  mainModalOpen: false,
                  showConfirmModal: false,
                  showSuccessModal: false,
                });
                this.props.hideModal();
              }}
            />
          )
            : null}
          <ErrorModalRedesign
            isOpen={this.state.showErrorModal}
            title={this.state.error}
            subtitle={this.state.subError}
            clickAction={() => {
              this.props.hideModal();
            }}
          />
        </>
      );
    }
    return null;
  }
}
const mapStateToProps = (state) => ({
  client: state.client,
  appointment: state.appointment,
  upcomingAppointmentId: state.upcomingAppointmentId,
  rebookOptions: state.rebookOptions,
  chats: state.chats,
  currentChat: state.currentChat,
});
export default connect(mapStateToProps, { setAppointment, fetchChat })(TimesModal);
