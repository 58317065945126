/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable default-param-last */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-lonely-if */
/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable no-use-before-define */
/* eslint-disable no-underscore-dangle */
import Amplitude from 'amplitude-js';
import queryString from 'query-string';
import sha1 from 'js-sha1';
import {
  split, assign, isEmpty, get, map, size,
} from 'lodash';
import moment from 'moment-timezone';
import { isGuestUser } from '../../constants';

if (process.env.REACT_APP_PRODUCTION === 'true') {
  // Initialize Amplitude
  Amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_SDK_KEY);

  // Initialize Iterable
  // const _iaq = window._iaq || [];
  // _iaq.push(['account', process.env.REACT_APP_ITERABLE_SDK_KEY]);
}

/* ************************** EVENT FUNCTIONS ************************* */

// SIGN UP EVENTS

export function setAllUserIds(userId, email) {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  Amplitude.getInstance().setUserId(userId);
  window.AF('pba', 'setCustomerUserId', userId.toString());

  // const _iaq = _iaq || [];
  // _iaq.push(['identify', email, {
  //   userId,
  // }]);

  sessionStorage.setItem('userSet', 'true');
}

export function signUpStart() {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_signup_start';
  const userHash = { user_type: 'visitor' };

  // Amplitude Events
  Amplitude.getInstance().setUserProperties(userHash);
  Amplitude.getInstance().logEvent(eventName, userHash);
}

export function signUpAccountDetails(email, firstName, lastName, phone) {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_signup_account_details';
  const userHash = {
    email,
    first_name: firstName,
    last_name: lastName,
    phone,
  };

  // Amplitude Events
  Amplitude.getInstance().setUserProperties(userHash);
  Amplitude.getInstance().logEvent(eventName, userHash);
}

export function signUpComplete(userId, registrationMethod, userEmail = '', experiment_cohort = 'user_auth_control') {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;
  const eventName = 'se_signup_complete';
  let eventHash = {
    user_id: userId,
    registration_method: registrationMethod,
    // experiment_cohort
  };

  const userHash = {
    user_id: userId,
    registration_method: registrationMethod,
    user_type: 'registered',
    // experiment_cohort
  };

  eventHash = hashAffiliateUtms(eventHash);

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
  Amplitude.getInstance().setUserProperties(userHash);
  // AppsFlyer Events
  window.AF('pba', 'event', { eventType: 'EVENT', eventName: 'af_complete_registration', eventValue: { af_registration_method: registrationMethod } });

  if (window.ire) {
    window.ire(
      'trackConversion',
      23848,
      {
        orderId: userId,
        customerId: userId,
        customerEmail: sha1(userEmail),
      },
      {
        verifySiteDefinitionMatch: true,
      },
    );
  }
  // const _iaq = _iaq || [];
  // _iaq.push(['track', 'signup_complete', {
  //   user_id: userId,
  //   registration_method: registrationMethod,
  // }]);
}

// LOG IN EVENTS

export function logInStart(logInSource) {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_login_start';
  const eventHash = { utm_source: logInSource };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}

export function logInComplete(logInMethod, userId, experiment_cohort = 'user_auth_control') {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;
  const eventName = 'se_login_complete';
  const eventHash = {
    login_method: logInMethod, user_id: userId, // experiment_cohort
  };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
  // AppsFlyer Events
  window.AF('pba', 'event', { eventType: 'EVENT', eventName: 'af_login' });
}

// LOGOUT EVENT
export function logoutEvent() {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_logout';
  const eventHash = {};

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}

// ADDRESS EVENTS

export function addressAddNew(address_id, click_source, experiment_cohort, nearby_address, skipped = 'No', market = '', test_name, test_value) {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_addresses_add';
  const eventHash = {
    address_id,
    click_source,
    // experiment_cohort,
    nearby_address,
    skipped,
    market,
    ...(test_value && { [`ab_test_${test_name}`]: test_value, })
  };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}

export function addressDeleteAddress() {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_addresses_delete';
  const eventHash = {};

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}

export function addressViewAddresses() {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_addresses_view';
  const eventHash = {};

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}

export function addressSelection(request_id, address_id, skipped, experiment_cohort, market = '', test_name, test_value) {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;
  const eventName = 'se_address_selected';
  const eventHash = {
    request_id,
    address_id,
    // experiment_cohort,
    skipped,
    market,
    ...(test_value && { [`ab_test_${test_name}`]: test_value, })
  };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function addressSelect(request_id, address_id, skipped) {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;
  const eventName = 'se_address_select';
  const eventHash = {
    request_id,
    address_id,
    skipped,
  };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}
// BOOKING EVENTS

// *********** HELPER FUNCTIONS ****************

function sumModalities(cartProducts) {
  return cartProducts.map((cp) => get(cp, 'cart_product_main_option.title', '').toLowerCase());
}

function sumOptions(cartProducts) {
  return cartProducts.map((cp) => [`length:${cp.base_session_length}`, `gender:${cp.subtitle ? cp.subtitle.split(' Pro')[0].toLowerCase() : ''}`]).flat();
}

function sumEnhancements(cartProducts) {
  const addOnSummary = cartProducts.map((cp) => {
    if (cp.add_ons.length > 0) {
      return cp.add_ons.map((ao) => ao.title.toLowerCase()).flat();
    }
  }).flat();

  return addOnSummary.filter((item) => item !== undefined);
}

function subscriptionPlan(subscriptionTiers, subscriptionTierId) {
  let plan;
  subscriptionTiers.forEach((st) => {
    if (st.id === subscriptionTierId) {
      plan = st.title.toLowerCase();
    }
  });

  return plan;
}

// *********** END HELPER FUNCTIONS ****************

// BOOKING EVENTS

export function seBookingStart(firstTimeBooker, cartId, user_id, email, serviceCategory = 'unknown', loggedIn = false, is_rebook, experiment_cohort, market = '', test_name = '', test_value = null, is_event = false, event_type = '') {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  let first_time_booking;
  if (firstTimeBooker == null) first_time_booking = 'n/a';
  else if (firstTimeBooker) first_time_booking = 'yes';
  else first_time_booking = 'no';

  const eventName = 'se_booking_start';
  let eventHash = {
    click_source: is_event ? "events-booking" : is_rebook ? 'past_appointments' : 'homepage',
    request_id: cartId || 'n/a',
    is_rebook,
    is_event,
    event_type,
    service_category: serviceCategory,
    service_nb_people: is_rebook ? 1 : 0,
    service_nb_pro: is_rebook ? 1 : 0,
    first_time_booking,
    logged_in: loggedIn,
    // experiment_cohort,
    market,
    ...(test_value && { [`ab_test_${test_name}`]: test_value, })
  };

  eventHash = hashAffiliateUtms(eventHash);

  const userHash = user_id || email ? { user_id, email } : {};

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
  Amplitude.getInstance().setUserProperties(userHash);
}

export function seBookingAddressInput(country, city, zipCode, isServiced) {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_booking_address_input';
  const eventHash = {
    country,
    city,
    zip_code: zipCode || 'n/a',
    is_serviced: isServiced ? 'yes' : 'no',
  };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}

export function seBookingServiceSelect(cart, serviceCategory, firstTimeBooker, experiment_cohort, service_modality = '', logged_in = false, market = '', test_name = '', test_value = null, is_event = false, event_type = '') {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_booking_service_select';
  const eventHash = {
    request_id: cart ? cart.id : 'n/a',
    is_rebook: cart ? cart.rebook : 'n/a',
    is_event,
    event_type,
    service_category: serviceCategory,
    first_time_booking: cart ? firstTimeBooker ? 'yes' : 'no' : 'n/a',
    // experiment_cohort,
    service_modality,
    skipped: 'no',
    market,
    ...(test_value && { [`ab_test_${test_name}`]: test_value, })
  };

  const userHash = { logged_in };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
  Amplitude.getInstance().setUserProperties(userHash);
}

export function seBookingCartProductCount(cart_product_count, selection, skipped = false) {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;
  const eventName = 'se_booking_cart_product_count';
  const eventHash = {
    cart_product_count,
    selection,
    skipped: skipped ? 'yes' : 'no',
  };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}

export function seBookingSchedule(
  cart,
  serviceCategory,
  firstTimeBooker,
  experiment_cohort,
  number_of_pros,
  test_name,
  test_value,
) {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const bkngDate = moment(get(cart, 'time.utc', ''));
  const nowDate = moment().tz(get(cart, 'time.timezone', ''));

  const eventName = isGuestUser() ? 'sbe_booking_time_selection' : 'se_booking_schedule';
  const eventHash = {
    request_id: cart.id,
    is_rebook: cart.rebook,
    service_category: serviceCategory,
    service_sum_modalities: sumModalities(cart.cartProducts),
    service_sum_options: sumOptions(cart.cartProducts),
    service_sum_enhancements: sumEnhancements(cart.cartProducts),
    service_nb_people: cart.cartProducts.length,
    service_nb_pro: (cart.cartProducts.length > 1 && !cart.backToBack.enabled) ? 2 : 1,
    first_time_booking: firstTimeBooker ? 'yes' : 'no',
    date: cart.date.utc,
    time: cart.time.utc,
    // experiment_cohort,
    number_of_pros,
    is_couples: cart.cartProducts.length === 1 ? 'False' : (cart.cartProducts.length > 1
      && !cart.backToBack.enabled) ? 'Same_Time' : 'Back_to_Back',
    market: get(cart, 'address.market', ''),
    lead_time_selected: bkngDate.diff(nowDate, 'hours'),
    is_today: bkngDate.isSame(nowDate, 'day'),
    ...(test_value && { [`ab_test_${test_name}`]: test_value, })
  };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}

export function seBookingPAPView(number_of_pros, request_id, experiment_cohort, is_couples, {
  market,
  number_of_ga_pros,
  number_of_ic_pros,
  lead_time_selected,
  is_today,
  click_source,
  test_name,
  test_value,
}) {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_pick_a_pro_view';
  const eventHash = {
    market,
    number_of_pros,
    number_of_ga_pros,
    number_of_ic_pros,
    lead_time_selected,
    is_today: is_today ? 'True' : 'False',
    click_source,
    request_id,
    // experiment_cohort,
    is_couples,
    ...(test_value && { [`ab_test_${test_name}`]: test_value, })
  };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}

export function seBookingPAPConfirm(pro_id, list_position, exact_match, request_id, {
  ab_test_booking_flow,
  market,
  is_ic,
  number_of_ga_pros,
  number_of_ga_pros_selected,
  lead_time_selected,
  is_today,
  click_source,
  test_name,
  test_value,
}) {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_pick_a_pro_confirm';
  const eventHash = {
    pro_id,
    list_position: is_ic ? list_position : 'n/a',
    exact_match,
    request_id,
    // ab_test_booking_flow,
    market,
    is_ic,
    number_of_ga_pros,
    number_of_ga_pros_selected,
    lead_time_selected,
    is_today: is_today ? 'True' : 'False',
    click_source,
    ...(test_value && { [`ab_test_${test_name}`]: test_value, })
  };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}

export function seBookingPaymentOption(
  cart,
  serviceCategory,
  firstTimeBooker,
  subscriptionTiers,
  subscriptionTierId,
) {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_booking_payment_option';
  const eventHash = {
    request_id: cart.id,
    is_rebook: cart.rebook,
    service_category: serviceCategory,
    service_sum_modalities: sumModalities(cart.cartProducts),
    service_sum_options: sumOptions(cart.cartProducts),
    service_sum_enhancements: sumEnhancements(cart.cartProducts),
    service_nb_people: cart.cartProducts.length,
    service_nb_pro: (cart.cartProducts.length > 1 && !cart.backToBack.enabled) ? 2 : 1,
    first_time_booking: firstTimeBooker ? 'yes' : 'no',
    booking_payment_plan: subscriptionTierId ? subscriptionPlan(subscriptionTiers, subscriptionTierId) : 'single',
  };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}

export function seBookingDetails(cart, cartProduct, serviceCategory, firstTimeBooker, experiment_cohort, cart_product_count, gender, logged_in = false, market = '', test_name = '', test_value = null, is_event = false, event_type = '', event_details = {}) {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_booking_details';
  const eventHash = {
    request_id: cart.id,
    is_rebook: cart.rebook,
    client_identification: cartProduct.client_name,
    service_category: serviceCategory,
    service_modality: get(cartProduct, 'cart_product_main_option.title', '').toLowerCase(),
    service_options: [`length:${cartProduct.base_session_length}`, `gender:${cartProduct.subtitle ? split(cartProduct.subtitle, ' ', cartProduct.subtitle.split(' ').length - 1).join(' ').toLowerCase() : ''}`],
    service_enhancements: map(cartProduct.add_ons || [], (ao) => ao.title.toLowerCase()).flat(),
    first_time_booking: firstTimeBooker ? 'yes' : 'no',
    skipped: 'no',
    is_event,
    event_type,
    ...event_details,
    // adding event details after service_options to override it
    // experiment_cohort,
    cart_product_count,
    gender,
    market,
    ...(test_value && { [`ab_test_${test_name}`]: test_value, })
  };

  const userHash = { logged_in };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
  Amplitude.getInstance().setUserProperties(userHash);
}

export function seBookingReview(
  request_id,
  is_rebook,
  service_category,
  service_nb_people = 0,
  first_time_booking = false,
  experiment_cohort,
  {
    market,
    is_ic,
    is_today,
    lead_time_selected,
    blind_auction_opt_in,
    test_name,
    test_value,
    is_event = false,
    event_type = '',
    start_time = "",
    end_time = "",
    nb_pros = 0,
    nb_guest = 0,
    service_nb_people2 = 0
  },
) {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = isGuestUser() ? 'sbe_booking_review' : 'se_booking_review';
  const eventHash = {
    request_id,
    is_rebook,
    is_event,
    ...is_event ? {
      event_type,
      start_time,
      end_time,
      nb_pros,
      nb_guest,
    } : {},
    service_nb_people: is_event ? service_nb_people2 : service_nb_people,
    service_category,
    first_time_booking,
    // experiment_cohort,
    market,
    is_ic,
    is_today: is_today ? 'True' : 'False',
    lead_time_selected,
    blind_auction_opt_in: blind_auction_opt_in ? 'True' : 'False',
    ...(test_value && { [`ab_test_${test_name}`]: test_value, })
  };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}

export function seBookingCheckout(
  request_id,
  is_rebook,
  service_category,
  service_nb_people = 0,
  first_time_booking = false,
  experiment_cohort,
  {
    market,
    is_ic,
    is_today,
    lead_time_selected,
    blind_auction_opt_in,
    test_name,
    test_value,
    is_event = false,
    event_type = '',
    start_time = "",
    end_time = "",
    nb_pros = 0,
    nb_guest = 0,
    service_nb_people2 = 0
  },
) {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_booking_checkout';
  const eventHash = {
    request_id,
    is_rebook,
    is_event,
    ...is_event ? {
      event_type,
      start_time,
      end_time,
      nb_pros,
      nb_guest,
    } : {},
    service_nb_people: is_event ? service_nb_people2 : service_nb_people,
    service_category,
    first_time_booking,
    // experiment_cohort,
    market,
    is_ic,
    is_today: is_today ? 'True' : 'False',
    lead_time_selected,
    blind_auction_opt_in: blind_auction_opt_in ? 'True' : 'False',
    ...(test_value && { [`ab_test_${test_name}`]: test_value, })
  };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}

export function seBookingAddressDetails(
  address_type = '',
  parking_details = '',
  skipped = false,
  first_time_booking = false,
  experiment_cohort,
  click_source,
  market = '',
  is_event = false,
  event_type = '',
) {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_booking_address_details';
  const eventHash = {
    address_type,
    parking_details,
    skipped: skipped ? 'yes' : 'no',
    first_time_booking: first_time_booking ? 'yes' : 'no',
    // experiment_cohort,
    click_source,
    market,
    is_event,
    event_type,
  };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}

export function seBookingSummary(
  cart,
  serviceCategory,
  firstTimeBooker,
  membership_type,
  membership_id,
  experiment_cohort,
  default_payment_method,
  test_name,
  test_value,
  is_event = false,
  event_type = '',
) {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const service_sum_modalities = []; const service_sum_options = []; const
    service_sum_enhancements = [];
  const cartProducts = cart ? cart.cartProducts : [];
  if (cartProducts && cartProducts.length > 0) {
    cartProducts.map((cp) => {
      service_sum_modalities.push(get(cp, 'cart_product_main_option.title', '').toLowerCase());
      service_sum_options.push(`length:${cp.base_session_length}`);
      if (cp.subtitle) {
        service_sum_options.push(`gender:${split(cp.subtitle, ' ', cp.subtitle.split(' ').length - 1).join(' ').toLowerCase()}`);
      }
      cp.add_ons.map((ao) => { service_sum_enhancements.push(ao.title.toLowerCase()); });
    });
  }

  const eventName = 'se_booking_summary';
  let eventHash = {
    request_id: cart.id,
    is_rebook: cart.rebook,
    is_event,
    event_type,
    service_category: serviceCategory,
    service_sum_modalities,
    service_sum_options,
    service_sum_enhancements,
    service_nb_people: cart.cartProducts ? cart.cartProducts.length : 0,
    first_time_booking: firstTimeBooker ? 'yes' : 'no',
    // experiment_cohort,
    default_payment_method,
    market: get(cart, 'address.market', ''),
    blind_auction_opt_in: get(cart, 'blind_auction_switch', false) ? 'True' : 'False',
    ...(test_value && { [`ab_test_${test_name}`]: test_value, })
  };
  if (membership_id) {
    eventHash = assign(eventHash, {
      user_type: 'subscriber',
      membership_type,
      membership_id,
    });
  }

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}

export function seBookingComplete(
  cart,
  serviceCategory,
  firstTimeBooker,
  subscriptionTiers,
  subscriptionTierId,
  spCompleteObj,
  experiment_cohort,
  test_name,
  test_value,
  is_event = false,
  event_type = '',
  event_details = {},
) {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;
  const bkngDate = moment(get(cart, 'date.utc', ''));
  const nowDate = moment();
  const eventName = isGuestUser() ? 'sbe_booking_checkout' : 'se_booking_complete';
  const is_ic = get(cart, 'info_fields.tracker', '') === 'confirmed';
  let eventHash = {
    request_id: cart.id,
    is_rebook: cart.rebook,
    service_category: serviceCategory,
    service_sum_modalities: sumModalities(cart.cartProducts),
    service_sum_options: sumOptions(cart.cartProducts),
    service_sum_enhancements: sumEnhancements(cart.cartProducts),
    service_nb_people: cart.cartProducts.length,
    service_nb_pro: (cart.cartProducts.length > 1 && !cart.backToBack.enabled) ? 2 : 1,
    // ovverriding the above 2 values if event
    first_time_booking: firstTimeBooker ? 'yes' : 'no',
    booking_payment_plan: subscriptionTierId ? subscriptionPlan(subscriptionTiers, subscriptionTierId) : 'single',
    revenue_amount: cart.billing.subtotal,
    currency_type: cart.billing.currency,
    booking_city: cart.address.city,
    booking_country: cart.time.timezone.split('/')[0],
    price: cart.billing.price,
    booking_status: cart.status,
    is_event,
    event_type,
    ...event_details,
    // experiment_cohort,
    is_couples: cart.cartProducts.length === 1 ? 'False' : (cart.cartProducts.length > 1
      && !cart.backToBack.enabled) ? 'Same_Time' : 'Back_to_Back',
    market: get(cart, 'address.market', ''),
    is_today: bkngDate.isSame(nowDate, 'day') ? 'True' : 'False',
    is_ic,
    number_of_ga_pros_selected: is_ic ? 0 : size(get(cart, 'cartProducts.0.pap_therapist_preferences', [])),
    blind_auction_opt_in: get(cart, 'blind_auction_switch', false) ? 'True' : 'False',
    ...(test_value && { [`ab_test_${test_name}`]: test_value, })
  };

  if (!isEmpty(spCompleteObj)) {
    const {
      membership_type, membership_id, used_points, used_cash,
    } = spCompleteObj;
    eventHash = assign(eventHash, {
      used_points: used_points ? 'true' : 'false',
      used_cash: used_cash ? 'true' : 'false',
      user_type: 'subscriber',
      membership_id,
      membership_type,
    });
  }
  eventHash = hashAffiliateUtms(eventHash);

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);

  // AppsFlyer Events
  window.AF('pba', 'event', {
    eventType: 'EVENT',
    eventName: firstTimeBooker ? 'af_first_booking' : 'af_repeat_booking',
    eventValue: {
      af_order_id: cart.id,
      af_revenue: cart.billing.subtotal,
      af_currency: cart.billing.currency,
      af_city: cart.address.city,
      af_country: cart.time.timezone.split('/')[0],
      af_price: cart.billing.price,
      af_promo_code: get(cart, 'info_fields.discount_code', ''),
    },
  });

  window.AF('pba', 'event', {
    eventType: 'EVENT',
    eventName: 'af_purchase',
    eventValue: {
      af_order_id: cart.id,
      af_revenue: cart.billing.subtotal,
      af_currency: cart.billing.currency,
      af_city: cart.address.city,
      af_country: cart.time.timezone.split('/')[0],
      af_price: cart.billing.price,
      af_promo_code: get(cart, 'info_fields.discount_code', ''),
    },
  });
}

export function bookingConversionImpactRadius(cart, client) {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  if (window.ire) {
    window.ire(
      'trackConversion',
      23849,
      {
        orderId: get(cart, 'id', ''),
        customerId: get(client, 'user_id', ''),
        customerEmail: sha1(client.email || ''),
        orderSubTotalPostDiscount: get(cart, 'billing.total_price', ''),
        orderPromoCode: get(cart, 'info_fields.discount_code', ''),
      },
      {
        verifySiteDefinitionMatch: true,
      },
    );
  }
}

// MEMBERSHIP EVENTS

export function membershipSelection(
  membershipOffer,
  membershipType,
  membershipId,
  membershipDiscount,
  membershipLenth,
) {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_membership_selection';
  const eventHash = {
    membership_offer: membershipOffer,
    membership_type: membershipType,
    membership_id: membershipId,
    membership_discount: membershipDiscount,
    membership_length: membershipLenth,
  };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}

export function membershipComplete(
  membershipType,
  membershipId,
  membershipDiscount,
  membershipLength,
  revenueAmount,
  currencyType,
) {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_membership_complete';
  const eventHash = {
    membership_type: membershipType,
    membership_id: membershipId,
    membership_discount: membershipDiscount,
    membership_length: membershipLength,
    revenue_amount: revenueAmount,
    currency_type: currencyType,
  };

  const userHash = {
    user_type: 'subscriber',
    membership_type: membershipType,
    membership_id: membershipId,
    membership_discount: membershipDiscount,
    membership_length: membershipLength,
  };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
  Amplitude.getInstance().setUserProperties(userHash);
  // AppsFlyer Events
  window.AF('pba', 'event', {
    eventType: 'EVENT',
    eventName: 'af_subscribe',
    eventValue: { af_revenue: revenueAmount, af_currency: currencyType },
  });
}

export function membershipCancelled(
  membershipType,
  membershipId,
  membershipDiscount,
  membershipLength,
) {
  if (process.env.NODE_ENV !== 'production') { return; }

  const eventName = 'se_membership_cancelled';
  const eventHash = {
    membership_type: membershipType,
    membership_discount: membershipDiscount,
    membership_length: membershipLength,
    performed_by: 'client',
  };

  const userHash = {
    user_type: 'registered',
    membership_id: membershipId,
  };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
  Amplitude.getInstance().setUserProperties(userHash);
}

// NAVIGATION EVENTS

export function navigationViewArticles() {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_view_article_section';
  const eventHash = {};

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}

export function navigationViewMemberships() {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_membership_view';
  const eventHash = {};

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}

export function navigationViewHomepage(test_name, test_value) {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_view_home';
  const eventHash = {
    ...(test_value && { [`ab_test_${test_name}`]: test_value, })
  };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}

export function navigationViewSAW() {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_sootheatwork_form_view';
  const eventHash = { click_source: 'web navbar' };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}

export function navigationViewLegalPages() {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_support_page_view';
  const eventHash = { click_source: 'web footer' };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}

// GIFT CARD EVENT

export function giftCardView() {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_giftcard_view';
  const eventHash = { click_source: 'web navbar' };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}

// GIFT CARD REDESIGN : EVENTS

export function giftCardStartEvent() {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_giftcard_start';

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName);
}

export function giftCardTypeEvent(type) {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  let formattedType;
  switch (type) {
    case 'print_at_home': {
      formattedType = 'print at home';
      break;
    }
    case 'buy_in_bulk': {
      formattedType = 'bulk';
      break;
    }
    default: {
      formattedType = 'digital';
    }
  }

  const eventName = 'se_giftcard_type';
  const eventHash = { type: formattedType };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}

export function giftCardLocationEvent(deliveryMethod, country, city) {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  let formattedType;
  if (deliveryMethod === 'print') {
    formattedType = 'print at home';
  } else {
    formattedType = 'digital';
  }

  const eventName = 'se_giftcard_location';
  const eventHash = { type: formattedType, country, city };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}

export function giftCardAmountEvent(deliveryMethod, country, city, length, cost) {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  let formattedType;
  if (deliveryMethod === 'print') {
    formattedType = 'print at home';
  } else {
    formattedType = 'digital';
  }

  const eventName = 'se_giftcard_amount';
  const eventHash = {
    type: formattedType, country, city, length, cost,
  };
  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}

export function giftCardDeliveryEvent(deliveryMethod, country, city, length, cost, delivery_date) {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  let formattedType;
  if (deliveryMethod === 'print') {
    formattedType = 'print at home';
  } else {
    formattedType = 'digital';
  }

  const eventName = 'se_giftcard_delivery';
  const eventHash = {
    type: formattedType, country, city, length, cost, delivery_date,
  };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}

export function giftCardRecipientInfoEvent(
  deliveryMethod,
  country,
  city,
  length,
  cost,
  delivery_date,
) {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  let formattedType;
  if (deliveryMethod === 'print') {
    formattedType = 'print at home';
  } else {
    formattedType = 'digital';
  }

  const eventName = 'se_giftcard_recipient_info';
  const eventHash = {
    type: formattedType, country, city, length, cost, delivery_date,
  };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}

export function giftCardBuyAnotherEvent() {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_giftcard_buy_another';
  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName);
}

export function giftCardCheckoutEvent(
  country,
  city,
  number_of_gift_cards,
  total_order_value,
  gift_certificate_assortment_id,
) {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_giftcard_checkout';
  const eventHash = {
    country, city, number_of_gift_cards, total_order_value, gift_certificate_assortment_id,
  };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}

// SOOTHEPASS MEMBERSHIP EVENTS
export function seViewedTermsModal(membership_type, membership_id) {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_viewed_terms_modal';
  const eventHash = {
    membership_type,
    membership_id,
    user_type: 'subscriber',
  };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function seTermsAccepted(membership_type, membership_id) {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_terms_accepted';
  const eventHash = {
    membership_type,
    membership_id,
    user_type: 'subscriber',
  };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function seMembershipUpsellView(membership_type, membership_id, experiment_cohort, market = '') {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_membership_upsell_view';
  const eventHash = {
    membership_type,
    membership_id,
    // experiment_cohort,
    market,
  };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function seMembershipSkip(membership_type, membership_id) {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_membership_skip';
  const eventHash = {
    membership_type,
    membership_id,
  };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function seMembershipView(membership_type, membership_id) {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_membership_view';
  const eventHash = {
    membership_type,
    membership_id,
  };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function seMembershipContinue(membership_type, membership_id) {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_membership_continue';
  const eventHash = {
    membership_type,
    membership_id,
  };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function seMembershipComplete(spCompleteObj) {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const {
    membership_type, first_time_booking, membership_id, service_category, booking_city,
    is_rebook, revenue_amount, currency_type, service_nb_people, used_points, used_cash,
  } = spCompleteObj;
  const eventName = 'se_membership_complete';
  const eventHash = {
    membership_type,
    first_time_booking: first_time_booking ? 'yes' : 'no',
    membership_id,
    service_category,
    booking_city,
    is_rebook,
    revenue_amount,
    currency_type,
    service_nb_people,
    used_points: used_points ? 'true' : 'false',
    used_cash: used_cash ? 'true' : 'false',
  };
  const userHash = {
    user_type: 'subscriber',
    membership_type,
    membership_id,
  };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
  Amplitude.getInstance().setUserProperties(userHash);
  // AppsFlyer Events
  window.AF('pba', 'event', { eventType: 'EVENT', eventName: 'af_subscribe' });

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'Subscribe',
    Currency: currency_type,
    Value: revenue_amount,
  });
}
export function seBookingUnapplyPoints(membership_type, membership_id, points_type) {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_booking_unapply_points';
  const eventHash = {
    membership_type,
    membership_id,
    points_type,
  };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function seViewSoothePassFAQ(membership_type, membership_id) {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_view_soothepass_faq';
  const eventHash = {
    membership_type,
    membership_id,
  };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}

export function sawFormSubmitted() {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_sootheatwork_form_submitted';
  const eventHash = {};

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}

// Browser Pixel Data

export function sendPixelData(appointmentRequestData, clientEmail, isFirstTimeBooker) {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'appointment_request',
    currency: appointmentRequestData.currency,
    appointment_request_id: appointmentRequestData.id,
    receipt_info_type: appointmentRequestData.receiptInfoType,
    session_length: appointmentRequestData.sessionLength,
    revenue: appointmentRequestData.totalInt,
    appointment_type: appointmentRequestData.appointmentType,
    client_email: clientEmail,
    service_category: appointmentRequestData.service_category,
  });

  if (isFirstTimeBooker) {
    window.dataLayer.push({
      event: 'first_time_appointment_request',
      currency: appointmentRequestData.currency,
      appointment_request_id: appointmentRequestData.id,
      receipt_info_type: appointmentRequestData.receiptInfoType,
      session_length: appointmentRequestData.sessionLength,
      revenue: appointmentRequestData.totalInt,
      appointment_type: appointmentRequestData.appointmentType,
      client_email: clientEmail,
      service_category: appointmentRequestData.service_category,
    });
  } else {
    window.dataLayer.push({
      event: 'returning_appointment_request',
      currency: appointmentRequestData.currency,
      appointment_request_id: appointmentRequestData.id,
      receipt_info_type: appointmentRequestData.receiptInfoType,
      session_length: appointmentRequestData.sessionLength,
      revenue: appointmentRequestData.totalInt,
      appointment_type: appointmentRequestData.appointmentType,
      client_email: clientEmail,
      service_category: appointmentRequestData.service_category,
    });
  }
  window.dataLayer.push({
    event: `booking_${get(appointmentRequestData, 'service_category', '')}`,
    first_time_booking: isFirstTimeBooker,
  });
}

// COMPONENT MOUNT INIT CALLS

export function componentMountedInitCalls(client) {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  window.gladlyConfig = {
    appId: `${process.env.REACT_APP_GLADLY_SIDEKICK_KEY}`,
  };

  setTimeout(() => {
    if (client.loggedIn) {
      window.Gladly.setUser({ name: `${client.first_name} ${client.last_name}`, email: `${client.email}` });

      // Impact Init Call
      if (window.ire) window.ire('identify', { customerId: `${client.user_id}`, customerEmail: `${sha1(client.email)}` });
    } else {
      // Impact Init Call
      if (window.ire) window.ire('identify', { customerId: '', customerEmail: '' });
    }
  }, 2000);
}

// HANDLE AFFILIATE UTMS

export function hashAffiliateUtms(hash = {}) {
  const parsedParams = queryString.parse(window.location.search);

  if (Object.keys(parsedParams).includes('utm_source')) {
    hash.source = parsedParams.utm_source;
  }

  if (Object.keys(parsedParams).includes('utm_medium')) {
    hash.medium = parsedParams.utm_medium;
  }

  if (Object.keys(parsedParams).includes('utm_campaign')) {
    hash.campaign = parsedParams.utm_campaign;
  }

  if (Object.keys(parsedParams).includes('utm_term')) {
    hash.term = parsedParams.utm_term;
  }

  if (Object.keys(parsedParams).includes('utm_content')) {
    hash.content = parsedParams.utm_content;
  }

  if (Object.keys(parsedParams).includes('gclid')) {
    hash.gclid = parsedParams.gclid;
  }

  if (Object.keys(parsedParams).includes('adgroup')) {
    hash.adgroup = parsedParams.adgroup;
  }

  if (Object.keys(parsedParams).includes('utm_adgroup')) {
    hash.adgroup = parsedParams.utm_adgroup;
  }

  return hash;
}

// APPOINTMENT MANAGEMENT EVENTS

export function seAppointmentDetails(
  status,
  appointment_id,
  pro_id,
  click_source,
  test_name,
  test_value,
) {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_view_appointment_details';
  const eventHash = {
    status,
    appointment_id,
    pro_id,
    click_source,
    ...(test_value && { [`ab_test_${test_name}`]: test_value, })
  };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function seAppointmentReview(appointment_id, pro_id, review_value) {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_client_review';
  const eventHash = {
    appointment_id,
    pro_id,
    review_value,
  };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function seAppointmentTip(appointment_id, pro_id, tip_amount) {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_client_tip';
  const eventHash = {
    appointment_id,
    pro_id,
    tip_amount,
  };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function seAppointmentExtend(appointment_id, pro_id, extension, click_source) {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_appointment_extend';
  const eventHash = {
    appointment_id,
    pro_id,
    extension,
    click_source,
  };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function seAppointmentReschedule(
  appointment_id,
  pro_id,
  date,
  time,
  click_source,
  number_of_requested_pros,
  test_name,
  test_value,
) {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_appointment_reschedule';
  const eventHash = {
    appointment_id,
    pro_id,
    date,
    time,
    click_source,
    number_of_requested_pros,
    ...(test_value && { [`ab_test_${test_name}`]: test_value, })
  };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function seAppointmentCancel(appointment_id, pro_id, click_source, test_name, test_value) {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_appointment_cancelled';
  const eventHash = {
    appointment_id,
    pro_id,
    click_source,
    ...(test_value && { [`ab_test_${test_name}`]: test_value, })
  };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function seViewAlternativeTimes(click_source, is_rebook, {
  ab_test_booking_flow,
  market,
  is_special_request,
  is_today,
  lead_time_selected,
  number_of_requested_pros,
  number_of_pros_unavailable,
  number_of_pros_unresponsive,
  time_til_appointment,
  blind_auction_opt_in,
}) {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_view_alternative_times';
  const eventHash = {
    click_source,
    is_rebook,
    // ab_test_booking_flow,
    market,
    is_special_request,
    is_today,
    lead_time_selected,
    number_of_requested_pros,
    number_of_pros_unavailable,
    number_of_pros_unresponsive,
    time_til_appointment,
    blind_auction_opt_in: blind_auction_opt_in ? 'True' : 'False',
  };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function seConfirmAlternativeTimes(time_til_appointment, is_rebook, objt) {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_confirm_alternative_times';
  const eventHash = {
    time_til_appointment,
    is_rebook: is_rebook ? 'Yes' : 'No',
    ...objt,
  };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function seDismissAlternativeTimes(is_rebook, objt) {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_dismiss_alternative_times';
  const eventHash = {
    is_rebook: is_rebook ? 'Yes' : 'No',
    ...objt,
  };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function seFindNewProvider(time_til_appointment, is_rebook) {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_find_new_provider';
  const eventHash = {
    is_rebook: is_rebook ? 'Yes' : 'No',
    time_til_appointment,
  };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}

// APPOINTMENTS LIST EVENTS - BOOKINGS PAGE
export function seViewAppointments(state, click_source) {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_view_appointments';
  const eventHash = {
    state,
    click_source,
  };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}

// APPOINTMENTS RESCHEDULE
export function seRescheduleInitiate({
  appointment_id, state, service_category, service_modality, client_id, cart_product_count,
}) {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_appointment_reschedule_initiate';
  const eventHash = {
    appointment_id,
    state,
    service_category,
    service_modality,
    client_id,
    cart_product_count,
  };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function seProviderContact(click_source) {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_provider_contact';
  const eventHash = {
    click_source,
  };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function seRescheduleReview({
  appointment_id, state, service_category, service_modality, client_id, cart_product_count,
  same_day, ic_utilized, late_reschedule, different_pro, price_adjustment,
}) {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_appointment_reschedule_review';
  const eventHash = {
    appointment_id,
    state,
    service_category,
    service_modality,
    client_id,
    cart_product_count,
    same_day,
    ic_utilized,
    late_reschedule,
    different_pro,
    price_adjustment,
  };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function seRescheduleComplete({
  appointment_id, client_id, state, service_category, service_modality,
  same_day, ic_utilized, late_reschedule, cart_product_count, changed_payment_method,
  different_pro, price_adjustment, number_of_requested_pros,
}) {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_appointment_reschedule';
  const eventHash = {
    appointment_id,
    client_id,
    state,
    service_category,
    service_modality,
    same_day,
    ic_utilized,
    late_reschedule,
    cart_product_count,
    changed_payment_method,
    different_pro,
    price_adjustment,
    number_of_requested_pros,
  };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function seRescheduleRequestView({ multiple_times, same_day }) {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_reschedule_request_view';
  const eventHash = {
    multiple_times,
    same_day,
  };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function seRescheduleRequestDismiss() {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_reschedule_request_dismiss';
  const eventHash = {};

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function seRescheduleRequestDecline({ multiple_times, same_day }) {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_reschedule_request_decline';
  const eventHash = { multiple_times, same_day };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function seRescheduleRequestAccept({ multiple_times, same_day }) {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_reschedule_request_accept';
  const eventHash = { multiple_times, same_day };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}
// INBOX
export function seInboxView(state, click_source) {
  // nav_bar, deep_link
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_inbox_view';
  const eventHash = { state, click_source };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function seInboxViewMessage({
  state, click_source, cart_product_count, booking_status,
  is_rebook, service_category, service_modality,
}) {
  // empty, unread, read
  // nav_bar, deep_link, appointment_details, sr_response
  // 1, 2
  // completed, cancelled, created, pending
  // True, False
  // Massage, Beauty, Hair, Nails, etc.
  // Deep Tissue, etc.
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_inbox_view_message';
  const eventHash = {
    state,
    click_source,
    cart_product_count,
    booking_status,
    is_rebook,
    service_category,
    service_modality,
  };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function seInboxSendMessage(cart_product_count) {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_inbox_send_message';
  const eventHash = {
    cart_product_count,
  };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function seInboxReportPro(reason) {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_inbox_report_pro';
  const eventHash = {
    reason,
  };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function seInboxToggleAptDetails() {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_inbox_toggle_apt_details';
  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, {});
}
export function seViewHelpCenter(click_source = 'inbox_message') {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;
  const eventName = 'se_view_help_center';
  const eventHash = {
    click_source,
  };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function seAddToCalendar(click_source = 'inbox_message') {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;
  const eventName = 'se_add_to_calendar';
  const eventHash = {
    click_source,
  };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function seProfileViewed(pro_id, rating_value, bookings_count, click_source = 'pick_a_pro', ab_test_booking_flow, market, is_ic) {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;
  const eventName = 'se_profile_viewed';
  const eventHash = {
    pro_id,
    rating_value,
    bookings_count,
    click_source,
    // ab_test_booking_flow,
    market,
    is_ic,
  };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}

// BOOKING FLOW FOR MARKETPLACE

export function seDeleteFromCart(cart_product_count, experiment_cohort, click_source = 'review_screen', market = '') {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_delete_from_cart';
  const eventHash = {
    cart_product_count,
    // experiment_cohort,
    click_source,
    market,
  };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}

// PHONE VERIFICATION
export function sePhoneVerificationInitiate(click_source, experiment_cohort = 'user_auth_control') {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_phone_verification_initiate';
  const eventHash = {
    click_source, // account_info (mobile app only), sign_up, log_in, booking_flow
    // experiment_cohort //New: user_auth_variant, user_auth_control
  };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function sePhoneVerificationComplete(click_source, experiment_cohort = 'user_auth_control') {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_phone_verification_complete';
  const eventHash = {
    click_source, // account_info (mobile app only), sign_up, log_in, booking_flow
    // experiment_cohort //New: user_auth_variant, user_auth_control
  };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function sePhoneVerificationSkip(click_source, experiment_cohort = 'user_auth_control') {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_phone_verification_skip';
  const eventHash = {
    click_source, // sign_up, log_in
    // experiment_cohort //New: user_auth_variant, user_auth_control
  };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}

// RESET PASSWORD EVENTS
export function seResetPasswordInitiate(logged_in, click_source) {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_reset_password_initiate';
  const eventHash = {
    click_source, // Log In, Settings, Forced Flow
    logged_in: logged_in ? 'True' : 'False', // True, False
  };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function seResetPasswordEmailSent(logged_in, click_source) {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_reset_password_email_sent';
  const eventHash = {
    click_source, // Log In, Settings, Forced Flow
    logged_in: logged_in ? 'True' : 'False', // True, False
  };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function seResetPasswordFinalScreen(logged_in, click_source) {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_reset_password_final_screen';
  const eventHash = {
    click_source, // Log In, Settings, Forced Flow
    logged_in: logged_in ? 'True' : 'False', // True, False
  };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function seResetPasswordPasswordComplete(logged_in, click_source) {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_reset_password_complete';
  const eventHash = {
    click_source, // Log In, Settings, Forced Flow
    logged_in: logged_in ? 'True' : 'False', // True, False
  };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function seResetPasswordForcedFlowView() {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_reset_password_forced_flow_view';
  const eventHash = {};

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function sePickAProSelect({
  ab_test_booking_flow, market, list_position, rating_value, bookings_count, is_ic,
  test_name, test_value,
}) {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_pick_a_pro_select';
  const eventHash = {
    // ab_test_booking_flow,
    market,
    list_position,
    rating_value,
    bookings_count,
    is_ic,
    ...(test_value && { [`ab_test_${test_name}`]: test_value, })
  };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function sePickAProUnselect({
  ab_test_booking_flow, market, list_position, rating_value, bookings_count,
  test_name, test_value,
}) {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_pick_a_pro_unselect';
  const eventHash = {
    // ab_test_booking_flow,
    market,
    list_position,
    rating_value,
    bookings_count,
    ...(test_value && { [`ab_test_${test_name}`]: test_value, })
  };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function seRequestedProToasterView({
  ab_test_booking_flow, market, number_of_requested_pros, number_of_pros_unavailable,
  number_of_pros_unresponsive, number_of_pros_alt_times,
  is_today, lead_time_selected, blind_auction_opt_in,
}) {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_requested_pro_toaster_view';
  const eventHash = {
    // ab_test_booking_flow,
    market,
    number_of_requested_pros,
    number_of_pros_unavailable,
    number_of_pros_unresponsive,
    number_of_pros_alt_times,
    is_today,
    lead_time_selected,
    blind_auction_opt_in: blind_auction_opt_in ? 'True' : 'False',
  };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function seBookingScheduleView({ market, service_nb_pro, test_name, test_value }) {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_booking_schedule_view';
  const eventHash = {
    market,
    service_nb_pro,
    ...(test_value && { [`ab_test_${test_name}`]: test_value, })
  };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}

// PAP MARKETPLACE V2 EVENTS
export function seAptAddProsToCartView({
  market, number_of_requested_pros, number_of_pros_displayed, click_source,
}) {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_appointment_add_pros_to_cart_view';
  const eventHash = {
    market, number_of_requested_pros, number_of_pros_displayed, click_source,
  };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}

export function seAptAddProsToCartComplete({
  market, number_of_requested_pros, number_of_pros_displayed, number_of_pros_added, click_source,
}) {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_appointment_add_pros_to_cart_complete';
  const eventHash = {
    market,
    number_of_requested_pros,
    number_of_pros_displayed,
    number_of_pros_added,
    click_source,
  };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function seSelfServeViewed({
  market, self_serve_type, click_source, is_special_request, is_today,
  lead_time_selected, number_of_requested_pros, time_til_appointment, is_blind_auction,
}) {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_self_serve_viewed';
  const eventHash = {
    market,
    self_serve_type,
    click_source,
    is_special_request,
    is_today,
    lead_time_selected,
    number_of_requested_pros,
    time_til_appointment,
    is_blind_auction,
  };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}
export function seTryAnotherTime({
  market, click_source, self_serve_type, is_today, lead_time_selected,
  number_of_requested_pros, time_til_appointment,
}) {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_try_another_time';
  const eventHash = {
    market,
    click_source,
    self_serve_type,
    is_today,
    lead_time_selected,
    number_of_requested_pros,
    time_til_appointment,
  };

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}

// EVENTS BOOKING
export function seEventForm(event_type) {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_events_initial_form';
  const eventHash = {
    event_type,
  }

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}

export function seEventContactMe() {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_events_contact_me_open';
  const eventHash = {
    is_event: true
  }

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}

export function seEventContactMeClose() {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_events_contact_me_close';
  const eventHash = {
    is_event: true
  }

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}

export function seEventContactMeSubmit() {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_events_contact_me_submit';
  const eventHash = {
    is_event: true
  }

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}

export function seEventContactMeCheckbox() {
  if (process.env.REACT_APP_PRODUCTION !== 'true') return;

  const eventName = 'se_events_contact_me_checkbox';
  const eventHash = {
    is_event: true
  }

  // Amplitude Events
  Amplitude.getInstance().logEvent(eventName, eventHash);
}