/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/sort-comp */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import { capitalize, get } from 'lodash';
import { withLocalize, Translate } from 'react-localize-redux';
import { defaultErrorMessage, pleaseEnterAGiftCodeError } from '../../../constants';
import { setCart } from '../../../Actions';
import { API_ROOT, ACCEPT_LANGUAGE_HEADER } from '../../../apiConfig';
import bookingTranslations from '../../../Translations/bookingFlow.json';
import Modal from '../../Shared/Modal';
import ErrorModal from '../../Shared/ErrorModal';
import { decode } from '../Shared/encode';
import '../../BookingFlow/Payment/Assets/Styles/Payments.css';
import TextWithIcon from '../../Shared/TextWithIcon';
import GiftIcon from './Assets/Images/Gift_icon.png';
import ModalRedesign from '../../Shared/ModalRedesign';

const svsGiftCardRegex = /^\d{19}$/;
class GiftCardsAndPromoCodes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            svsPinCode: '',
            giftCode: '',
            isSvsGiftCode: false,
            showErrorModal: false,
            showSuccessModal: false,
            successMessage: null,
            error: null,
            showGCModal: false,
        };

        this.setCart = this.setCart.bind(this);
        this.handleSvsPinChange = this.handleSvsPinChange.bind(this);
        this.errorModal = this.errorModal.bind(this);
        this.successModal = this.successModal.bind(this);
        this.renderGiftCodeForm = this.renderGiftCodeForm.bind(this);
        this.submitGiftCode = this.submitGiftCode.bind(this);
        this.handleGiftCodeChange = this.handleGiftCodeChange.bind(this);

        this.props.addTranslation(bookingTranslations);
    }

    handleGiftCodeChange(event) {
        event.preventDefault();
        this.setState({
            giftCode: event.target.value,
            isSvsGiftCode: svsGiftCardRegex.test(event.target.value || event.target.value.toString()),
        });
    }

    submitGiftCode(event) {
        if (event) {
            event.preventDefault();
        }
        const { giftCode, svsPinCode } = this.state;

        if (giftCode) {
            const giftCardData = {
                gift_code: giftCode,
            };

            if (svsPinCode) {
                giftCardData.svs_pin = svsPinCode;
            }

            axios.post(
                `${API_ROOT}/v7/carts/${this.props.booking.cart.id}/apply_gift_code`,
                { cart: giftCardData },
                { withCredentials: true },
                ACCEPT_LANGUAGE_HEADER,
            ).then((resp) => {
                const { result, svs_gift_card_success_message } = resp.data;

                if (result) {
                    this.props.assignToCart({ cart: decode(resp.data.cart) });

                    this.setState({
                        giftCode: '',
                        svsPinCode: '',
                        isSvsGiftCode: false,
                        showSuccessModal: Boolean(svs_gift_card_success_message),
                        successMessage: svs_gift_card_success_message,
                        showGCModal: false,
                    });
                } else {
                    this.setState({ showErrorModal: true, error: get(resp, 'data.errors.0.message', defaultErrorMessage) });
                }
            }).catch((error) => {
                this.setState({ showErrorModal: true, error: get(error, 'response.data.errors.0.message', defaultErrorMessage) || defaultErrorMessage });
            });
        } else {
            this.setState({ showErrorModal: true, error: pleaseEnterAGiftCodeError });
        }
    }

    setCart(value) {
        this.props.setCart({
            ...this.props.booking,
            ...value,
        });
    }

    handleSvsPinChange(event) {
        event.preventDefault();
        this.setState({
            svsPinCode: event.target.value,
        });
    }

    renderGiftCodeForm() {
        if (this.props.is_event) {
            return (
                <form onSubmit={this.submitGiftCode}>
                    <input
                        className="form-control gift-code-input gray-input-style contentPrimary"
                        value={this.state.giftCode || ''}
                        placeholder="Enter Code"
                        onChange={this.handleGiftCodeChange}
                        id="giftCardInput"
                        autoComplete='off'
                    />
                    {this.state.isSvsGiftCode
                        && (
                            <input
                                className="form-control gift-code-input gray-input-style contentPrimary"
                                value={this.state.svsPinCode || ''}
                                placeholder="Pin Code"
                                onChange={this.handleSvsPinChange}
                                autoComplete='off'
                            />
                        )}
                </form>
            );
        }
        return (
            <ModalRedesign
                title="Apply Code"
                relevantId="giftCardAndPromoCodePopup"
                isOpen={this.state.showGCModal}
                close={() => {
                    this.setState({ showGCModal: false, giftCode: '', isSvsGiftCode: false });
                }}
                apply={this.submitGiftCode}
                relevantBtnId="giftCardAndPromoCodeSubmitBtn"
            >
                <div className='contentTertiary size-12-20 mb-8'>Enter the gift card code, promo code or referral code to apply to this service.</div>
                <input
                    className="form-control gift-code-input gray-input-style contentPrimary"
                    value={this.state.giftCode || ''}
                    placeholder="Enter Code"
                    onChange={this.handleGiftCodeChange}
                    id="giftCardInput"
                    autoComplete='off'
                />
                {this.state.isSvsGiftCode
                    && (
                        <input
                            className="form-control gift-code-input gray-input-style contentPrimary"
                            value={this.state.svsPinCode || ''}
                            placeholder="Pin Code"
                            onChange={this.handleSvsPinChange}
                            autoComplete='off'
                        />
                    )}
            </ModalRedesign>
        );

    }

    errorModal() {
        return (
            <ErrorModal
                isOpen={this.state.showErrorModal}
                close={() => { this.setState({ showErrorModal: false, error: null }); }}
            >
                <p>{this.state.error}</p>
            </ErrorModal>
        );
    }

    successModal() {
        return (
            <Translate>
                {({ translate }) => (
                    <Modal title={capitalize(translate('global.confirmation'))} isOpen={this.state.showSuccessModal} close={() => { this.setState({ showSuccessModal: false, successMessage: null }); }} showBtn btnCopy="Close">
                        <p>{this.state.successMessage}</p>
                    </Modal>
                )}
            </Translate>
        );
    }

    render() {
        const { is_event } = this.props;
        if (is_event) {
            return (
                <>
                    <div className='contentPrimary size-24-32 medium-font mb-8'>Gift Cards & Promo Codes</div>
                    <div className='contentPrimary size-16-20 medium-font mt-16 mb-8'>Apply Code</div>
                    <div className='contentTertiary size-12-20 mb-8'>Enter the gift card code, promo code or referral code to apply to this service.</div>
                    {this.renderGiftCodeForm()}
                    {this.errorModal()}
                    {this.successModal()}
                </>);
        }
        return (
            <>
                <div
                    className="clickable"
                    id="giftCardAndPromoCode"
                    onClick={() => { this.setState({ showGCModal: true }); }}
                >
                    <TextWithIcon
                        icon={GiftIcon}
                        title="Gift Cards & Promo Codes"
                        details="Enter the gift card code, promo code or referral code to apply to this service."
                        displayBorder
                        displayArrow
                    />
                </div>

                {this.renderGiftCodeForm()}
                {this.errorModal()}
                {this.successModal()}
            </>
        )
    }
}
const mapStateToProps = (state) => ({
    booking: state.booking,
    addresses: state.addresses,
    client: state.client,
});

GiftCardsAndPromoCodes.propTypes = {
    booking: PropTypes.object.isRequired,
    client: PropTypes.object.isRequired,
};

export default withLocalize(connect(mapStateToProps, { setCart })(GiftCardsAndPromoCodes));
