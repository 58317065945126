/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { Tooltip, withStyles } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';

const HtmlTooltip = withStyles((theme) => ({
  arrow: (props) => ({
    color: props.bgColor || '#586B94',
  }),
  tooltip: (props) => ({
    backgroundColor: props.bgColor || '#586B94',
    color: props.color || '#fff',
    fontSize: '12px',
    lineHeight: '20px',
    padding: '16px',
  }),
}))((props) => <Tooltip {...props} classes={props.classes} />);
export default function TriggersTooltips(props) {
  return (
    <HtmlTooltip arrow title={props.title} interactive {...props}>
      {props.customIcon ? props.customIcon : <InfoOutlined className="contentTertiary" />}
    </HtmlTooltip>
  );
}
