import React from 'react';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import { find, get, indexOf, isEmpty, isEqual, size, split } from 'lodash';
import { setBookingFlowStep, setCart, setAddresses, setProducts, setBookingFlowPreviousStep, setAvailableTherapists, setBookingFlowComingFrom } from '../../../Actions';
import './Assets/Timing.css';
import '../Assets/Styles/index.css';
import DayPicker from './DayPicker';
import HourPicker from './HourPicker';
import CTAButton from '../../Shared/CTAButton';
import { STEPS, momentCap } from '../Shared/constants';
import moment from 'moment';
import { seBookingSchedule, seBookingScheduleView } from '../../Shared/WebAnalytics';
import { getAvailableTherapists, guestCreateCart, updateCart } from '../Shared/helpers';
import { decode } from '../Shared/encode';
import { defaultErrorMessage, HotJar, isGuestUser, hasUuid, NOT_BEFORE_H } from '../../../constants';
import Slide from "@material-ui/core/Slide";
import TimePicker from './TimePicker';
HotJar();

const yesterday = moment(new Date()).subtract(1, 'day');
const roundedUp = Math.ceil(moment(new Date()).minute() / 15) * 15;
const daysOfWeekIndexes = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]

class Index extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            sessionDate: '',
            sessionTime: '',
            nextView: false,
            backToBack: false,
            showBackToBackModal: false,
            todayMinTime: moment(new Date()).add(NOT_BEFORE_H, 'hours').minutes(roundedUp).seconds(0),
            todayMaxTime: moment(new Date()).set({ hour: 22, minute: 0, second: 0, millisecond: 0 }),
            slideFlag: true,
            workingHours: []
        }
        this.goToNextStep = this.goToNextStep.bind(this);
        this.updateCartDateTime = this.updateCartDateTime.bind(this);
        this.dateValue = this.dateValue.bind(this);
        this.timeValue = this.timeValue.bind(this);
        this.dayOf = this.dayOf.bind(this);
        this.isValidDate = this.isValidDate.bind(this);
        this.onDayChange = this.onDayChange.bind(this);
        this.updateCartCallback = this.updateCartCallback.bind(this);
        this.prepareForGuestNextStep = this.prepareForGuestNextStep.bind(this);
        this.papCallback = this.papCallback.bind(this);
        this.refreshHrs = this.refreshHrs.bind(this);
        this.isDisabled = this.isDisabled.bind(this);
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        seBookingScheduleView({ market: get(this.props, "booking.cart.address.market", ""), service_nb_pro: size(get(this.props, "bookingFlow.availableTherapists", [])) })
        this.setState({
            workingHours: get(this.props, "fieldsHolder.listingPageStorage.working_hours", [])
        }, () => {
            const sessionDate = this.dateValue();
            this.setState({
                sessionDate: moment(sessionDate).format('MM/DD/YYYY')
            }, () => {
                this.setState({
                    sessionTime: this.timeValue(),
                    todayMinTime: this.dayOf() ? moment(new Date()).add(NOT_BEFORE_H, 'hours').minutes(roundedUp).seconds(0) : moment(this.state.sessionDate).set({ hour: 8, minute: 0, second: 0, millisecond: 0 }),
                    todayMaxTime: moment(this.state.sessionDate).set({ hour: 22, minute: 0, second: 0, millisecond: 0 })
                })
            })
        })
        let rebook = get(this.props, "booking.cart.rebook", false);
        if (rebook) {
            this.props.changeBackground("");
            this.props.toggleNavBarStyle(false);
            let previousStep = find(STEPS, (step) => (step.id === 'MODALITY'));
            this.props.setBookingFlowPreviousStep(previousStep);
        } else if (!hasUuid()) {
            this.props.changeBackground("none");
            this.props.toggleNavBarStyle(true);
            let previousStep = find(STEPS, (step) => (step.id === 'MODALITY'));
            this.props.setBookingFlowPreviousStep(previousStep);
        } else {
            this.props.toggleNavBarStyle(!get(this.props, "fieldsHolder.listingPageStorage.bannerimage", ""));
            this.props.changeBackground(get(this.props, "fieldsHolder.listingPageStorage.bannerimage", "") || "none");
        }
        let loader = this.props.setLoaderFlag;
        if (loader) {
            loader(true)
        }
        setTimeout(this.refreshHrs, 1500)
    }
    refreshHrs() {
        let loader = this.props.setLoaderFlag;
        this.props.changeBackground(get(this.props, "fieldsHolder.listingPageStorage.bannerimage", "") || "none");
        if (isEmpty(this.state.workingHours) && !isEmpty(get(this.props, "fieldsHolder.listingPageStorage.working_hours", []))) {
            this.setState({
                workingHours: get(this.props, "fieldsHolder.listingPageStorage.working_hours", [])
            }, () => {
                const sessionDate = this.dateValue();
                this.setState({
                    sessionDate: moment(sessionDate).format('MM/DD/YYYY')
                }, () => {
                    if (loader) {
                        loader(false)
                    }
                    this.setState({
                        sessionTime: this.timeValue(),
                        todayMinTime: this.dayOf() ? moment(new Date()).add(NOT_BEFORE_H, 'hours').minutes(roundedUp).seconds(0) : moment(this.state.sessionDate).set({ hour: 8, minute: 0, second: 0, millisecond: 0 }),
                        todayMaxTime: moment(this.state.sessionDate).set({ hour: 22, minute: 0, second: 0, millisecond: 0 })
                    })
                })
            })
        } else {
            if (loader) {
                loader(false)
            }
        }
    }
    componentDidUpdate() {
        if (!isEqual(this.state.workingHours, get(this.props, "fieldsHolder.listingPageStorage.working_hours", []))) {
            this.refreshHrs()
        }
    }

    dayOf() {
        const dateTime = this.state.sessionDate;
        return moment(new Date()).isSame(dateTime, 'day');
    }
    dateValue() {
        let dateUtc = get(this.props, "booking.cart.date.utc", ""),
            wrkDays = this.state.workingHours;
        if (dateUtc) {
            const dateTime = moment(dateUtc);
            if (this.isValidDate(dateTime)) {
                return dateTime;
            } else if (!isEmpty(wrkDays)) {
                let currDayInd = indexOf(daysOfWeekIndexes, dateTime.clone().format("dddd")),
                    curr = find(wrkDays, (el) => (indexOf(daysOfWeekIndexes, el.weekname) >= currDayInd));
                if (isEmpty(curr)) {
                    curr = get(wrkDays, "0", null);
                }
                let splittedFrom = split(get(curr, "from", "08:00:00"), ":"),
                    hour = Number(get(splittedFrom, "0", "0")), minute = Number(get(splittedFrom, "1", "0")), second = Number(get(splittedFrom, "2", "0"));

                let newVal = dateTime.clone().isoWeekday(indexOf(daysOfWeekIndexes, get(curr, "weekname", "Monday")) + 1).set({ hour, minute, second });
                if (moment(newVal).isBefore(dateTime, 'day')) {
                    newVal = dateTime.clone().isoWeekday(indexOf(daysOfWeekIndexes, get(curr, "weekname", "Monday")) + 8).set({ hour, minute, second });
                }
                return newVal;
            }
        }
        let defaultOpt = momentCap(new Date());
        if (!this.isValidDate(defaultOpt) && !isEmpty(wrkDays)) {
            let currDayInd = indexOf(daysOfWeekIndexes, defaultOpt.clone().format("dddd")),
                curr = find(wrkDays, (el) => (indexOf(daysOfWeekIndexes, el.weekname) >= currDayInd));
            if (isEmpty(curr)) {
                curr = get(wrkDays, "0", null);
            }
            let splittedFrom = split(get(curr, "from", "08:00:00"), ":"),
                hour = Number(get(splittedFrom, "0", "0")), minute = Number(get(splittedFrom, "1", "0")), second = Number(get(splittedFrom, "2", "0"));

            let newVal1 = momentCap(defaultOpt.clone().isoWeekday(indexOf(daysOfWeekIndexes, get(curr, "weekname", "Monday")) + 1).set({ hour, minute, second }).format())
            if (newVal1.isBefore(defaultOpt, 'day')) {
                newVal1 = momentCap(defaultOpt.clone().isoWeekday(indexOf(daysOfWeekIndexes, get(curr, "weekname", "Monday")) + 8).set({ hour, minute, second }).format())
            }
            return newVal1;
        }
        return defaultOpt;
    }
    isDisabled(time) {
        let date = this.state.sessionDate;
        let enabledHours = this.state.workingHours;
        if (isEmpty(enabledHours)) {
            return false;
        }
        let dateSelected = moment(date).format("YYYY-MM-DD"),
            dayOfWeek = moment(dateSelected).format("dddd"),
            obj = find(enabledHours, (el) => (el.weekname === dayOfWeek));
        if (obj) {
            let fromH = obj && obj.from && moment.duration(`${obj.from}`).asMinutes(),
                tillH = obj && obj.till && moment.duration(`${obj.till}`).asMinutes(),
                formattedTimeH = time && moment(`${dateSelected} ${time}`, 'YYYY-MM-DD h:mm A').format("HH:mm"),
                timeH = moment.duration(formattedTimeH).asMinutes();
            if (fromH && tillH && timeH >= fromH && timeH <= tillH) {
                return false
            }
        }
        return true
    }

    timeValue() {
        let sessionDate = this.state.sessionDate;
        sessionDate = moment(sessionDate);
        let timeUtc = get(this.props, "booking.cart.time.utc", "");
        if (timeUtc) {
            let sessionDatetime = moment(timeUtc).month(sessionDate.month()).date(sessionDate.date()).year(sessionDate.year()).format('h:mm A');
            const todaysCap = momentCap(sessionDate.toDate());

            // if the time is not avail for date then set to the min time for date
            if (todaysCap > sessionDatetime) {
                return todaysCap.format('h:mm A');
            } else {
                return this.props.booking.cart.time.display;
            }
        }
        let time = momentCap(sessionDate.toDate()).format('h:mm A');
        if (this.isDisabled(time)) {
            let dateSelected = moment(sessionDate.toDate()).format("YYYY-MM-DD"),
                dayOfWeek = moment(dateSelected).format("dddd"),
                obj = find(this.state.workingHours, (el) => (el.weekname === dayOfWeek));
            if (obj) {
                return moment(`${dateSelected} ${get(obj, "from", "")}`, "YYYY-MM-DD HH:mm:ss").format('h:mm A')
            }
        }
        return time;
    }
    updateCartCallback(response) {
        let { result } = response.data;
        let loader = this.props.setLoaderFlag;
        if (loader) {
            loader(false)
        }
        if (result) {
            this.props.assignToCart({ cart: decode(response.data.cart) });

            this.goToNextStep();
        } else {
            let message = get(response, 'data.errors.0.message', defaultErrorMessage);
            this.setState({ showErrorModal: true, message });
        }
    }
    updateCartDateTime(sessionDate, sessionTime) {
        let cartId = get(this.props, "booking.cart.id", "");
        let loader = this.props.setLoaderFlag,
            rebook = get(this.props, "booking.cart.rebook", false);
        if (isGuestUser() && !cartId) {
            if (loader) {
                loader(true)
            }
            let cart = get(this.props, "fieldsHolder.listingPageStorage.cart", {}),
                address = get(this.props, "fieldsHolder.listingPageStorage.address", "");
            guestCreateCart({
                ...cart,
                "session_date": moment(sessionDate).format('YYYY-MM-DD'),
                "session_time": moment(this.state.sessionTime, 'h:mm A').format('HH:mm')
            }, address, get(this.props, "fieldsHolder.csrfToken", ""),
                this.updateCartCallback,
                (err) => {
                    if (loader) {
                        loader(false)
                    }
                    console.log({ err })
                    this.props.displayError(get(err, 'response.data.errors.0.message', defaultErrorMessage))
                }
            )
        } else if (cartId) {
            if (loader) {
                loader(true)
            }
            updateCart(cartId, {
                "session_date": moment(sessionDate).format('YYYY/MM/DD'),
                "session_time": sessionTime,
                "remove_pick_a_pro": rebook ? false : true
                // TODO get back and recheck the case with rebook
            }, get(this.props, "fieldsHolder.csrfToken", ""), this.updateCartCallback, (err) => {
                if (loader) {
                    loader(false)
                }
                this.props.displayError(get(err, 'response.data.errors.0.message', defaultErrorMessage))
            });
        }
    }
    prepareForGuestNextStep(cb) {

        let cart = get(this.props, "booking.cart", null),
            cartProducts = get(cart, "cartProducts", []),
            pickAProEnabled = get(cart, "pickAProEnabled", false);
        let loader = this.props.setLoaderFlag;
        if (pickAProEnabled) {
            if (loader) {
                loader(true)
            }
            getAvailableTherapists(cart.id, get(cartProducts, '0.id', ''), get(cart, "date.utc", ""), get(this.props, "fieldsHolder.csrfToken", ""), (response) => {
                if (loader) {
                    loader(false)
                }
                let providers = get(response, "data.providers", []);
                this.props.setAvailableTherapists(providers);
                cb();
            }, () => {
                if (loader) {
                    loader(false)
                }
                this.props.setAvailableTherapists([]);
                cb();
            });
        } else {
            this.props.setAvailableTherapists([]);
            cb();
        }
    }
    papCallback() {
        let pickAProEnabled = get(this.props, "booking.cart.pickAProEnabled", false),
            personsCount = get(this.props, "bookingFlow.recipientOption.personsCount", 1),
            productsCount = size(get(this.props, "booking.cart.cartProducts", [])),
            backToBackAvailable = get(this.props, "booking.cart.backToBack.available", false),
            nextStepKey = "REVIEW",
            rebook = get(this.props, "booking.cart.rebook", false);

        if (!rebook && (personsCount > 1 || productsCount > 1) && backToBackAvailable) {
            nextStepKey = "COUPLE_PREFERENCE";
        } else if (!rebook && pickAProEnabled && !isEmpty(get(this.props, "bookingFlow.availableTherapists", []))) {
            nextStepKey = "PICKAPRO";
        }
        let nextStepCustom = find(STEPS, (step) => (step.id === nextStepKey));
        let cart = get(this.props, "booking.cart", null);
        let productTitle = get(this.props, "booking.product.title", "").toLowerCase(),
            isFirstTimeBooker = get(this.props, "client.first_time_booker", false);

        seBookingSchedule(cart, productTitle, isFirstTimeBooker, "booking_23_control", size(get(this.props, "bookingFlow.availableTherapists", [])));

        this.props.setBookingFlowComingFrom("TIMING")
        this.props.setBookingFlowStepThroughParent(nextStepCustom);
    }
    goToNextStep() {
        this.prepareForGuestNextStep(this.papCallback);
    }
    onDayChange(day, dayString) {
        let sessionTime = moment(this.state.sessionTime, 'h:mm A');
        const sessionDatetime = sessionTime.month(day.month()).date(day.date()).year(day.year());
        const todaysCap = momentCap(day.toDate());

        // if the time is not avail for date then set to the min time for date
        if (todaysCap > sessionDatetime) {
            this.setState({ sessionDate: dayString, sessionTime: todaysCap.format('h:mm A') }, () => {
                this.setState({
                    todayMinTime: this.dayOf() ? moment(new Date()).add(NOT_BEFORE_H, 'hours').minutes(roundedUp).seconds(0) : moment(this.state.sessionDate).set({ hour: 8, minute: 0, second: 0, millisecond: 0 }),
                    todayMaxTime: moment(this.state.sessionDate).set({ hour: 22, minute: 0, second: 0, millisecond: 0 })
                })
            });
        } else {
            this.setState({ sessionDate: dayString }, () => {
                this.setState({
                    sessionTime: this.timeValue(),
                    todayMinTime: this.dayOf() ? moment(new Date()).add(NOT_BEFORE_H, 'hours').minutes(roundedUp).seconds(0) : moment(this.state.sessionDate).set({ hour: 8, minute: 0, second: 0, millisecond: 0 }),
                    todayMaxTime: moment(this.state.sessionDate).set({ hour: 22, minute: 0, second: 0, millisecond: 0 })
                })
            });
        }
    }
    isValidDate(currentDate) {
        let todayMinTime = this.state.todayMinTime, todayMaxTime = this.state.todayMaxTime;
        const now = moment(new Date());

        let disabledHours = this.state.workingHours;
        if (!isEmpty(disabledHours)) {
            let dayName = currentDate.clone().format("dddd"),
                obj = find(disabledHours, (el) => (el.weekname === dayName));
            if (isEmpty(obj)) {
                return false;
            }
        }

        if (currentDate.isSame(now, 'day')) {
            if (todayMaxTime.isBefore(todayMinTime)) {
                // There is not available slots today
                return false;
            }

            return now.isSameOrBefore(todayMaxTime) && currentDate.isSameOrBefore(momentCap(new Date()));
        }

        return currentDate.isAfter(yesterday);
    }

    render() {
        let { todayMaxTime, todayMinTime, slideFlag } = this.state,
            guest = hasUuid(),
            rebook = get(this.props, "booking.cart.rebook", false);
        return (<Slide direction="left" in={slideFlag} mountOnEnter unmountOnExit>
            <div className='display-flex'>
                <div className={rebook ? "max-w-50-vw" : 'max-width-47'}>
                    <div className={`${guest || rebook ? 'color-white txt-shadow sm-color-black' : 'color-black'} medium-font main-text size-44-52`}>When would you like it?</div>
                    <div className={` ${rebook ? "" : "max-width-375 background-primary"} sm-pb-100 border-radius-16`}>
                        {rebook ?
                            <TimePicker action={this.updateCartDateTime} setLoaderFlag={this.props.setLoaderFlag} assignToCart={this.props.assignToCart} />
                            : <>
                                <DayPicker
                                    value={this.state.sessionDate}
                                    timeFormat={false}
                                    onChange={this.onDayChange}
                                    isValidDate={this.isValidDate}
                                />
                                <HourPicker
                                    date={this.state.sessionDate}
                                    time={this.state.sessionTime}
                                    minTime={todayMinTime.format('h:mm A')}
                                    maxTime={todayMaxTime.format('h:mm A')}
                                    onChange={(sessionTime) => { this.setState({ sessionTime }); }}
                                />
                                <CTAButton text="Continue" action={() => {
                                    this.updateCartDateTime(this.state.sessionDate, this.state.sessionTime);
                                }} additionalClass="w-80"
                                    additionalWrapperClass="justify-content-center"
                                    disabled={todayMinTime.isAfter(todayMaxTime)}
                                    relevantId="continueButton"
                                />
                            </>}
                    </div>
                </div>
            </div>
        </Slide>
        );
    }
}

const mapStateToProps = state => ({
    booking: state.booking,
    client: state.client,
    bookingFlow: state.bookingFlow,
    fieldsHolder: state.fieldsHolder
});

export default withLocalize(connect(mapStateToProps, { setBookingFlowStep, setCart, setAddresses, setProducts, setBookingFlowPreviousStep, setAvailableTherapists, setBookingFlowComingFrom })(Index));