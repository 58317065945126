import {
  get, identity, mapKeys, omit, pickBy, size, snakeCase,
  take,
} from 'lodash';
import { isEventBooking } from '../../../constants';

export function encode(data, confirm = false) {
  let params = pickBy(mapKeys(data, (value, key) => snakeCase(key)), identity);

  params = omit(params, ['address',
    'top_billing_line_items',
    'bottom_billing_line_items',
    'status',
    'subtotal_price',
    'total_price',
    'back_to_back']);

  if (confirm) {
    return params;
  }
  return omit(params, 'credit_card_id');
}

export function decode(data) {
  const { settable_fields, info_fields } = data;
  const v0Cart = get(data, 'display_helpers.v0', {});
  const {
    id, status, time, date, address, tracking, terms,
    subscriptions, fees, billing, extensions, rebook,
    pap_therapist_preferences, blind_auction_switch, request_pros_limit_reached,
    is_event, cart_products, event, event_pros,
  } = v0Cart;
  let cartProducts = cart_products;
  // if (isEventBooking() && size(cartProducts) > 1) {
  //   cartProducts = take(cartProducts, 1);
  // }

  return {
    id,
    status,
    time,
    date,
    address,
    tracking,
    terms,
    subscriptions,
    fees,
    billing,
    extensions,
    rebook,
    pap_therapist_preferences,
    currencySymbol: v0Cart.currency_symbol,
    giftCode: v0Cart.gift_code,
    backToBack: v0Cart.back_to_back,
    cart_messages: v0Cart.cart_messages,
    cartProducts,
    pickAProEnabled: v0Cart.pick_a_pro_enabled || false,
    therapist_preferences: v0Cart.therapist_preferences,
    settable_fields,
    info_fields,
    blind_auction_switch,
    request_pros_limit_reached,
    is_event,
    event,
    event_pros,
  };
}
