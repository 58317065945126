import React from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { defaultErrorMessage, HotJar } from '../../constants';
import "./index.css";
import PlaceAutoCompleteInput from '../Shared/PlaceAutoCompleteInput';
import PersonalSvg from '../../Assets/Images/personal_event_img.svg';
import CorporateSvg from '../../Assets/Images/corporate_event_img.svg';
import BlueCheckLottie from '../Shared/BlueCheckLottie';
import Datetime from 'react-datetime';
import moment from 'moment';
import CTAButton from '../Shared/CTAButton';
import { contactRepresentative, initCartJson } from '../Shared/Helpers';
import CheckboxOptions from '../Shared/CheckboxOptions';
import CenteredGrids from '../Shared/CenteredGrids';
import EmailInput from '../Shared/EmailInput';
import PhoneInput from '../Shared/PhoneInput';
import { get, pick, toLower } from 'lodash';
import LoadingOverlay from '../Shared/LoadingOverlay';
import ErrorModal from '../Shared/ErrorModal';
import { seEventForm } from '../Shared/WebAnalytics';

HotJar();

class EventForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: '',
            showErrorModal: false,
            width: window.innerWidth,
            loading: false,
            duration: 0,

            showForm: true,
            needARep: false,
            emailIsValid: false,
            phoneIsValid: false,
            date: "",
            dateIsInvalid: "",

            first_name: "",
            last_name: "",
            email: "",
            phone: "",
            occasion: "",
            address: {
                address_line_1: "",
                address_line_2: "",
                city: "",
                state: "",
                zip_code: "",
                country: "",
            },
            kind: "personal",
            company: "",
            isFormValid: false,
            invalidAddress: false,
        };
        this.setValueByField = this.setValueByField.bind(this);
        this.thankYouView = this.thankYouView.bind(this);
        this.formView = this.formView.bind(this);
        this.continueFlowWithRepresentative = this.continueFlowWithRepresentative.bind(this);
        this.continueFlowWithoutRespresentative = this.continueFlowWithoutRespresentative.bind(this);
        this.validateAndsubmitForm = this.validateAndsubmitForm.bind(this);
        this.errorModal = this.errorModal.bind(this);
        this.isValidDate = this.isValidDate.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }
    componentDidMount() {
        window.addEventListener('resize', this.updateWindowDimensions);

        const parsedParams = queryString.parse(window.location.search);
        let kind = toLower(get(parsedParams, 'event_type', ''));
        if (kind) {
            kind = kind === "corporate" ? "corporate" : "personal";
            this.setState({ kind });
        }
        seEventForm(kind || "personal");
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }
    updateWindowDimensions() {
        this.setState({ width: window.innerWidth })
    }
    componentDidUpdate(prevProps) {
        // TODO maybe add a check for checkedout carts of current uuid
        if (!prevProps.user_kind && this.props.user_kind) {
            console.log("EventForm props", this.props);
            this.setState({
                ...pick(this.props, ["first_name", "last_name", "email", "phone", "occasion", "kind", "company", "date"]),
                needARep: Boolean(this.props.first_name),
                date: moment(this.props.session_date, 'YYYY-MM-DD').format('MM/DD/YYYY'),
                address: {
                    ...this.props.address,
                    zip_code: this.props.zipCode,
                }
            }, this.validateForm);
        }
    }
    setValueByField(field, value) {
        this.setState({ [field]: value }, this.validateForm);
    }
    isValidDate(currentDate) {
        let sevenDaysFromNow = moment().add(7, 'days');
        let selectedDate = moment(currentDate);
        return selectedDate.isSameOrAfter(sevenDaysFromNow, "days") ? "" : "invalid";
    }

    continueFlowWithRepresentative() {
        this.setState({ loading: true });
        let payload = pick(this.state, ["first_name", "last_name", "email", "phone", "occasion", "kind", "company", "date"]);
        contactRepresentative(
            {
                ...payload,
                ...this.state.address,
            },
            (_response) => {
                this.setState({ loading: false, showForm: false });
            },
            (error) => {
                const message = get(error, 'response.data.errors.0.message', defaultErrorMessage);
                this.setState({ loading: false, showErrorModal: true, error: message });
            }
        );
    }
    continueFlowWithoutRespresentative() {
        let { occasion, address, kind, company, date } = this.state;
        let cart_json = {
            cart: {
                "user_kind": "guest",
                occasion,
                kind,
                company,
                session_date: moment(date, "MM/DD/YYYY").format('YYYY-MM-DD'),
                event: true,
            },
            address: {
                ...address,
                zipCode: address.zip_code,
            },
        }
        initCartJson(cart_json, (response) => {
            let uuid = get(response, "data.uuid", "");
            if (uuid) {
                window.location.href = `/booking?event-organizer=${uuid}`;
            }
        }, (error) => {
            const message = get(error, 'response.data.errors.0.message', defaultErrorMessage);
            this.setState({ showErrorModal: true, error: message });
        });
    }
    validateForm() {
        let { first_name, last_name, email, phone, occasion, address, kind, company, date, needARep,
            dateIsInvalid, emailIsValid, phoneIsValid, invalidAddress } = this.state;
        let { address_line_1, state, zip_code, country } = address;
        if (!occasion || !kind || !date || dateIsInvalid || !address_line_1 || !state || !zip_code || invalidAddress) {
            this.setState({ error: "Please fill all required fields", isFormValid: false });
            return;
        }
        if (kind === "corporate" && !company) {
            this.setState({ error: "Please fill in a company name", isFormValid: false });
            return;
        }
        if (needARep && (!first_name || !last_name || !email || !phone)) {
            this.setState({ error: "Please fill all required fields", isFormValid: false });
            return;
        }
        if (needARep && (!emailIsValid || !phoneIsValid)) {
            this.setState({ error: `Please fill a valid ${!emailIsValid ? "email" : "phone number"}`, isFormValid: false });
            return;
        }
        this.setState({ isFormValid: true, error: "" });
    }
    validateAndsubmitForm() {
        let { isFormValid, needARep } = this.state;
        this.validateForm()
        if (!isFormValid) {
            this.setState({ showErrorModal: true });
            return;
        };
        if (needARep) {
            this.continueFlowWithRepresentative();
        } else {
            this.continueFlowWithoutRespresentative();
        }
    }

    thankYouView() {
        return (<div className='flex-centered ht-100'>
            <div className='centered'>
                <div className='centered'><BlueCheckLottie /></div>
                <div className='size-44-52 medium-font color-black mb-16'>Thank you!</div>
                <p className='size-18-28 color-black mb-32'>We've received your event inquiry.<br />
                    A Soothe representative will be in touch with you in 1-2 business days. </p>
                <CTAButton
                    text="Browse services"
                    action={this.continueFlowWithoutRespresentative}
                    additionalClass="resp-w-303"
                    additionalWrapperClass="justify-content-center br-top-none-imp pb-100"
                    relevantId="browseServicesButton"
                />
            </div>
        </div>
        )
    }
    formView() {
        let {
            showForm, needARep, date, first_name, last_name, email, emailIsValid, phone, phoneIsValid, occasion, address, kind, company,
            dateIsInvalid, width, isFormValid, invalidAddress
        } = this.state;
        return (<>
            <div className='mb-16'>

                <div className='size-44-52 medium-font color-black mb-16'>Build your Wellness Event</div>
                <p className='size-18-28 color-black'>Please answer a few questions to get started.</p>
            </div>



            <form className='mt-16' onSubmit={(e) => { e.preventDefault() }} autoComplete='off'>

                {/* Event Type */}
                <div className="mb-24">
                    <div className="mb-16 medium-font color-black size-14-16">Event type*</div>
                    <CenteredGrids additionalClassName="small-centered-grids"
                        grids={[
                            {
                                title: 'Corporate',
                                value: "corporate",
                                action: () => this.setValueByField("kind", "corporate"),
                                selected: kind === "corporate",
                            }, {
                                title: 'Personal',
                                value: "personal",
                                action: () => this.setValueByField("kind", "personal"),
                                selected: kind === "personal",
                            }]} />
                </div>
                {kind === "corporate" ? <div className="mb-24">
                    <div className="mb-10 medium-font color-black size-14-16">Company Name*</div>
                    <input
                        defaultValue={company}
                        required
                        type="text"
                        className="form-control generic-input-style-white"
                        onChange={(e) => this.setValueByField("company", e.target.value)}
                        placeholder='Enter text'
                    />
                </div> : null}
                {/* Occasion */}
                <div className="mb-24">
                    <div className="mb-10 medium-font color-black size-14-16">Occasion*</div>
                    <input
                        defaultValue={occasion}
                        type="text"
                        className="form-control generic-input-style-white"
                        placeholder={kind === "corporate" ? "Ex: Employee Wellness Day" : "Ex: Bachelorette Party"}
                        onChange={(e) => this.setValueByField("occasion", e.target.value)}
                    />
                </div>

                {/* Date */}
                <div className="mb-24">
                    <div className="mb-10 medium-font color-black size-14-16">
                        Date*
                    </div>
                    <Datetime className={`generic-datepicker-style-white ${dateIsInvalid}`}
                        timeFormat={false}
                        dateFormat="MM/DD/YYYY"
                        value={date}
                        onChange={(e) => {
                            this.setState({
                                date: e.format("MM/DD/YYYY"),
                                dateIsInvalid: this.isValidDate(e)
                            }, this.validateForm);
                        }}
                        closeOnSelect
                        inputProps={{
                            placeholder: "Select date"
                        }}
                    // TODO placeholder font color
                    />
                    <div className='invalid-msg size-14-20'>Minimum event lead time is 7 days.</div>
                </div>
                {/* Address */}
                <div className='mb-24'>
                    <label className='address-form-label'>Event add&zwnj;ress*</label>
                    <PlaceAutoCompleteInput
                        uniqueId={"event-one-off"}
                        inputStylingClass={`form-control generic-input-style-white ${invalidAddress ? "invalid" : ""}`}
                        clearBtnStylingClass="address-clear-input"
                        handleAddress={(newAddress) => {
                            const { addressLine1, country, state, zipCode, name, city } = newAddress;
                            this.setValueByField("invalidAddress", !zipCode || !state || !addressLine1);
                            this.setValueByField("address", {
                                address_line_1: addressLine1,
                                country,
                                state,
                                zip_code: zipCode,
                                city,
                                // name
                            });
                        }}
                        value={address?.address_line_1 || ''}
                        placeholder={address?.address_line_1 || 'Enter event add\u200cress'}
                        onClear={() => {
                            this.setValueByField("address", {});
                            this.setValueByField("invalidAddress", false);
                        }}
                        hideIcon
                        inCaseOfError={() => {
                            this.setValueByField("invalidAddress", true);
                        }}
                    />
                    {invalidAddress ? <div className=' size-14-20' style={{ color: "#ff4d4f" }}>Please select a location with a street address.</div> : null}

                </div>

                {/* <div className='mb-24'>
                    <CheckboxOptions optionsArray={[{
                        key: "needARep_key",
                        label: <div className='size-14-20 color-black regular-font'>Check this box if you'd like a Soothe representative to reach out.</div>,
                        value: needARep,
                        checked: needARep,
                    }]}
                        onChange={(key1, checked) => this.setValueByField("needARep", checked)} />
                </div> */}
                {!needARep ? null : <div className='row'>
                    <div className="col-xs-6 mb-24">
                        <div className="mb-10 medium-font color-black size-14-16">First Name*</div>
                        <input
                            defaultValue={first_name}
                            type="text"
                            className="form-control generic-input-style-white"
                            onChange={(e) => this.setValueByField("first_name", e.target.value)}
                        />
                    </div>
                    <div className="col-xs-6 mb-24">
                        <div className="mb-10 medium-font color-black size-14-16">Last Name*</div>
                        <input
                            defaultValue={last_name}
                            type="text"
                            className="form-control generic-input-style-white"
                            onChange={(e) => this.setValueByField("last_name", e.target.value)}
                        />
                    </div>
                    <div className="col-xs-6 mb-24">
                        <div className="mb-10 medium-font color-black size-14-16">Email*</div>
                        <EmailInput className={`form-control generic-input-style-white ${!emailIsValid && email ? 'invalid' : ''}`}
                            email={email}
                            onEmailChange={({ email, isValid }) => this.setState({
                                email,
                                emailIsValid: isValid
                            }, this.validateForm)} />
                    </div>
                    <div className="col-xs-6 mb-24">
                        <div className="mb-10 medium-font color-black size-14-16">Phone Number*</div>
                        <PhoneInput
                            showLable={false}
                            onPhoneNumberChange={({ number, isValidNumber }) => {
                                this.setState({ phone: number, phoneIsValid: isValidNumber }, this.validateForm);
                            }}
                            className={`intl-tel-input generic-input-style-white size-14-20 contentPrimary z-index-0 ${!phoneIsValid && phone ? 'invalid' : ''}`}
                            phone={phone}
                        />
                    </div>
                </div>}

                <div className='continue-btn-container mt-10'>
                    <CTAButton
                        text="Next"
                        disabled={!isFormValid}
                        action={this.validateAndsubmitForm}
                        additionalClass="resp-w-303"
                        additionalWrapperClass={`justify-content-center br-top-none-imp ${width > 800 ? "" : "b-55-imp"}`}
                    />
                </div>
            </form>
        </>
        );
    }
    errorModal() {
        return (
            <ErrorModal
                isOpen={this.state.showErrorModal}
                close={() => { this.setState({ showErrorModal: false, error: null }); }}
            >
                <p>{this.state.error}</p>
            </ErrorModal>
        );
    }

    render() {
        let { showForm, loading, width, kind } = this.state;
        return <>
            <div className={width > 800 ? 'pt-80 pb-80' : 'mb-150'}>
                <div className='row'>
                    {/* {smallScreen ? null : <div className="flex-2 d-sm-none full-w-h bg-img" style={{ backgroundImage: `url(${get(this.props, 'appointment.info_fields.img_url', '')})` }} />} */}
                    <div className='sm-d-none col-sm-5'>
                        <img width="442px" height="661px" className='obj-fit-cover br-rd-8'
                            src={kind === "corporate" ? CorporateSvg : PersonalSvg} alt="side bg"
                        />
                    </div>
                    <div className='col-xs-12 col-sm-7'>
                        {showForm ? this.formView()
                            : this.thankYouView()}
                    </div>
                </div>
                {this.errorModal()}
            </div>

            <LoadingOverlay showLoader={loading} />
        </>
    }

}
const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, {
})(EventForm);