// configureStore.js
import { createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web and AsyncStorage for react-native
import hardSet from 'redux-persist/lib/stateReconciler/hardSet';
import rootReducer from './Reducers';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['purchasedGiftCardAssortment', 'creditCards', 'creditCard', 'booking', 'chats', 'currentChat', 'anonymousCart', 'fieldsHolder', 'abTest', 'appointment', 'rebookOptions'],
  stateReconciler: hardSet,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default () => {
  const store = createStore(persistedReducer);
  const persistor = persistStore(store);

  // persistor.purge()

  return { store, persistor };
};
