/* eslint-disable no-console */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable consistent-return */
/* eslint-disable react/sort-comp */
/* eslint-disable no-undef */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-filename-extension */
import React, { Fragment } from 'react';
import FacebookLogin from 'react-facebook-login';
import {
  get, merge, pick, replace,
} from 'lodash';
import { withLocalize, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Close } from '@material-ui/icons';
import CTAButton from './CTAButton';
import AppleIcon from '../../Assets/Images/Apple_icon.svg';
import FacebookIcon from '../../Assets/Images/Facebook_icon.svg';
import ErrorModal from './ErrorModal';
import { defaultErrorMessage, blankHref } from '../../constants';
import AppleLogin from './AppleLogin';
import { HOST_ROOT } from '../../apiConfig';
import {
  logInComplete, logInStart, setAllUserIds, signUpAccountDetails, signUpComplete, signUpStart,
} from './WebAnalytics';
import PhoneInput from './PhoneInput';
import EmailInput from './EmailInput';
import checkMark from '../../Assets/Images/Check_mark.png';
import { loadClient, setField } from '../../Actions';
import {
  fbSignin, fbSignup, getClient, getEmail, getSessionParams, userSignin, userSignup,
} from './Helpers';

class UnchangedAuthModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      mobileNumber: '',
      signin: props.isSignin,
      isValidNumber: false,
      countryCode: '',
      canEmail: false,
      marketingCallsTexts: false,
      showErrorModal: false,
      error: null,
      facebookAppId: process.env.REACT_APP_FACEBOOK_APP_ID || '679911028787197',
      width: window.innerWidth,
      isValidEmail: false,
      cart_id: get(props, 'cart_id', null),
      emailExists: false,
    };

    this.otherLoginOptions = this.otherLoginOptions.bind(this);
    this.orSeparator = this.orSeparator.bind(this);
    this.loginForm = this.loginForm.bind(this);
    this.signupForm = this.signupForm.bind(this);

    this.signup = this.signup.bind(this);
    this.signin = this.signin.bind(this);
    this.renderSignupOrSignin = this.renderSignupOrSignin.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.facebookSignup = this.facebookSignup.bind(this);
    this.facebookSignin = this.facebookSignin.bind(this);
    this.renderErrorModal = this.renderErrorModal.bind(this);
    this.phoneCommunicationOptin = this.phoneCommunicationOptin.bind(this);
    this.formSwitcher = this.formSwitcher.bind(this);
    this.fetchClientOnLoad = this.fetchClientOnLoad.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.sendPixelData = this.sendPixelData.bind(this);
    this.resetPasswordLink = this.resetPasswordLink.bind(this);
    this.emailCheck = this.emailCheck.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    window.addEventListener('resize', () => {
      this.setState({ width: window.innerWidth });
    });
  }

  nextStep() {
    this.fetchClientOnLoad();
  }

  fetchClientOnLoad() {
    getClient((response) => {
      const { client } = response.data;
      if (!sessionStorage.getItem('userSet')) {
        setAllUserIds(client.user_id, client.email);
      }

      this.props.loadClient({ ...client, loggedIn: true });
      if (this.props.next) {
        this.props.next();
      }

      getSessionParams(client.user_id, (response2) => {
        const { session_id } = response2.data;
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'user_session_info',
          user_id: client.user_id,
          session_id,
        });
      });
    }, (e) => {
      const { response } = e;
      if (response.status === 401) {
        this.props.loadClient({ loggedIn: false });
      }

      if (response.status === 404 && get(response, 'data.errors.0.code', '') === 'client_not_found' && response.data.result === false) {
        window.location.replace(`${HOST_ROOT}${process.env.REACT_APP_RAILS_REDIRECTION_URL}`);
      }
      if (response.status === 200) {
        this.props.loadClient({ loggedIn: false });
      }
    });
  }

  emailCheck() {
    const { isValidEmail, email } = this.state;
    if (isValidEmail) {
      getEmail(email, (resp) => {
        if (get(resp, 'data.admin', false)) {
          this.setState({
            emailExists: false, signin: true, showErrorModal: true, error: "Can't login with this user type",
          });
        } else if (get(resp, 'data.result', false)) {
          this.setState({ emailExists: true, signin: true });
        } else {
          this.setState({ emailExists: false, signin: false });
        }
      }, () => {
        this.setState({ emailExists: false, signin: false });
      });
    }
  }

  formSwitcher() {
    return (
      <div className="size-16-24 contentPrimary mb-32">
        {this.state.signin ? (
          <>
            Don't have an account?
            {' '}
            <a
              className="CactusFlowerPrimary font-weight-bold"
              href={blankHref}
              onClick={(event) => {
                event.preventDefault();
                this.setState({ signin: false });
              }}
            >
              Signup
            </a>
          </>
        ) : (
          <>
            Already have an account?
            {' '}
            <a
              className="CactusFlowerPrimary font-weight-bold"
              href={blankHref}
              onClick={(event) => {
                event.preventDefault();
                this.setState({ signin: true });
              }}
            >
              Login
            </a>
          </>
        )}
      </div>
    );
  }

  handleInputChange({ isValidNumber, number, countryCode }) {
    this.setState({ isValidNumber, mobileNumber: isValidNumber ? `+${replace(number, /\D/g, '')}` : number, countryCode });
  }

  phoneCommunicationOptin() {
    if (this.state.countryCode === 'us') {
      return (
        <div className="form-group mb-24 contentTertiary">
          <div className="row">
            <div className="col-xs-1 col-sm-1">
              <input type="checkbox" checked={this.state.marketingCallsTexts} className="" onChange={() => this.setState({ marketingCallsTexts: !this.state.marketingCallsTexts })} />
            </div>
            <div className="col-xs-10 col-sm-9 optin-text">
              <Translate id="navigation.receivePromotionalCallsAndTexts" />
            </div>
          </div>
        </div>
      );
    }
  }

  marketingEmailOptin() {
    if (this.state.countryCode !== 'us' && this.state.countryCode !== '') {
      return (
        <div className="form-group mb-24 contentTertiary">
          <div className="row">
            <div className="col-xs-1 col-sm-1">
              <input type="checkbox" checked={this.state.canEmail} className="" onChange={() => this.setState({ canEmail: !this.state.canEmail })} />
            </div>
            <div className="col-xs-10 col-sm-9 optin-text">
              I would like to receive promotional emails from Soothe
            </div>
          </div>
        </div>
      );
    }
  }

  sendPixelData(userId, type, userEmail) {
    if (process.env.REACT_APP_PRODUCTION !== 'true') {
      return;
    }
    try {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'client_signup',
        user_id: userId,
        user_account: userEmail,
        type,
      });
    } catch (e) {
      console.log({ e });
    }
  }

  signup(event) {
    if (event && event.preventDefault) {
      event.preventDefault();
    }
    if (!this.state.mobileNumber || !this.state.isValidNumber) {
      this.setState({ showErrorModal: true, error: 'Please provide a valid phone number' });
      return;
    }

    const { firstName } = this.state;
    const { lastName } = this.state;

    const params = merge(
      { mobile_number: this.state.mobileNumber },
      { first_name: firstName, last_name: lastName },
      pick(this.state, ['name', 'email', 'password', 'cart_id']),
    );

    userSignup(
      { 'X-CSRF-Token': get(this.props, 'fieldsHolder.csrfToken', ''), withCredentials: true },
      merge(params, {
        gdpr_marketing_permissions: this.state.canEmail,
        marketing_calls_texts: this.state.marketingCallsTexts,
      }),
      (resp) => {
        const { user } = resp.data;
        this.props.setField('csrfToken', get(resp, 'data.csrfToken', ''));
        const {
          email, id, first_name, last_name, mobile_number,
        } = user || {};
        setAllUserIds('id', email ? email.toString() : '');
        signUpStart();
        signUpComplete(id, 'email', email ? email.toString() : '');
        signUpAccountDetails(email, first_name, last_name, mobile_number);
        this.sendPixelData(id, 'email', email ? email.toString() : '');
        setTimeout(this.nextStep, 2000);
      },
      (error) => {
        const user_already_exists = get(error, 'response.data.errors.user_already_exists', false);
        if (user_already_exists) {
          window.scrollTo(0, 0);
          this.setState({ signin: true });
        } else {
          const message = get(error, 'response.data.errors.email.0', '') || get(error, 'response.data.errors.mobile_number.0', '') || get(error, 'response.data.errors.password.0', '') || get(error, 'response.data.errors.first_name.0', '') || get(error, 'response.data.errors.last_name.0', '') || get(error, 'response.data.errors.0.message', defaultErrorMessage);
          this.setState({ error: message, showErrorModal: true });
        }
      },
    );
  }

  signin(event) {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    const params = pick(this.state, [
      'email', 'password', 'cart_id',
    ]);
    userSignin({ 'X-CSRF-Token': get(this.props, 'fieldsHolder.csrfToken', ''), withCredentials: true }, params, (resp) => {
      const {
        success, result, user, type,
      } = resp.data;

      if (result || type === 'client') {
        const { email, id } = user || {};
        this.props.setField('csrfToken', get(resp, 'data.csrfToken', ''));
        setAllUserIds(id, email ? email.toString() : '');
        logInStart('React App');
        logInComplete('email', id);
        setTimeout(this.nextStep, 2000);
      } else if (success && type === 'therapist') {
        return window.location.replace(`${HOST_ROOT}/therapists/${get(user, 'id', '')}`);
      } else if (success && type === 'b2b_client' && !!get(user, 'b2b_admin_company_id', '')) {
        return window.location.replace(`${HOST_ROOT}/sootheatwork/admin/appointments`);
      } else if (success && result == null) {
        return window.location.replace(`${HOST_ROOT}/clients/${get(user, 'id', '')}`);
      }
    }, (error) => {
      console.log({ error });
      const message = get(error, 'response.data.message') || get(error, 'response.data.errors.0.message', defaultErrorMessage);
      this.setState({ error: message, showErrorModal: true });
    });
  }

  facebookSignup(response) {
    const { name } = response;
    const firstName = name.substr(0, name.indexOf(' '));
    const lastName = name.substr(name.indexOf(' ') + 1);
    const params = {
      first_name: firstName, last_name: lastName, email: response.email, oauth_token: response.accessToken, provider: 'facebook',
    };

    fbSignup(
      merge(params, {
        gdpr_marketing_permissions: this.state.canEmail,
        marketing_calls_texts: this.state.marketingCallsTexts,
      }),
      (resp) => {
        const {
          email, user_id, first_name, last_name, mobile_number,
        } = get(resp, 'data', {});
        signUpStart();
        signUpComplete(user_id, 'Facebook', email);
        signUpAccountDetails(email, first_name, last_name, mobile_number);
        this.sendPixelData(user_id, 'Facebook', email);
        setTimeout(this.nextStep, 2000);
      },
      (error) => {
        const message = get(error, 'response.data.errors.0.message', defaultErrorMessage);
        this.setState({ error: message, showErrorModal: true });
      },
    );
  }

  facebookSignin(response) {
    const { name } = response;
    const firstName = name.substr(0, name.indexOf(' '));
    const lastName = name.substr(name.indexOf(' ') + 1);
    const params = {
      first_name: firstName, last_name: lastName, email: response.email, oauth_token: response.accessToken, provider: 'facebook',
    };

    fbSignin(params, (resp) => {
      logInStart('React App');
      logInComplete('Facebook', get(resp, 'data.user_id', ''));
      setTimeout(this.nextStep, 2000);
    }, (error) => {
      const message = get(error, 'response.data.errors.0.message', defaultErrorMessage);
      this.setState({ error: message, showErrorModal: true });
    });
  }

  renderSignupOrSignin() {
    if (this.state.signin) {
      return this.loginForm();
    }
    return this.signupForm();
  }

  otherLoginOptions() {
    return (
      <div className="grids-section">
        <AppleLogin
          signIn
          redirectURI={this.props.redirectURI}
          backToSelectServiceCallback={this.nextStep}
          inPopup
          hideCopy
          customLogo={AppleIcon}
          wrapperClassName="centered-cell"
          customClassName="p-10"
          iconClassName="height-24"
          sendPixelData={this.sendPixelData}
          addCartId={true}
        />

        <FacebookLogin
          appId={this.state.facebookAppId}
          autoLoad={false}
          fields="name,email"
          scope="public_profile,email"
          callback={this.facebookSignin}
          textButton={(
            <div className="form-group centered-cell">
              <div className="p-10">
                <img src={FacebookIcon} className="height-24" alt="" />
              </div>
            </div>
                  )}
          cssClass="my-facebook-button-class transparent-button w-100-perc pr-0"
          isMobile={false}
        />
      </div>
    );
  }

  orSeparator() {
    return (
      <div className="or-separator mb-24">
        <div className="gray-hr-line flex-1-1-45" />
        <div className="contentTertiary size-14-20 flex-1-1-10 font-weight-bold">OR</div>
        <div className="gray-hr-line flex-1-1-45" />
      </div>
    );
  }

  loginForm() {
    const { cart_id } = this.props;
    const {
      email, emailExists, isValidEmail, password,
    } = this.state;
    return (
      <>
        <div className="size-24-32 contentPrimary font-weight-bold mb-32">
          Login
          {' '}
          {cart_id ? ' or Signup to continue' : ''}
        </div>
        {/* {this.formSwitcher()} */}

        {cart_id ? null : (
          <>
            {this.otherLoginOptions()}
            {this.orSeparator()}
          </>
        )}

        <form onSubmit={this.signin}>
          <div className={`${isValidEmail ? 'success' : ''}`}>
            <label className="contentPrimary size-16-20 mb-8">Email</label>
            <EmailInput
              email={email}
              className="form-control generic-input-style size-16-24 contentPrimary"
              onEmailChange={({ email, isValid }) => {
                this.setState({ email, isValidEmail: isValid });
              }}
            />
            <img className="input-checkmark" src={checkMark} alt="" />
          </div>
          {emailExists ? (
            <>
              <div className={`mt-24 ${password ? 'success' : ''}`}>
                <label className="contentPrimary size-16-20 mb-8">Password</label>
                <input
                  type="password"
                  className="form-control generic-input-style size-16-24 contentPrimary"
                  value={password}
                  onChange={(event) => this.setState({ password: event.target.value })}
                />
                <img className="input-checkmark" src={checkMark} alt="" />
              </div>
              <CTAButton
                text="Login"
                additionalClass="w-100-perc"
                additionalWrapperClass="mt-36 p-0-imp br-top-none-imp"
                type="submit"
                bottomSection={this.resetPasswordLink()}
              />
            </>
          )
            : isValidEmail
              ? (
                <CTAButton
                  text="Continue"
                  additionalClass="w-100-perc"
                  additionalWrapperClass="mt-36 p-0-imp br-top-none-imp"
                  action={this.emailCheck}
                />
              )
              : null}
        </form>
      </>
    );
  }

  signupForm() {
    return (
      <>

        <div className="size-24-32 contentPrimary font-weight-bold mb-24">Signup</div>

        <form onSubmit={this.signup}>
          <div className={`form-group mb-8 ${this.state.isValidEmail ? 'success' : ''}`}>
            <label className="contentPrimary size-16-20 mb-8">Email</label>
            <EmailInput
              email={this.state.email}
              className="form-control generic-input-style size-16-24 contentPrimary"
              onEmailChange={({ email, isValid }) => {
                this.setState({ email, isValidEmail: isValid });
              }}
            />
            <img className="input-checkmark" src={checkMark} alt="" />
          </div>
          <div className="size-14-20 contentTertiary mb-24">
            We'll email you booking confirmations and receipts
          </div>
          {this.formSwitcher()}
          <div className={`form-group mb-24 ${this.state.password ? 'success' : ''}`}>
            <label className="contentPrimary size-16-20 mb-8">Password</label>
            <input
              type="password"
              className="form-control generic-input-style size-16-24 contentPrimary"
              value={this.state.password}
              onChange={(event) => this.setState({ password: event.target.value })}
            />
            <img className="input-checkmark" src={checkMark} alt="" />
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-6 form-group">
              <label className="contentPrimary size-16-20 mb-8">First Name</label>
              <div className={`${this.state.firstName ? 'success' : ''}`}>
                <input
                  type="text"
                  value={this.state.firstName}
                  className="form-control generic-input-style size-16-24 contentPrimary"
                  style={{ marginBottom: '20px' }}
                  onChange={(event) => this.setState({ firstName: event.target.value })}
                  required
                />
                <img className="input-checkmark input-checkmark-editted" src={checkMark} alt="" />
              </div>
            </div>

            <div className="col-xs-12 col-sm-6 form-group ">
              <label className="contentPrimary size-16-20 mb-8">Last Name</label>
              <div className={`${this.state.lastName ? 'success' : ''}`}>
                <input
                  type="text"
                  value={this.state.lastName}
                  className="form-control generic-input-style size-16-24 contentPrimary"
                  style={{ marginBottom: '20px' }}
                  onChange={(event) => this.setState({ lastName: event.target.value })}
                  required
                />
                <img className="input-checkmark input-checkmark-editted" src={checkMark} alt="" />
              </div>
            </div>
          </div>
          <div className="form-group tel-input mb-24 ">
            <label className="contentPrimary size-16-20 mb-8">Phone number</label>
            <div className={`${this.state.isValidNumber ? 'success' : ''}`}>
              <PhoneInput
                showLable={false}
                onPhoneNumberChange={this.handleInputChange}
                className={`intl-tel-input generic-input-style size-16-24 contentPrimary ${this.state.isValidNumber ? 'valid' : 'invalid'}`}
              />
              <img className="input-checkmark input-checkmark-editted" src={checkMark} alt="" />
            </div>
            {this.state.isValidNumber || !this.state.mobileNumber ? null : <div className="small-red-message">This number is invalid.</div>}
            <div className="size-12-18 contentTertiary mt-8">By providing your mobile number to Soothe, you agree that Soothe and therapists may use that number to communicate with you via calls and text messages (including automatically dialed calls and texts) regarding your appointments and account.</div>
          </div>

          {this.phoneCommunicationOptin()}
          {this.marketingEmailOptin()}

          <CTAButton
            text="Agree and Sign Up"
            additionalClass="w-100-perc"
            additionalWrapperClass="p-0-imp br-top-none-imp"
            type="submit"
            bottomSection={(
              <div className="contentPrimary size-12-20 centered mt-24 mb-24">
                <div>
                  By selecting Agree and Sign Up, I agree to Soothe's
                  <a className="BluePrimary underline" href="/legal/terms/b2c/" target="_blank" rel="noopener noreferrer">Terms of Service</a>
                  {' '}
                  and
                  <a className="underline BluePrimary" href="/legal/privacy/" target="_blank" rel="noopener noreferrer">Privacy Policy.</a>
                </div>
              </div>
)}
          />
        </form>
      </>
    );
  }

  resetPasswordLink() {
    return (
      <div className="contentPrimary size-12-20 centered mb-20 mt-20">
        Forgot your password?
        <a
          href="/users/password/new"
          target="_blank"
          rel="noopener noreferrer"
          className="BluePrimary underline"
        >
          Reset password
        </a>
      </div>
    );
  }

  renderErrorModal() {
    return (
      <ErrorModal
        isOpen={this.state.showErrorModal}
        close={() => { this.setState({ showErrorModal: false, error: null }); }}
      >
        <p>{this.state.error}</p>
      </ErrorModal>
    );
  }

  render() {
    return (
      <>
        <div className="full-background">
          <div className="popup-frame-2 login-popup">
            <div className="mb-24">
              <a
                className="cursor-pointer"
                onClick={() => {
                  this.props.closeModal();
                }}
              >
                <Close style={{ color: '#000000', fontSize: '24px' }} />
              </a>
            </div>
            {this.renderSignupOrSignin()}
          </div>
        </div>

        {this.renderErrorModal()}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  client: state.client,
  fieldsHolder: state.fieldsHolder,
});

export default withLocalize(connect(mapStateToProps, { loadClient, setField })(UnchangedAuthModal));
