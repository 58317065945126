/* eslint-disable no-console */
/* eslint-disable react/no-unused-state */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import {
  capitalize, isEmpty, get, find,
} from 'lodash';
import { withLocalize, Translate } from 'react-localize-redux';
import DollarIcon from './Assets/Images/Dollar_icon.png';
import { defaultErrorMessage, pleaseEnterAGiftCodeError } from '../../../constants';
import { setCart } from '../../../Actions';
import { API_ROOT, ACCEPT_LANGUAGE_HEADER } from '../../../apiConfig';
import bookingTranslations from '../../../Translations/bookingFlow.json';
import Modal from '../../Shared/Modal';
import ModalRedesign from '../../Shared/ModalRedesign';
import ErrorModal from '../../Shared/ErrorModal';
import { decode } from '../Shared/encode';
import TextWithIcon from '../../Shared/TextWithIcon';
import '../../BookingFlow/Payment/Assets/Styles/Payments.css';

class PromoCodes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      giftCode: '',
      svsPinCode: '',
      isSvsGiftCode: false,
      showErrorModal: false,
      showSuccessModal: false,
      successMessage: null,
      error: null,
      showGiftCode: false,
    };

    this.setCart = this.setCart.bind(this);
    this.renderGiftCodeForm = this.renderGiftCodeForm.bind(this);
    this.submitGiftCode = this.submitGiftCode.bind(this);
    this.handleGiftCodeChange = this.handleGiftCodeChange.bind(this);
    this.errorModal = this.errorModal.bind(this);
    this.successModal = this.successModal.bind(this);
    this.getText = this.getText.bind(this);

    this.props.addTranslation(bookingTranslations);
  }

  componentDidMount() {
    this.applyB2bDiscount();
  }

  setCart(value) {
    this.props.setCart({
      ...this.props.booking,
      ...value,
    });
  }

  applyB2bDiscount() {
    const b2bDiscountCode = get(this.props, 'booking.b2bDiscountCode', '');

    if (b2bDiscountCode) {
      axios.post(
        `${API_ROOT}/v7/carts/${get(this.props, 'booking.cart.id', '')}/apply_gift_code`,
        { cart: { gift_code: b2bDiscountCode } },
        { withCredentials: true },
        ACCEPT_LANGUAGE_HEADER,
      ).then((resp) => {
        const { result } = resp.data;
        if (result) {
          this.props.assignToCart({ cart: decode(resp.data.cart) });
        } else {
          console.log(get(resp, 'data.errors.0.message', defaultErrorMessage));
        }
      }).catch((error) => {
        console.log(get(error, 'response.data.errors.0.message', defaultErrorMessage));
      });
    }
  }

  submitGiftCode(event) {
    event.preventDefault();
    const { giftCode, svsPinCode } = this.state;

    if (giftCode) {
      const giftCardData = {
        gift_code: giftCode,
      };

      if (svsPinCode) {
        giftCardData.svs_pin = svsPinCode;
      }

      axios.post(
        `${API_ROOT}/v7/carts/${this.props.booking.cart.id}/apply_gift_code`,
        { cart: giftCardData },
        { withCredentials: true },
        ACCEPT_LANGUAGE_HEADER,
      ).then((resp) => {
        const { result, svs_gift_card_success_message } = resp.data;

        if (result) {
          this.props.assignToCart({ cart: decode(resp.data.cart) });
          this.setState({
            showGiftCode: false,
            giftCode: '',
            showSuccessModal: Boolean(svs_gift_card_success_message),
            successMessage: svs_gift_card_success_message,
          });
        } else {
          this.setState({ showErrorModal: true, error: get(resp, 'data.errors.0.message', defaultErrorMessage) });
        }
      }).catch((error) => {
        this.setState({ showErrorModal: true, error: get(error, 'response.data.errors.0.message', defaultErrorMessage) });
      });
    } else {
      this.setState({ showErrorModal: true, error: pleaseEnterAGiftCodeError });
    }
  }

  handleGiftCodeChange(event) {
    event.preventDefault();
    this.setState({
      giftCode: event.target.value,
    });
  }

  errorModal() {
    return (
      <ErrorModal
        isOpen={this.state.showErrorModal}
        close={() => { this.setState({ showErrorModal: false, error: null }); }}
      >
        <p>{this.state.error}</p>
      </ErrorModal>
    );
  }

  successModal() {
    return (
      <Translate>
        {({ translate }) => (
          <Modal title={capitalize(translate('global.confirmation'))} isOpen={this.state.showSuccessModal} close={() => { this.setState({ showSuccessModal: false, successMessage: null }); }} showBtn btnCopy="Close">
            <p>{this.state.successMessage}</p>
          </Modal>
        )}
      </Translate>
    );
  }

  renderGiftCodeForm() {
    if (this.state.showGiftCode) {
      return (
        <ModalRedesign
          title="Promo code"
          relevantId="promoCodePopup"
          isOpen={this.state.showGiftCode}
          close={() => {
            this.setState({ showGiftCode: false, giftCode: '' });
          }}
          apply={this.submitGiftCode}
          relevantBtnId="promoCodeSubmitBtn"
        >
          <div className="gift-code-container">
            <input className="form-control gift-code-input gray-input-style" value={this.state.giftCode || ''} placeholder="Enter promo code " onChange={this.handleGiftCodeChange} id="promoCodeInput" />
          </div>
        </ModalRedesign>
      );
    }
    return null;
  }

  getText() {
    const discountCredits = find(get(this.props.booking, 'cart.billing.top_billing_line_items', []), (el) => (el.title === 'Credits'));
    if (!isEmpty(discountCredits)) {
      return (<div>Credits in order summary</div>);
    }
    return (<div>none</div>);
  }

  render() {
    return (
      <>
        <div
          className="clickable"
          id="promoCode"
          onClick={(event) => { event.preventDefault(); this.setState({ showGiftCode: true }); }}
        >
          <TextWithIcon
            icon={DollarIcon}
            title="Promo codes"
            displayArrow
          />
        </div>

        {this.renderGiftCodeForm()}
        {this.errorModal()}
        {this.successModal()}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  booking: state.booking,
  addresses: state.addresses,
  client: state.client,
});

PromoCodes.propTypes = {
  booking: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
};

export default withLocalize(connect(mapStateToProps, { setCart })(PromoCodes));
