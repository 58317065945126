/* eslint-disable camelcase */
/* eslint-disable max-len */
export const MAIN_EVENT = 'MAIN_EVENT';

export function setEvent({
    event_type, occasion, date, start_time, end_time, duration, prosNeeded, attendees, address,
    request_representative, first_name, last_name, email, phone,
    product, session_length, pro_gender, cost_per_hour, subtotal, discount, discount_code, tip, credit_card,
    cart, user_kind, guest_id
}) {
    return {
        type: MAIN_EVENT,
        payload: {
            event_type, occasion, date, start_time, end_time, duration, prosNeeded, attendees, address,
            cart, user_kind, guest_id,
            request_representative,
            personalInfo: {
                first_name, last_name, email, phone
            },
            service: {
                product, session_length, pro_gender
            },
            billing: {
                cost_per_hour, subtotal, discount, discount_code, tip, credit_card
            }
        },
    };
}