/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import OrderSummary from '../CheckoutStep/OrderSummary';
import AppointmentDate from '../ReviewStep/AppointmentDate';
import AppointmentAddress from '../ReviewStep/AppointmentAddress';

class EventDetails extends React.Component {
  constructor(props) {
    super(props);
  }
  // TODO maybe compare new screen to old screen in case of any missed important information
  render() {
    return (
      <div className='row'>
        <div className='col-xs-12 col-sm-6'>
          <div className="background-primary border-radius-16 mb-24">
            <div className='plr-16'>
              <OrderSummary />
            </div>
          </div>
        </div>

        <div className='col-xs-12 col-sm-6'>
          <div className="background-primary border-radius-16 mb-24">
            <div className='contentPrimary size-24-32 medium-font p-24-16-0'>Date</div>
            <AppointmentDate />
          </div>

          <div className="background-primary border-radius-16 mb-24">
            <div className='contentPrimary size-24-32 medium-font p-24-16-0'>Location</div>
            <AppointmentAddress showFinishAddress={() => {
              // TODO revisit and hide this in case of event type
            }}
            />
          </div>
        </div>

      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  booking: state.booking,
  addresses: state.addresses,
  client: state.client,
});

EventDetails.propTypes = {
  booking: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
};

export default withLocalize(connect(mapStateToProps)(EventDetails));
