/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/sort-comp */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import { capitalize, get } from 'lodash';
import { withLocalize, Translate } from 'react-localize-redux';
import { defaultErrorMessage, isEventBooking, pleaseEnterAGiftCodeError } from '../../../constants';
import { setCart } from '../../../Actions';
import { API_ROOT, ACCEPT_LANGUAGE_HEADER } from '../../../apiConfig';
import bookingTranslations from '../../../Translations/bookingFlow.json';
import Modal from '../../Shared/Modal';
import ModalRedesign from '../../Shared/ModalRedesign';
import ErrorModal from '../../Shared/ErrorModal';
import { decode } from '../Shared/encode';
import '../../BookingFlow/Payment/Assets/Styles/Payments.css';
import TextWithIcon from '../../Shared/TextWithIcon';
import GiftIcon from './Assets/Images/Gift_icon.png';

const svsGiftCardRegex = /^\d{19}$/;
class GiftCards extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      svsPinCode: '',
      giftCode: '',
      isSvsGiftCode: false,
      showGCModal: false,
      showErrorModal: false,
      showSuccessModal: false,
      successMessage: null,
      error: null,
    };

    this.setCart = this.setCart.bind(this);
    this.handleSvsPinChange = this.handleSvsPinChange.bind(this);
    this.renderGCModal = this.renderGCModal.bind(this);
    this.errorModal = this.errorModal.bind(this);
    this.successModal = this.successModal.bind(this);
    this.renderGiftCodeForm = this.renderGiftCodeForm.bind(this);
    this.submitGiftCode = this.submitGiftCode.bind(this);
    this.handleGiftCodeChange = this.handleGiftCodeChange.bind(this);

    this.props.addTranslation(bookingTranslations);
  }

  handleGiftCodeChange(event) {
    event.preventDefault();
    this.setState({
      giftCode: event.target.value,
      isSvsGiftCode: svsGiftCardRegex.test(event.target.value || event.target.value.toString()),
    });
  }

  submitGiftCode(event) {
    if (event) {
      event.preventDefault();
    }
    const { giftCode, svsPinCode } = this.state;

    if (giftCode) {
      const giftCardData = {
        gift_code: giftCode,
      };

      if (svsPinCode) {
        giftCardData.svs_pin = svsPinCode;
      }

      axios.post(
        `${API_ROOT}/v7/carts/${this.props.booking.cart.id}/apply_gift_code`,
        { cart: giftCardData },
        { withCredentials: true },
        ACCEPT_LANGUAGE_HEADER,
      ).then((resp) => {
        const { result, svs_gift_card_success_message } = resp.data;

        if (result) {
          this.props.assignToCart({ cart: decode(resp.data.cart) });

          this.setState({
            showGCModal: false,
            giftCode: '',
            svsPinCode: '',
            isSvsGiftCode: false,
            showSuccessModal: Boolean(svs_gift_card_success_message),
            successMessage: svs_gift_card_success_message,
          });
        } else {
          this.setState({ showErrorModal: true, error: get(resp, 'data.errors.0.message', defaultErrorMessage) });
        }
      }).catch((error) => {
        this.setState({ showErrorModal: true, error: get(error, 'response.data.errors.0.message', defaultErrorMessage) || defaultErrorMessage });
      });
    } else {
      this.setState({ showErrorModal: true, error: pleaseEnterAGiftCodeError });
    }
  }

  setCart(value) {
    this.props.setCart({
      ...this.props.booking,
      ...value,
    });
  }

  handleSvsPinChange(event) {
    event.preventDefault();
    this.setState({
      svsPinCode: event.target.value,
    });
  }

  renderGiftCodeForm() {
    return (
      <form onSubmit={this.submitGiftCode}>
        <input
          className="form-control gift-code-input gray-input-style"
          value={this.state.giftCode || ''}
          placeholder="Enter gift code "
          onChange={this.handleGiftCodeChange}
          id="giftCardInput"
        />
        {this.state.isSvsGiftCode
          && (
            <input
              className="form-control gift-code-input gray-input-style"
              value={this.state.svsPinCode || ''}
              placeholder="Pin Code"
              onChange={this.handleSvsPinChange}
            />
          )}
      </form>
    );
  }

  renderGCModal() {
    if (this.state.showGCModal) {
      return (
        <Translate>
          {({ translate }) => (
            <div className="summary-wrapper">
              <ModalRedesign
                title={translate('bookingFlow.spafinderModalTitle')}
                relevantId="giftCardPopup"
                isOpen={this.state.showGCModal}
                close={() => { this.setState({ showGCModal: false }); }}
                apply={this.submitGiftCode}
                relevantBtnId="giftCardSubmitBtn"
              >
                <div className="spafinder-title">
                  <div className="wrapSpafinderLegacyForm">
                    {this.renderGiftCodeForm()}
                  </div>
                </div>
              </ModalRedesign>
            </div>
          )}
        </Translate>
      );
    }
    return null;
  }

  errorModal() {
    return (
      <ErrorModal
        isOpen={this.state.showErrorModal}
        close={() => { this.setState({ showErrorModal: false, error: null }); }}
      >
        <p>{this.state.error}</p>
      </ErrorModal>
    );
  }

  successModal() {
    return (
      <Translate>
        {({ translate }) => (
          <Modal title={capitalize(translate('global.confirmation'))} isOpen={this.state.showSuccessModal} close={() => { this.setState({ showSuccessModal: false, successMessage: null }); }} showBtn btnCopy="Close">
            <p>{this.state.successMessage}</p>
          </Modal>
        )}
      </Translate>
    );
  }

  render() {
    return (
      <>
        <div
          className="clickable"
          id="giftCard"
          onClick={() => { this.setState({ showGCModal: true }); }}
        >
          <TextWithIcon
            icon={GiftIcon}
            title="Gift cards"
            displayBorder={!isEventBooking()}
            displayArrow
          />
        </div>

        {this.renderGCModal()}
        {this.errorModal()}
        {this.successModal()}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  booking: state.booking,
  addresses: state.addresses,
  client: state.client,
});

GiftCards.propTypes = {
  booking: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
};

export default withLocalize(connect(mapStateToProps, { setCart })(GiftCards));
