/* eslint-disable no-shadow */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/sort-comp */
/* eslint-disable no-undef */
import React from 'react';
import { connect } from 'react-redux';
import '../../Assets/Styles/index.css';
import moment from 'moment';
import { get, size, split } from 'lodash';
import { Close } from '@material-ui/icons';
import LatestModalDesign from '../../../Shared/LatestModalDesign';
import { rescheduleAppointment } from '../../Shared/helpers';
import DatetimeWrapper from '../../../Shared/DatetimeWrapper';
import SelectTimeWrapper from '../../../Shared/SelectTimeWrapper';
import { decode } from '../../Shared/encode';
import { setAppointment } from '../../../../Actions/AppointmentManagementAction';
import { momentCap } from '../../../BookingFlowRedesign/Shared/constants';
import ConfirmChanges from './ConfirmChanges';
import ErrorModalRedesign from '../../../Shared/ErrorModalRedesign';
import { NOT_BEFORE_H, defaultErrorMessage } from '../../../../constants';
import { seRescheduleComplete } from '../../../Shared/WebAnalytics';
import SuccesModal from '../SuccessModal';

const yesterday = moment(new Date()).subtract(1, 'day');
const roundedUp = Math.ceil(moment(new Date()).minute() / 15) * 15;
class AnotherTimeModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sessionDate: '',
      sessionTime: '',
      todayMinTime: moment(new Date()).add(NOT_BEFORE_H, 'hours').minutes(roundedUp).seconds(0),
      todayMaxTime: moment(new Date()).set({
        hour: 22, minute: 0, second: 0, millisecond: 0,
      }),
      selection: null,
      mainModalOpen: get(props, 'show', false),
      showConfirmModal: false,
      showErrorModal: false,
      error: '',
      showSuccessModal: false,
    };
    this.submitReschedule = this.submitReschedule.bind(this);
    this.isValidDate = this.isValidDate.bind(this);
    this.onDayChange = this.onDayChange.bind(this);
    this.dateValue = this.dateValue.bind(this);
    this.timeValue = this.timeValue.bind(this);
    this.dayOf = this.dayOf.bind(this);
    this.triggerSuccessfulReschedule = this.triggerSuccessfulReschedule.bind(this);
    this.refreshValues = this.refreshValues.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.refreshValues();
  }

  componentDidUpdate(prevProps) {
    const prevStt = get(prevProps, 'show', false);
    const nextStt = get(this.props, 'show', false);
    if (nextStt && prevStt !== nextStt) {
      this.refreshValues();
    }
  }

  refreshValues() {
    const sessionDate = this.dateValue();
    const sessionTime = this.timeValue(sessionDate);
    this.setState({
      sessionTime,
    }, () => {
      this.setState({
        todayMinTime: this.dayOf() ? moment(new Date()).add(NOT_BEFORE_H, 'hours').minutes(roundedUp).seconds(0) : moment(this.state.sessionDate).set({
          hour: 8, minute: 0, second: 0, millisecond: 0,
        }),
        todayMaxTime: moment(this.state.sessionDate).set({
          hour: 22, minute: 0, second: 0, millisecond: 0,
        }),
      });
    });
  }

  dayOf() {
    const dateTime = this.state.sessionDate;
    return moment(new Date()).isSame(dateTime, 'day');
  }

  dateValue() {
    const dateUtc = get(this.props, 'appointment.date.utc', '');
    if (dateUtc) {
      const dateTime = moment(dateUtc);
      if (this.isValidDate(dateTime)) {
        return dateTime;
      }
    }
    return momentCap(new Date());
  }

  timeValue(sessionDate) {
    const timeUtc = get(this.props, 'appointment.time.utc', '');
    const timeD = get(this.props, 'appointment.time.display', '');
    if (timeUtc) {
      const hour24 = moment(`${sessionDate.format('YYYY-MM-DD')} ${timeD}`).format('HH:mm');
      let sessionDatetime = moment(`${sessionDate.format('YYYY-MM-DD')} ${timeD}`).add(NOT_BEFORE_H, 'h');
      const todaysCap = momentCap(sessionDate.toDate());
      const parts = split(hour24, ':', 2);
      if (Number(get(parts, '0', '')) > 20 || (Number(get(parts, '0', '')) === 20 && Number(get(parts, '1', '')) > 0)) {
        sessionDatetime = momentCap(moment(`${sessionDate.format('YYYY-MM-DD')}`).add(1, 'd').format());
      }
      // if the time is not avail for date then set to the min time for date
      if (todaysCap >= sessionDatetime) {
        this.setState({ sessionDate: sessionDate.format('YYYY-MM-DD') });
        return todaysCap.format('h:mm A');
      }
      this.setState({ sessionDate: sessionDatetime.format('YYYY-MM-DD') });
      return sessionDatetime.format('h:mm A');
    }
    this.setState({ sessionDate: momentCap(new Date()).format('YYYY-MM-DD') });
    return momentCap(new Date()).format('h:mm A');
  }

  isValidDate(currentDate) {
    const { todayMinTime } = this.state;
    const { todayMaxTime } = this.state;
    const now = moment(new Date());

    if (currentDate.isSame(now, 'day')) {
      if (todayMaxTime.isBefore(todayMinTime)) {
        // There is not available slots today
        return false;
      }

      return now.isSameOrBefore(todayMaxTime) && currentDate.isSameOrBefore(momentCap(new Date()));
    }

    return currentDate.isAfter(yesterday);
  }

  onDayChange(day, dayString) {
    const sessionTime = moment(this.state.sessionTime, 'h:mm A');
    const sessionDatetime = sessionTime.month(day.month()).date(day.date()).year(day.year());
    const todaysCap = momentCap(day.toDate());

    // if the time is not avail for date then set to the min time for date
    if (todaysCap > sessionDatetime) {
      this.setState({ sessionDate: dayString, sessionTime: todaysCap.format('h:mm A') }, () => {
        this.setState({
          todayMinTime: this.dayOf() ? moment(new Date()).add(NOT_BEFORE_H, 'hours').minutes(roundedUp).seconds(0) : moment(this.state.sessionDate).set({
            hour: 8, minute: 0, second: 0, millisecond: 0,
          }),
          todayMaxTime: moment(this.state.sessionDate).set({
            hour: 22, minute: 0, second: 0, millisecond: 0,
          }),
        });
      });
    } else {
      this.setState({ sessionDate: dayString }, () => {
        this.setState({
          todayMinTime: this.dayOf() ? moment(new Date()).add(NOT_BEFORE_H, 'hours').minutes(roundedUp).seconds(0) : moment(this.state.sessionDate).set({
            hour: 8, minute: 0, second: 0, millisecond: 0,
          }),
          todayMaxTime: moment(this.state.sessionDate).set({
            hour: 22, minute: 0, second: 0, millisecond: 0,
          }),
        });
      });
    }
  }

  triggerSuccessfulReschedule(oldApt, changedCC = false) {
    const apt = get(this.props, 'appointment', {});
    seRescheduleComplete({
      appointment_id: get(oldApt, 'cartProducts.0.id', ''),
      state: get(oldApt, 'info_fields.tracker.state', ''),
      client_id: get(this.props, 'client.user_id', ''),
      service_category: get(apt, 'cartProducts.0.product.title', '').toLowerCase(),
      service_modality: get(apt, 'cartProducts.0.cart_product_main_option.title', '').toLowerCase(),
      same_day: moment(this.state.sessionDate).isSame(get(oldApt, 'date.utc'), 'day'),
      ic_utilized: get(apt, 'info_fields.tracker.pending_response_details.reschedule.instant_booking', false),
      late_reschedule: Boolean(get(oldApt, 'fees.reschedule.amount', 0)),
      cart_product_count: size(get(oldApt, 'cartProducts', [])),
      changed_payment_method: changedCC,
      different_pro: true,
      price_adjustment: 0,
      number_of_requested_pros: size(get(this.props, 'appointment.therapist_preferences', [])),
    });
  }

  submitReschedule(_message, changedCC = false) {
    const { sessionDate, sessionTime } = this.state;
    const oldApt = get(this.props, 'appointment', {});
    if (sessionDate && sessionTime) {
      rescheduleAppointment(get(this.props, 'appointment.id', ''), {
        credit_card_id: get(this.props, 'appointment.billing.credit_card.id', ''),
        late_reschedule_fee: get(this.props, 'appointment.fees.reschedule.amount', 0),
        rebook: 'same_therapist',
        session_date: moment(sessionDate).format('YYYY-MM-DD'),
        session_time: moment(`${sessionDate} ${sessionTime}`, 'YYYY-MM-DD h:mm A').format('HH:mm'),
      }, (response) => {
        this.props.setAppointment(decode(response.data.cart));
        this.triggerSuccessfulReschedule(oldApt, changedCC);
        this.setState({ showSuccessModal: true, mainModalOpen: false, showConfirmModal: false });
      }, (err) => {
        this.setState({
          showErrorModal: true, error: get(err, 'response.data.errors.0.message', defaultErrorMessage), mainModalOpen: false, showConfirmModal: false,
        });
      });
    }
  }

  render() {
    const {
      sessionTime, sessionDate, todayMaxTime, todayMinTime,
    } = this.state;
    if (sessionDate && sessionTime) {
      return (
        <>
          <LatestModalDesign
            isOpen={this.state.mainModalOpen}
            title="Try another time"
            customIcon={<Close />}
            applyBtnCopy="Confirm changes"
            contentExtraClass="max-width-480"
            fullWidthFooter
            hideCancel
            apply={() => {
              this.setState({ showConfirmModal: true, mainModalOpen: false });
            }}
            back={this.props.hideModal}
          >
            <div className="bg-secondary p-16 br-rd-8">
              <DatetimeWrapper
                open
                input={false}
                value={sessionDate}
                timeFormat={false}
                onChange={this.onDayChange}
                isValidDate={this.isValidDate}
              />
              <div className="medium-font size-18-24 color-black mb-8 size-16-20">Time</div>
              <div className="gray-select-style">
                <SelectTimeWrapper
                  date={sessionDate}
                  time={sessionTime}
                  minTime={todayMinTime.format('h:mm A')}
                  maxTime={todayMaxTime.format('h:mm A')}
                  onChange={(sessionTime) => { this.setState({ sessionTime }); }}
                />
              </div>
            </div>
          </LatestModalDesign>
          <ConfirmChanges
            show={this.state.showConfirmModal}
            comingFromCalendar
            selection={{ datetime: moment(`${sessionDate} ${sessionTime}`, 'YYYY-MM-DD h:mm A').format(), time: this.state.sessionTime }}
            ctaAction={this.submitReschedule}
            hideModal={() => {
              this.setState({ showConfirmModal: false, mainModalOpen: true });
            }}
          />
          <SuccesModal
            isOpen={this.state.showSuccessModal}
            title="Appointment successfully rescheduled"
            subtitle=" "
            ctaAction={() => {
              this.setState({
                mainModalOpen: false,
                showConfirmModal: false,
                showSuccessModal: false,
              });
              this.props.hideModal();
            }}
          />
          <ErrorModalRedesign
            isOpen={this.state.showErrorModal}
            title={this.state.error}
            subtitle={this.state.error ? ' ' : ''}
            clickAction={() => {
              this.props.hideModal();
            }}
          />
        </>
      );
    }
    return null;
  }
}
const mapStateToProps = (state) => ({
  client: state.client,
  appointment: state.appointment,
  upcomingAppointmentId: state.upcomingAppointmentId,
  rebookOptions: state.rebookOptions,
});
export default connect(mapStateToProps, { setAppointment })(AnotherTimeModal);
