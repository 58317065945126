/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-undef */
import React from 'react';
import { connect } from 'react-redux';
import {
  get, map, remove, size, split,
  take,
} from 'lodash';
import { Delete } from '@material-ui/icons';
import { Box, SwipeableDrawer, styled } from '@material-ui/core';
import {
  setCart, setAddresses, setProducts, setAnonymousCart,
} from '../../../Actions';
import { decode } from '../Shared/encode';
import { defaultErrorMessage, isEventBooking } from '../../../constants';
import { deleteCartProduct } from '../Shared/helpers';
import { seDeleteFromCart } from '../../Shared/WebAnalytics';

const drawerBleeding = 56;

const Puller = styled(Box)(() => ({
  width: 48,
  height: 4,
  backgroundColor: '#E2E2E2',
  borderRadius: 3,
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 24px)',
}));
class CartDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
    };
    this.relevantItemsList = this.relevantItemsList.bind(this);
    this.mobileCart = this.mobileCart.bind(this);
    this.desktopCart = this.desktopCart.bind(this);
    this.isFullCart = this.isFullCart.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', () => this.setState({ width: window.innerWidth }));
  }

  isFullCart() {
    const loggedIn = get(this.props, 'client.loggedIn', false);
    const anoncrtPrds = get(this.props, 'anonymousCart.cartProducts', []);
    const crtPrds = get(this.props, 'booking.cart.cartProducts', []);
    const maxItems = isEventBooking() ? 1 : 2;
    if (loggedIn) {
      return size(crtPrds) >= maxItems;
    }
    return size(anoncrtPrds) >= maxItems;
  }

  relevantItemsList() {
    const cartProducts = get(this.props, 'booking.cart.cartProducts', []);
    const anonCart = get(this.props, 'anonymousCart', {});
    const anonCPs = get(anonCart, 'cartProducts', []);
    const loggedIn = get(this.props, 'client.loggedIn', false);
    const cartId = get(this.props, 'booking.cart.id', '');
    let listTobeUsed;
    const market = get(this.props, 'anonymousCart.address.market', '') || get(this.props, 'booking.cart.address.market', '');
    const is_event = isEventBooking();
    // TODO revisit title and price
    // TODO display only first cart product
    // TODO display only one remove icon 
    // TODO go back to event form when removing main cart product 
    // TODO maybe prefill event form with existing options?
    // TODO double check responsive code
    if (loggedIn) {
      listTobeUsed = is_event ? take(cartProducts) : cartProducts;
      return (
        <div className="">
          {map(listTobeUsed, (cp, it) => {
            const splittedSub = split(get(cp, 'subtitle', ''), ' with ', 2);
            return (
              <div key={`cart-item-${it}`} className="notLast-br-b-opaque d-flex-only align-items-center justify-content-spaced">
                <div>
                  <div className="size-18-24 font-weight-bold contentPrimary mt-16 mb-4">
                    {get(splittedSub, 0, '')}
                    {get(splittedSub, 1, '') ? (
                      <>
                        <br />
                        with
                        {' '}
                        {get(splittedSub, 1, '')}
                      </>
                    ) : null}
                  </div>
                  <div className="size-16-24 contentSecondary mb-16">{get(cp, 'cart_product_main_option.price', '')}</div>
                </div>
                <div className="pr-24">
                  <button
                    type="button"
                    className="btn edit-icon"
                    onClick={() => {
                      seDeleteFromCart(size(cartProducts), 'booking_23_variant', 'treatments_menu', market);
                      deleteCartProduct(cartId, cp.id, get(this.props, 'fieldsHolder.csrfToken', ''), (response) => {
                        this.props.assignToCart({ cart: decode(response.data.cart) });
                      }, (err) => this.props.displayError(get(err, 'response.data.errors.0.message', defaultErrorMessage)));
                    }}
                  >
                    <Delete />
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      );
    }
    listTobeUsed = is_event ? take(anonCPs) : anonCPs;
    return (
      <div className="">
        {map(listTobeUsed, (cp, it) => (
          <div key={`cart-item-${it}`} className="notLast-br-b-opaque d-flex-only align-items-center justify-content-spaced">
            <div>
              <div className="size-18-24 font-weight-bold contentPrimary mt-16 mb-4">
                {get(cp, 'title', '')}
                <br />
                {' '}
                {get(cp, 'subtitle', '')}
              </div>
              <div className="size-16-24 contentSecondary mb-16">{get(cp, 'price', '')}</div>
            </div>
            <div className="pr-24">
              <button
                type="button"
                className="btn edit-icon"
                onClick={() => {
                  seDeleteFromCart(size(anonCPs), 'booking_23_variant', 'treatments_menu', market);
                  remove(anonCPs, (_el, j) => (j === it));
                  this.props.setAnonymousCart({ ...anonCart, cartProducts: anonCPs });
                }}
              >
                <Delete />
              </button>
            </div>
          </div>
        ))}
      </div>
    );
  }

  mobileCart() {
    const { open } = this.props;
    const { emCrt } = this.props;
    const rebook = get(this.props, 'booking.cart.rebook', false);

    return (
      <SwipeableDrawer
        PaperProps={{ style: { borderRadius: '16px 16px 0px 0px' } }}
        anchor="bottom"
        open={open}
        onClose={this.props.close}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen
        onOpen={() => { }}
      >
        {' '}
        <Puller />
        <div className="mt-8 p-12-16 centered br-b-opaque-2">
          <div className="size-18-24 font-weight-bold contentPrimary mb-4">Cart</div>
          {rebook || isEventBooking() ? null : <div className="size-12-20 contentSecondary">Add up to two treatments from the same category.</div>}
        </div>
        {emCrt ? <div className="size-16-24 contentSecondary centered-188 mt-60">Empty</div>
          : (
            <>
              <div className="sm-p-24-16">
                {this.relevantItemsList()}
              </div>
              <div className="p-16">
                {this.isFullCart() ? null : (
                  <button
                    type="button"
                    className="btn btn-accent-light w-100-perc p-16-20-imp mb-8 size-18-24-imp"
                    onClick={this.props.close}
                  >
                    + Add Another
                  </button>
                )}
                <button
                  type="button"
                  className="btn cta-primary"
                  onClick={this.props.next}
                  id="chooseDate"
                >
                  {isEventBooking() ? "Next" : "Choose date"}
                </button>
              </div>
            </>
          )}
      </SwipeableDrawer>
    );
  }

  desktopCart() {
    const rebook = get(this.props, 'booking.cart.rebook', false);
    return (
      <div className="top-sticky-80 height-100-perc">
        <div className="p-16-0-24-24">
          <div className="size-32-40 font-weight-bold contentPrimary mb-20">Cart</div>
          {rebook || isEventBooking() ? null : <div className="size-18-28 contentPrimary mb-40">Add up to two treatments from the same category.</div>}
          {this.relevantItemsList()}
        </div>
        <div className="txt-center w-25-minus-48 fxd-btm r-24">
          <button
            type="button"
            className="btn cta-primary w-100-perc"
            id="chooseDate"
            onClick={this.props.next}
          >
            {isEventBooking() ? "Next" : this.state.width <= 1000 ? 'Choose date' : 'Next: Choose date'}
          </button>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.width <= 800) {
      return this.mobileCart();
    }
    return this.desktopCart();
  }
}

const mapStateToProps = (state) => ({
  addresses: state.addresses,
  booking: state.booking,
  products: Object.values(get(state, 'productsReducer.products', {})),
  client: state.client,
  bookingFlow: state.bookingFlow,
  anonymousCart: state.anonymousCart,
  fieldsHolder: state.fieldsHolder,
});

export default connect(mapStateToProps, {
  setCart, setAddresses, setProducts, setAnonymousCart,
})(CartDrawer);
