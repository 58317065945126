import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import { get } from 'lodash';

class AdditionalDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return <div className="pb-24">
            <div className="size-16-20 font-weight-bold contentPrimary mb-8 mt-16">
                Note to Provider <span className="contentTertiary">(Optional)</span>
            </div>
            <div>
                <textarea
                    className="form-control gray-textarea"
                    rows={3}
                    placeholder="Enter note"
                    defaultValue={get(this.props.booking, 'cart.event.provider_notes', '')}
                    onChange={(event) => this.props.adjustInParent(event.target.value || '')}
                />
            </div>
            <div className="size-12-20 color-light-gray mt-16">Please list any allergies or preferences here.</div>
        </div>
    }

}
const mapStateToProps = (state) => ({
    booking: state.booking,
    addresses: state.addresses,
    client: state.client,
});

AdditionalDetails.propTypes = {
    booking: PropTypes.object.isRequired,
    client: PropTypes.object.isRequired,
};

export default withLocalize(connect(mapStateToProps)(AdditionalDetails));