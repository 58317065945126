import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setCart } from '../../../Actions';
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { get, isEmpty, map, find, includes, findIndex, size, camelCase } from 'lodash';
import MediaCard from '../../Shared/MediaCard';
import circularCheck from '../../../Assets/Images/Circular_white_check.png';
import { hasUuid } from '../../../constants';
class OptionsCarousel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            allowedValues: []
        }
        this.getSlides = this.getSlides.bind(this);
        this.getCardView = this.getCardView.bind(this);
        this.getProductImage = this.getProductImage.bind(this);
        this.getInitialSlide = this.getInitialSlide.bind(this);
        this.optionPrice = this.optionPrice.bind(this);
    }
    componentDidMount() {
        let pOptions = get(this.props, "product.product_options", []),
            prd = find(pOptions, (p) => (p.display_type === "detailed_radio")),
            options = get(prd, "options", []);
        if (!isEmpty(options)) {
            let allowedValues = [];
            allowedValues = map(options, (o) => (o.id));
            this.setState({ allowedValues })
        }
    }
    getProductImage(prd) {
        let images = get(prd, "learn_more.images", []);
        if (!isEmpty(images)) {
            return get(find(images, (ig) => (ig.kind === 'hero')), 'web_url', '');
        }
    }
    optionPrice(el) {
        let isSoothePassSubscribed = get(this.props, "booking.cart.info_fields.soothe_pass.subscribed", false),
            isSoothePassAvailable = get(this.props, "booking.cart.info_fields.soothe_pass.available", false) && !hasUuid(),
            member_price = get(el, "member_price", ""),
            price = get(el, "price", "");
        if (!isSoothePassAvailable && (!member_price || !isSoothePassSubscribed) && price) {
            return <div className='size-14-16'>{price}</div>
        }
        if (member_price) {
            let mmbrObj = <div className='size-12-20 mb-4'><span className='size-14-16'>{member_price}</span> Member</div>,
                nonMmbrObj = <div className='size-12-20'><span className='size-14-16'>{price}</span> Nonmember</div>;
            if (isSoothePassSubscribed) {
                return <>{mmbrObj}{nonMmbrObj}</>
            }
            return <>{nonMmbrObj}{mmbrObj}</>
        }
        return null;
    }
    getCardView(el, it, required) {
        let selected = (includes(this.props.optionIds, el.id)) || (el.default && isEmpty(this.props.optionIds));
        return (<div key={`option-card-${it}`} className={`${selected ? 'card-selected' : ""}`}>
            <MediaCard
                cardTitle={el.title || ""}
                cardImage={this.getProductImage(el)}
                onCardClick={() => this.props.updateOption(el.id, this.state.allowedValues, required)}
                relevantId={camelCase(this.props.prdIndex === 0 ? el.slug : `second_${el.slug}`)}
            >
                <div className={`row `}>
                    <div className='col-xs-10'>
                        <div className='medium-font size-18-24 color-black mb-4 sm-min-height-48'>{el.title || ""}</div>
                    </div>
                    <div className='col-xs-2'>
                        <img src={circularCheck} className='check-icon' />
                    </div>
                    <div className='col-xs-12'>
                        <div className='size-12-20 mb-20 height-40 txt-ellipsis-2'>{get(el, "short_description", "")}</div>
                        {this.optionPrice(el)}
                    </div>
                </div>
            </MediaCard>
        </div>)
    }
    getSlides(prd) {
        let options = get(prd, "options", []),
            optional = get(prd, "optional", false);
        if (!isEmpty(options)) {
            return (map(options, (el, it) => (this.getCardView(el, it, !optional))))
        }
        return null;
    }
    getInitialSlide(prd) {
        let options = get(prd, "options", []);
        if (!isEmpty(options)) {
            let selectedOption = findIndex(options, (el) => (Boolean((includes(this.props.optionIds, el.id)) || (el.default && isEmpty(this.props.optionIds)))))
            if (selectedOption > -1) {
                return selectedOption;
            }
        }
        return 0;
    }
    render() {
        let pOptions = get(this.props, "product.product_options", []);
        let prd = find(pOptions, (p) => (p.display_type === "detailed_radio")),
            slidesTotal = size(get(prd, "options", []));
        if (isEmpty(prd))
            return null;
        return (<div className='mb-48'>
            <div className='size-24-32 color-black medium-font'>{prd.title || ""}</div>
            <div className='size-18-28 color-gray mb-20'>{prd.subtitle || ""}</div>
            <Slider
                initialSlide={this.getInitialSlide(prd)}
                slidesToShow={slidesTotal > 2 ? 2.5 : slidesTotal}
                swipeToSlide={true}
                focusOnSelect={true}
                infinite={false}
                responsive={[
                    {
                        breakpoint: 1300,
                        settings: {
                            slidesToShow: 1.5,
                            arrows: true
                        }
                    },
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 1.25,
                            arrows: false
                        }
                    }
                ]
                }
            >{this.getSlides(prd)}
            </Slider>
        </div>);
    }
}
const mapStateToProps = state => ({
    booking: state.booking,
    client: state.client,
    bookingFlow: state.bookingFlow,
    product: state.booking?.product
});

OptionsCarousel.propTypes = {
    booking: PropTypes.object.isRequired,
    client: PropTypes.object.isRequired,
    bookingFlow: PropTypes.object.isRequired,
    setCart: PropTypes.func.isRequired
};

export default connect(mapStateToProps, { setCart })(OptionsCarousel);