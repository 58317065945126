/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable global-require */
/** **************************************************
class ExampleModal extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      date: '12/25/2018'
    };

    this.onDayChange = this.onDayChange.bind(this);
  }

  onDayChange (day, dayString) {
    this.setState({ date: dayString })
  }

  render() {
    return (
       <SelectTimeWrapper dateFormat='MM/DD/YY'
                        value={ this.state.date }
                        timeFormat={ false }
                        onChange={ this.onDayChange }
                        isValidDate={ (currentDate) => currentDate.isAfter(yesterday); }
       />
    );
  }
}
**************************************************** */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Select from 'react-select';
import { find, get, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { ACTIVE_LANGUAGE_CODE, DE } from '../../constants';

if (ACTIVE_LANGUAGE_CODE === DE) {
  require('moment/locale/de');
}

class SelectTimeWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.availableTimes = this.availableTimes.bind(this);
    this.timeOptions = this.timeOptions.bind(this);
    this.isDisabled = this.isDisabled.bind(this);

    this.state = {
      times: this.availableTimes(props),
    };
  }

  componentDidMount() {
    this.setState({ times: this.availableTimes(this.props) });
  }

  componentWillReceiveProps(nextProps) {
    const { date, minTime } = nextProps;

    if (this.props.date !== date || (this.props.minTime !== minTime)) {
      this.setState({
        times: this.availableTimes(nextProps),
      });
    }
  }

  isDisabled(time) {
    const disabledHours = get(this.props, 'fieldsHolder.listingPageStorage.working_hours', []);
    const dateSelected = moment(this.props.date).format('YYYY-MM-DD');
    const dayOfWeek = moment(dateSelected).format('dddd');
    const obj = find(disabledHours, (el) => el.weekname === dayOfWeek);

    if (isEmpty(disabledHours)) {
      return false;
    }

    if (!obj) {
      return true;
    }

    const fromH = obj.from ? moment.duration(obj.from).asMinutes() : null;
    const tillH = obj.till ? moment.duration(obj.till).asMinutes() : null;
    const formattedTimeH = moment(time, "h:mm A").format("HH:mm");
    const timeH = moment.duration(formattedTimeH).asMinutes();


    if (fromH !== null && tillH !== null) {
      const isWithinRange = timeH >= fromH && timeH <= tillH;
      return !isWithinRange;
    }

    return true;
  }


  availableTimes(props) {
    const selectedDate = props.date;
    const times = [];
    const hours = [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22];
    const quarterHours = [0, 15, 30, 45];

    if (moment(new Date()).isSame(selectedDate, 'day')) {
      const minTime = moment(props.minTime, 'h:mm A');
      const maxTime = moment(props.maxTime, 'h:mm A');
      hours.forEach((hour) => {
        quarterHours.forEach((minutes) => {
          const time = moment(`${hour}:${minutes}`, 'HH:mm');

          if (time.isValid() && time.isSameOrAfter(minTime) && time.isSameOrBefore(maxTime)) {
            if (hour === 22 && minutes > 0) { return; }
            times.push(time.format('h:mm A'));
          }
        });
      });

      return times;
    }

    hours.forEach((hour) => {
      quarterHours.forEach((minutes) => {
        const time = moment(`${hour}:${minutes}`, 'HH:mm');

        if (time.isValid()) {
          if (hour === 22 && minutes > 0) { return; }
          times.push(time.format('h:mm A'));
        }
      });
    });

    return times;
  }

  timeOptions() {
    return this.state.times.map((time) => (<option value={time} key={`time-${time}`}>{time}</option>));
  }

  render() {
    const timeValue = this.props.time;
    const updatedList = this.state.times.map((time) => ({
      value: time,
      label: time,
      isDisabled: this.isDisabled(time),
    }));
    return (
      <Select
        className={this.props.customClassName ? this.props.customClassName : "basic-single"}
        classNamePrefix="select"
        value={this.isDisabled(timeValue)
          ? find(updatedList, (elt) => (elt && !elt.isDisabled))
          || {} : { value: timeValue, label: timeValue }}
        options={updatedList}
        isSearchable={false}
        onChange={(val) => this.props.onChange(val.value || '')}
        menuShouldBlockScroll
      />
    );
  }
}

const mapStateToProps = (state) => ({
  fieldsHolder: state.fieldsHolder,
});

SelectTimeWrapper.propTypes = {
  date: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  maxTime: PropTypes.string.isRequired,
  minTime: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {})(SelectTimeWrapper);
