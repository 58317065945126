import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import { isEmpty, get, startCase } from 'lodash';
import TextWithIcon from '../../Shared/TextWithIcon';
import { PlaylistAddCheck, Room } from '@material-ui/icons';

class AppointmentAddress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const address = get(this.props, 'booking.cart.address', null);
    const isCompleteAddress = get(address, 'complete', false);
    if (!isEmpty(address)) {
      return (<>
        <TextWithIcon
          muiIcon={<Room />}
          title="Location"
          details={(
            <div className="sm-mb-12">
              {address.address_line_1}
              <br />
              {address.city}
              ,
              {' '}
              {address.state}
              {' '}
              {address.zip_code}
            </div>
          )}
          displayBorder
          extraClass="sm-display-block"
        >
          {isCompleteAddress ? null : (
            <button
              type="button"
              className="btn infoButton"
              onClick={() => {
                this.props.showFinishAddress();
              }}
            >
              More details needed
            </button>
          )}
        </TextWithIcon>
        <TextWithIcon
          muiIcon={<PlaylistAddCheck />}
          title="Arrival Instructions"
          details={(
            <div className="sm-mb-12">
              {address.parking_info ? <>
                {address.parking_info}
                <br />
                <br />
              </> : null}
              <ul>
                {address.parking_type ? <li>{startCase(address.parking_type)}</li> : null}
                <li>{address.address_type === "home" ? "Private Residence" : startCase(address.address_type)}</li>
                <li>{address.has_cats ? "Has Cats" : "No Cats"}</li>
                <li>{address.has_dogs ? "Has Dogs" : "No Dogs"}</li>
              </ul>
            </div>
          )}
          extraClass="sm-display-block"
        >
        </TextWithIcon>
      </>
      );
    }
    return null;
  }
}
const mapStateToProps = (state) => ({
  booking: state.booking,
  addresses: state.addresses,
  client: state.client,
});

AppointmentAddress.propTypes = {
  booking: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
};

export default withLocalize(connect(mapStateToProps)(AppointmentAddress));
