import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  map, get, find, isEmpty, includes, size, camelCase,
} from 'lodash';
import { setCart } from '../../../Actions';
import StyledRadioTabs from '../../Shared/StyledRadioTabs';

class SessionLength extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allowedValues: [],
      selection: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (size(prevProps.optionIds) !== size(this.props.optionIds)) {
      const pOptions = get(this.props, 'booking.currentOption.sub_product_options', []);
      const prd = find(pOptions, (p) => (p.display_type === 'bubble' && p.slug === 'massage_length'));
      const options = get(prd, 'options', []);
      if (!isEmpty(options)) {
        let allowedValues = [];
        let sel = find(options, (op) => (includes(this.props.optionIds, op.id)));
        if (isEmpty(sel)) {
          sel = find(options, (op) => (op.default));
        }
        allowedValues = map(options, (o) => (o.id));
        this.setState({ allowedValues, selection: get(sel, 'id', null) }, () => {
          this.props.updateOption(get(sel, 'id', null), this.state.allowedValues, !get(prd, 'optional', false), get(sel, 'title', ''), get(sel, 'price', ''), get(sel, 'member_price', ''), get(sel, 'raw_price', 0), get(sel, 'raw_member_price', 0));
        });
      }
    }
  }

  render() {
    const pOptions = get(this.props, 'booking.currentOption.sub_product_options', []);
    const prd = find(pOptions, (p) => (p.display_type === 'bubble' && p.slug === 'massage_length'));
    const options = get(prd, 'options', []);
    const optional = get(prd, 'optional', false);
    if (isEmpty(prd)) return null;
    return (
      <div className="ptb-20 br-b-opaque d-flex-only justify-content-spaced align-items-center">
        <div className="size-16-20 color-black medium-font">{get(prd, "title", "Length")}</div>
        <div>
          <StyledRadioTabs
            name="session-length"
            optionsArray={map(options, (el) => ({
              label: el.title, value: el.id, checked: el.id === this.state.selection, extras: el, relevantId: camelCase(this.props.isFirstCP ? get(el, 'slug', '') : `second_${get(el, 'slug', '')}`),
            }))}
            action={(val, title, extras) => {
              this.setState({ selection: Number(val) });
              this.props.updateOption(val, this.state.allowedValues, !optional, title, get(extras, 'price', ''), get(extras, 'member_price', ''), get(extras, 'raw_price', 0), get(extras, 'raw_member_price', 0));
            }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  booking: state.booking,
  client: state.client,
  bookingFlow: state.bookingFlow,
  product: state.booking.product,
});

SessionLength.propTypes = {
  booking: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
  bookingFlow: PropTypes.object.isRequired,
  setCart: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { setCart })(SessionLength);
