import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import { setBookingFlowStep, setCart, setAddresses, setBookingFlowPreviousStep, setUpcomingAppointmentId, setProducts, setField } from '../../../Actions';
import { defaultErrorMessage, pleaseAddACreditCardError, ROUTES, blankHref, HotJar, termsAndConditions, isGuestUser, hasUuid } from '../../../constants';
import { STEPS } from '../Shared/constants';
import PaymentMethod from './PaymentMethod';
import OrderSummary from './OrderSummary';
import TipProvider from './TipProvider';
import ApplicablePoints from './ApplicablePoints';
import '../../BookingFlow/Payment/Assets/Styles/Summary.css';
import './Assets/Styles/index.css';
import { seBookingComplete, sendPixelData, seMembershipComplete, bookingConversionImpactRadius } from '../../Shared/WebAnalytics.js';
import _, { get, find, map } from 'lodash';
import Linkify from 'react-linkify';
import ErrorModal from '../../Shared/ErrorModal';
import Modal from '../../Shared/Modal';
import { decode } from '../Shared/encode';
import { acceptTerms, checkoutBooking, getCartDetails } from '../Shared/helpers';
import CTAButton from '../../Shared/CTAButton';
import Slide from "@material-ui/core/Slide";
import VerifyPhoneNumber from './VerifyPhoneNumber';
import GiftCardsAndPromoCodes from './GiftCardsAndPromoCodes.js';

HotJar();

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      billing: { top_billing_line_items: '', bottom_billing_line_items: '', subtotal: '' },
      isChecked: true,
      showClientTermsModal: false,
      showErrorModal: false,
      view: 'payment',
      width: window.innerWidth,
      height: 300,
      submitted: false
    };

    this.book = this.book.bind(this);
    this.handleChecked = this.handleChecked.bind(this);
    this.errorModal = this.errorModal.bind(this);
    this.clientTermsModal = this.clientTermsModal.bind(this);
    this.acceptTermsAndConditions = this.acceptTermsAndConditions.bind(this);
    this.checkForTermsAndBook = this.checkForTermsAndBook.bind(this);
    this.updateSummaryBillingData = this.updateSummaryBillingData.bind(this);
    this.hasTippableProducts = this.hasTippableProducts.bind(this);
    this.checkForCreditCard = this.checkForCreditCard.bind(this);
    this.sendPixelDataAboutAppointment = this.sendPixelDataAboutAppointment.bind(this);
    this.registerBookingCompleteAnalytic = this.registerBookingCompleteAnalytic.bind(this);
    this.handleCheckoutResponse = this.handleCheckoutResponse.bind(this);
    this.submitRequest = this.submitRequest.bind(this);
    this.getCartCallbackFunc = this.getCartCallbackFunc.bind(this);
    this.goToNextStep = this.goToNextStep.bind(this);
    this.errorHandler = this.errorHandler.bind(this);
    this.soothePassFinePrint = this.soothePassFinePrint.bind(this);
    this.registerMembershipCompleteAnalytic = this.registerMembershipCompleteAnalytic.bind(this);
    this.getRelevantCTACopy = this.getRelevantCTACopy.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let rebook = get(this.props, "booking.cart.rebook", false);
    if (rebook) {
      this.props.toggleNavBarStyle(false);
      this.props.changeBackground("");
    } else if (get(this.props, "fieldsHolder.listingPageStorage.bannerimage", "")) {
      this.props.toggleNavBarStyle(false);
      this.props.changeBackground(get(this.props, "fieldsHolder.listingPageStorage.bannerimage", ""));
    } else {
      this.props.toggleNavBarStyle(true);
      this.props.changeBackground('none');
    }
    this.updateSummaryBillingData();
    let previousStep = find(STEPS, (step) => (step.id === "REVIEW"));
    this.props.setBookingFlowPreviousStep(previousStep);
  }
  componentDidUpdate() {
    let anchorTags;

    if (document.getElementById('client-terms') !== null) {
      anchorTags = document.querySelectorAll('#client-terms a');

      for (let a of anchorTags) {
        a.setAttribute('target', '_blank');
      }
    }
  }

  goToNextStep() {
    if (isGuestUser()) {
      let nextStep = find(STEPS, (step) => (step.id === "CONFIRMATION"));
      this.props.setBookingFlowStepThroughParent(nextStep);
    } else {
      // set apt id
      this.props.setUpcomingAppointmentId(get(this.props, "booking.cart.id", ""))

      if (isGuestUser()) {
        this.props.setField("csrfToken", "")
        this.props.setField("listingPageStorage", null);
      }
      bookingConversionImpactRadius(get(this.props, "booking.cart", {}), get(this.props, "client", {}));
      // reset booking
      this.props.setProducts({ products: [] });
      this.props.setCart({ cart: {}, addressId: null, product: {}, cartProducts: [], currentCartProduct: {} });

      setTimeout(() => { window.location = "/appointment_management/"; }, 3500)
    }
  }

  getRelevantCTACopy() {
    let papEnabled = get(this.props, "booking.cart.pickAProEnabled", false),
      proId = get(this.props, "booking.cart.therapist_preferences.0.id", "");
    if (papEnabled && proId) {
      return "Book Instantly";
    }
    return "Request Appointment";
  }

  acceptTermsAndConditions() {
    let cartId = get(this.props, "booking.cart.id", "")
    acceptTerms(cartId, () => {
      this.setState({ showClientTermsModal: false }, () => this.submitRequest(true));
    }, this.errorHandler)
  }
  sendPixelDataAboutAppointment(respObj) {
    const { backToBack, billing, id } = respObj;
    let cart = this.props.booking.cart;
    let data = {
      currency: billing.currency,
      id,
      receiptInfoType: cart.cartProducts.map((cp) => cp.title),
      sessionLength: cart.cartProducts.map((cp) => cp.session_length),
      totalInt: billing.total_price,
      appointmentType: (backToBack.enabled ? 'Back to Back' : 'Single'),
      service_category: get(this.props, "booking.product.title", "").toLowerCase()
    };
    sendPixelData(data, this.props.client.email, this.props.client.first_time_booker);
  }

  registerBookingCompleteAnalytic() {

    let subscription_tier_id = get(this.props, "booking.cart.info_fields.soothe_pass.subscription_tier_id", ""),
      subscription_tier_name = get(this.props, "booking.cart.info_fields.soothe_pass.subscription_tier_name", "");
    let cart = this.props.booking.cart;
    let subscriptionTierId = _.get(this.props, 'client.subscription_tier_id', null);
    if (!subscriptionTierId) {
      subscriptionTierId = _.get(cart, 'subscriptions.subscription_tier_id', null);
    }
    let subscriptionTiers = get(cart, "subscriptions.subscription_tiers", []);
    let used_points = get(this.props, "booking.cart.billing.points_used", 0),
      used_cash = get(this.props, "booking.cart.billing.total_price", "") !== "0.0";
    seBookingComplete(cart, get(this.props, "booking.product.title", "").toLowerCase(), this.props.client.first_time_booker, subscriptionTiers, subscriptionTierId,
      { membership_type: subscription_tier_name, membership_id: subscription_tier_id, used_points, used_cash }, "booking_23_control");
  }

  registerMembershipCompleteAnalytic() {
    let alreadyAppliedToCart = get(this.props, "booking.cart.info_fields.soothe_pass.subscription_applied_to_cart", false),
      subscription_tier_id = get(this.props, "booking.cart.info_fields.soothe_pass.subscription_tier_id", ""),
      subscription_tier_name = get(this.props, "booking.cart.info_fields.soothe_pass.subscription_tier_name", ""),
      { rebook, billing, address, cartProducts } = get(this.props, "booking.cart", {});

    if (alreadyAppliedToCart) {
      seMembershipComplete({
        membership_type: subscription_tier_name,
        first_time_booking: get(this.props, "client.first_time_booker", false),
        membership_id: subscription_tier_id,
        service_category: get(this.props, "booking.product.title", "").toLowerCase(),
        booking_city: get(address, "city", ""),
        is_rebook: rebook,
        revenue_amount: get(billing, "subtotal", ""),
        currency_type: get(billing, "currency", "$"),
        service_nb_people: cartProducts ? cartProducts.length : 0,
        used_points: get(billing, "points_used", 0),
        used_cash: get(billing, "total_price", "") !== "0.0"
      })
    }
  }

  handleCheckoutResponse(data) {
    const { result } = data;
    if (result) {
      this.props.assignToCart({ cart: decode(data.cart) });
      this.sendPixelDataAboutAppointment(get(this.props, "booking.cart", null));

      this.registerBookingCompleteAnalytic();
      this.registerMembershipCompleteAnalytic();

      this.props.setLoaderFlag(false);
      this.goToNextStep();
    } else {
      this.props.setLoaderFlag(false);
      this.setState({ submitted: false, showErrorModal: true, error: _.get(data, 'errors.0.message', defaultErrorMessage) });
    }
  }

  book() {
    let cartBeforeCheckout = this.props.booking.cart;

    this.setState({ submitted: true });
    this.props.setLoaderFlag(true);
    checkoutBooking(cartBeforeCheckout.id, (response) => {
      this.handleCheckoutResponse(response.data);
    }, (err) => {
      let stts = get(err, "response.status", null);
      this.props.setLoaderFlag(false);
      if (stts === 422 || stts === 500) {
        this.setState({ submitted: false, showErrorModal: true, error: _.get(err, 'response.data.errors.0.message', defaultErrorMessage) });
      }
    })
  }

  checkForTermsAndBook(termsAccepted) {
    const cart = get(this.props, "booking.cart", null);
    let alreadyAppliedToCart = get(this.props, "booking.cart.info_fields.soothe_pass.subscription_applied_to_cart", false);

    if (alreadyAppliedToCart && !this.state.isChecked) {
      this.setState({ showErrorModal: true, error: "Please accept the terms and conditions by ticking the checkbox" });
      return false;
    } else if (cart.terms.client_needs_to_accepted_terms && !termsAccepted) {
      this.setState({ showClientTermsModal: true });
      return false;
    }
    return true;
  }

  checkForCreditCard() {
    let cartCreditCardId = _.get(this.props.booking, 'cart.billing.credit_card.id', null);
    if (_.isEmpty(this.props.creditCards) || !(cartCreditCardId)) {
      this.setState({ showErrorModal: true, error: pleaseAddACreditCardError });
      return false;
    }
    return true;
  }

  submitRequest(termsAccepted = false) {
    if (!this.state.submitted && this.checkForCreditCard() && this.checkForTermsAndBook(termsAccepted) && !(get(this.props, "client.phone_verification_required", false) && get(this.props, "client.ab_tests.mobile_phone_verification", "") === "needs_verification")) {
      this.book();
    }
  }

  handleChecked() {
    this.setState({ isChecked: !this.state.isChecked });
  }

  getCartCallbackFunc(response) {
    this.props.assignToCart({ cart: decode(response.data.cart) });
  }

  errorHandler(err) {
    this.props.displayError(get(err, 'response.data.errors.0.message', defaultErrorMessage));
  }
  updateSummaryBillingData() {
    getCartDetails(get(this.props, 'booking.cart.id', ''), this.getCartCallbackFunc, this.errorHandler);
  }


  clientTermsModal() {
    let cart = this.props.booking.cart;

    if (cart.terms && cart.terms.client_needs_to_accepted_terms) {
      return (<div className='payments-redesign'>
        <Modal isOpen={this.state.showClientTermsModal} title={cart.terms.client_title_terms || ''} close={() => this.setState({ showClientTermsModal: false })} >
          <Linkify>
            <div id='client-terms-redesign'>
              {cart.terms.client_terms.split('\n').map((item, key) => {
                let marginValue = key > 0 && key < 20 ? '30px' : '0';
                return <span style={{ marginLeft: marginValue }} key={key}>{item}<br /></span>
              })
              }
            </div>
          </Linkify>

          <div className='terms-btn-container'>
            <a id="acceptCovidTermsButton" className='accept-terms-btn contentInversePrimary' href={blankHref} onClick={this.acceptTermsAndConditions}>I Accept</a>
          </div>

          <div className='back-btn-container'>
            <a className='btn contentInversePrimary' href={blankHref} style={{ color: '#F58266' }} onClick={() => this.setState({ showClientTermsModal: false })} >Cancel</a>
          </div>
        </Modal>
      </div>
      );
    }
  }

  errorModal() {
    return (
      <ErrorModal isOpen={this.state.showErrorModal} close={() => { this.setState({ showErrorModal: false, error: null }); }}>
        <p>{this.state.error}</p>
      </ErrorModal>
    )
  }

  soothePassFinePrint() {
    let fine_print = get(this.props, "booking.cart.info_fields.soothe_pass.fine_print", ""),
      fine_print_text_links = get(this.props, "booking.cart.info_fields.soothe_pass.fine_print_text_links", []),
      isSoothePassAvailable = get(this.props, "booking.cart.info_fields.soothe_pass.available", false),
      isSoothePassSubscribed = get(this.props, "booking.cart.info_fields.soothe_pass.subscribed", false),
      alreadyAppliedToCart = get(this.props, "booking.cart.info_fields.soothe_pass.subscription_applied_to_cart", false);

    if (isSoothePassAvailable && alreadyAppliedToCart && fine_print && !isSoothePassSubscribed) {
      let temp = null;
      map(fine_print_text_links, (item) => {
        const { text, link } = item;
        let splittedArr = fine_print.split(text);
        if (!temp) {
          temp = splittedArr[0]
        }
        let part2 = splittedArr[1];
        temp = (<>{temp}{' '}{<a className='underline BluePrimary' href={link} target="_blank" rel="noopener noreferrer">{text}</a>}{part2}</>)
      })
      return (<div className='summary-wrapper'>
        <div className='annual-subscription-disclaimer' style={{ maxWidth: "none" }}>
          {/* <div className='check-box'>
            <input type='checkbox' value={this.state.isChecked} className='' onChange={this.handleChecked} id="termsCheckbox" />
          </div> */}
          <div className='check-box-copy annual-disclaimer' style={{ fontSize: '12px', lineHeight: '20px', width: '100%', padding: '0px' }}>
            {temp}
          </div>
        </div>
      </div>)
    }
  }

  hasTippableProducts() {
    let toTip = [];
    let cartProducts = get(this.props, "booking.cart.cartProducts", []);
    if (!_.isEmpty(cartProducts)) {
      toTip = _.filter(cartProducts, (el) => (_.get(el, "tip.can_tip", false) && _.get(el, "tip.tip_changes_allowed", false) && !el.parent_id));
    }
    return !_.isEmpty(toTip);
  }

  render() {
    let alreadyAppliedToCart = get(this.props, "booking.cart.info_fields.soothe_pass.subscription_applied_to_cart", false),
      guest = Boolean(hasUuid() && get(this.props, "fieldsHolder.listingPageStorage.bannerimage", "")),
      rebook = get(this.props, "booking.cart.rebook", false),
      instant_confirmation = get(this.props, "booking.instant_confirmation", false);

    return (<Slide direction="up" in={true} mountOnEnter unmountOnExit>
      <div>
        <div className='mb-106 sm-mb-177'>
          <div className={guest || rebook ? "max-w-50-vw" : 'max-width-55'}>
            <div className={`size-44-52 sm-size-32-40 ${guest || rebook ? 'color-white txt-shadow sm-color-black' : 'color-black'} medium-font txt-center sm-text-left mb-24`}>Checkout</div>

            <div className={guest || rebook ? 'background-primary border-radius-16 pt-24' : ""}>
              <div className={`p-0-60-24 ${guest || rebook ? "pt-24" : ""}`}>
                <div className='br-btm-gray'>
                  <VerifyPhoneNumber showPersonaInParent={this.props.initiateOrShowPersona} removePersona={this.props.removePersona} />
                  <PaymentMethod updateSummaryBillingData={this.updateSummaryBillingData} assignToCart={this.props.assignToCart} setLoaderFlag={this.props.setLoaderFlag} />
                  <GiftCardsAndPromoCodes updateSummaryBillingData={this.updateSummaryBillingData} assignToCart={this.props.assignToCart} />
                </div>
                {this.hasTippableProducts() ? <div className='br-btm-gray'>
                  <TipProvider updateSummaryBillingData={this.updateSummaryBillingData} assignToCart={this.props.assignToCart} setLoaderFlag={this.props.setLoaderFlag} />
                </div> : null}
                <div className='br-btm-gray'>
                  <ApplicablePoints assignToCart={this.props.assignToCart} />
                </div>
                <OrderSummary />
                {this.soothePassFinePrint()}
              </div>
              <CTAButton text={rebook && instant_confirmation ? "Confirm Instantly" : alreadyAppliedToCart ? "Subscribe and Request now" : "Request appointment"} disabled={Boolean(get(this.props, "client.phone_verification_required", false) && get(this.props, "client.ab_tests.mobile_phone_verification", "") === "needs_verification" && !hasUuid() && this.props.initiateOrShowPersona)} submitted={this.state.submitted}
                additionalWrapperClass="justify-content-center d-block-imp"
                action={() => {
                  this.submitRequest();
                }}
                additionalClass={rebook ? "full-width-btn" : ""}
                relevantId="requestAppointmentButton"
                legalCopyOnTop={(<div className="mb-16 contentSecondary size-12-20">
                  By clicking the button below, I acknowledge that I am requesting services from an independent provider marketing their services on Soothe's platform.
                </div>)}
              />
            </div>
          </div>
        </div>
        {this.clientTermsModal()}
        {this.errorModal()}
      </div>
    </Slide>
    );
  }
}
const mapStateToProps = state => ({
  booking: state.booking,
  addresses: state.addresses,
  client: state.client,
  creditCards: state.creditCards,
  bookingFlow: state.bookingFlow,
  fieldsHolder: state.fieldsHolder
});

Index.propTypes = {
  booking: PropTypes.object.isRequired,
  bookingFlow: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
  creditCards: PropTypes.array.isRequired,
  setBookingFlowStep: PropTypes.func.isRequired,
  setCart: PropTypes.func.isRequired,
  setAddresses: PropTypes.func.isRequired
};
export default withLocalize(connect(mapStateToProps, {
  setBookingFlowStep, setCart, setAddresses, setBookingFlowPreviousStep, setUpcomingAppointmentId, setProducts, setField
})(Index));
