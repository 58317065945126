import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setCart } from '../../../Actions';
import CenteredGrids from '../../Shared/CenteredGrids';
import { map, get, find, isEmpty, includes, camelCase } from 'lodash';

class SessionLength extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            allowedValues: []
        }
        this.getSessionLengthOptions = this.getSessionLengthOptions.bind(this);
    }
    componentDidMount() {
        let pOptions = get(this.props, "product.product_options", []),
            prd = find(pOptions, (p) => (p.display_type === "bubble" && p.slug === "massage_length")),
            options = get(prd, "options", []);
        if (!isEmpty(options)) {
            let allowedValues = [];
            allowedValues = map(options, (o) => (o.id));
            this.setState({ allowedValues })
        }
    }
    getSessionLengthOptions(prd) {
        let options = get(prd, "options", []),
            optional = get(prd, "optional", false);
        if (!isEmpty(options)) {
            return map(options, (el) => ({
                title: el.title || '',
                subtitle: el.sub_title || '',
                value: el.id,
                action: (val) => {
                    this.props.updateOption(val, this.state.allowedValues, !optional)
                },
                selected: (includes(this.props.optionIds, el.id)) || (el.default && isEmpty(this.props.optionIds)),
                relevantId: camelCase(this.props.prdIndex === 0 ? get(el, "slug", "") : `second_${get(el, "slug", "")}`)
            }));
        }
        return [];
    }
    render() {
        let pOptions = get(this.props, "product.product_options", []);
        let prd = find(pOptions, (p) => (p.display_type === "bubble" && p.slug === "massage_length"));
        if (isEmpty(prd))
            return null;
        return (
            <div className='mb-48'>
                <div className='size-24-32 color-black medium-font'>{prd.title || ""}</div>
                {prd.subtitle ? <div className='size-18-28 color-gray mb-20'>{prd.subtitle || ""}</div> : null}
                <div className='txt-center'>
                    <CenteredGrids
                        grids={this.getSessionLengthOptions(prd)}
                    />
                </div>
            </div>)



    }
}

const mapStateToProps = state => ({
    booking: state.booking,
    client: state.client,
    bookingFlow: state.bookingFlow,
    product: state.booking?.product
});

SessionLength.propTypes = {
    booking: PropTypes.object.isRequired,
    client: PropTypes.object.isRequired,
    bookingFlow: PropTypes.object.isRequired,
    setCart: PropTypes.func.isRequired
};

export default connect(mapStateToProps, { setCart })(SessionLength);