import React from "react";
import { Drawer, SwipeableDrawer } from "@material-ui/core";
import { get, isEmpty, map, startCase } from "lodash";
import { connect } from "react-redux";
import placeholder from "../../../Assets/Images/Mask_img.svg";
import TextWithIcon from "../../Shared/TextWithIcon";
import { ArrowBack } from "@material-ui/icons";
import ProProfileDetails from "../../BookingFlowForMarketplace/ProviderPickStep/ProProfileDetails";

class EventProList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openProProfile: false,
            pro: {},
        }
    }
    render() {
        const pros = get(this.props, 'appointment.event_pros', []);
        const totalPros = get(this.props, 'appointment.event.providers_number', []);
        const { close } = this.props;
        const { openProProfile, pro } = this.state;
        if (isEmpty(pros)) {
            return null;
        }
        return (<>
            <SwipeableDrawer
                style={{ zIndex: 9999999999999 }}
                PaperProps={{ style: { borderRadius: '16px 16px 0px 0px', zIndex: 9999999999999 } }}
                anchor="bottom"
                open={!openProProfile}
                onClose={close}
                disableSwipeToOpen
                onOpen={() => { }}
            >
                <div className="p-16 flex-centered">
                    <div className="mb-2 flex-1">
                        <button
                            type="button"
                            className="btn btn-link contentPrimary size-20-28 deco-none"
                            onClick={close}
                        >
                            <ArrowBack />
                        </button>
                    </div>
                    <div className="size-14-16 contentPrimary font-weight-bold flex-1">{totalPros} Providers</div>
                </div>
                {map(pros, (prov, it) => {
                    const pro = get(prov, "pro", {});
                    const { first_name, last_name, avatar_url } = pro;
                    return (
                        <div key={`event_pro_${it}`} className={prov.status === "pending" ? "" : "clickable"} onClick={() => {
                            if (prov.status !== "pending") {
                                this.setState({ openProProfile: true, pro });
                            }
                        }}
                        ><TextWithIcon
                                cstmImg={<img src={avatar_url || placeholder} alt="pro avatar" className="br-rd-50 w-h-56" />}
                                title={<div className='font-weight-bold'>{first_name ? `${first_name} ${last_name}` : "Unassigned Provider"}</div>}
                                details={prov.status !== "pending" ? startCase(prov.status) : "Waiting for Response"}
                                displayArrow={prov.status !== "pending"}
                                displayBorder
                            />
                        </div>
                    )
                })}
            </SwipeableDrawer>
            <Drawer style={{ zIndex: 999999999999 }} anchor="right" open={openProProfile} onClose={() => this.setState({ openProProfile: false })}>
                {openProProfile ? (
                    <ProProfileDetails
                        fieldsHolder={this.props.fieldsHolder}
                        pro={pro}
                        showProDetailsToggle={() => this.setState({ openProProfile: false })}
                    // isPrivate
                    />
                ) : null}
            </Drawer>
        </>
        );
    }
}
const mapStateToProps = (state) => ({
    client: state.client,
    appointment: state.appointment,
    chats: state.chats,
    currentChat: state.currentChat,
    abTest: state.abTest,
    upcomingAppointmentId: state.upcomingAppointmentId,
    rebookOptions: state.rebookOptions,
    fieldsHolder: state.fieldsHolder,
});
export default connect(mapStateToProps, {})(EventProList);