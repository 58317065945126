/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { Route, Router } from 'react-router-dom';
import { Translate } from 'react-localize-redux';
import App from '../App';
import SoothePlus from '../Containers/SoothePlus';
import PhoneNumberOptinPage from '../Containers/LandingPages/PhoneNumberOptin';
import CoronaLandingPage from '../Containers/LandingPages/Covid19';
import SignupModal from '../Containers/Shared/SignupModal';
import history from './history';
import SubscriptionLanding from '../Containers/SoothePlus/SubscriptionLanding';
import GiftCardsRedesign from '../Containers/GiftCardsRedesign';
import GiftCardsRedesignCheckout from '../Containers/GiftCardsRedesign/Checkout';
import AppointmentManagement from '../Containers/AppointmentManagement';
import AppointmentsList from '../Containers/AppointmentsList';
import Inbox from '../Containers/Inbox';
import BookingRedirection from '../Containers/Shared/BookingRedirection';
import Events from '../Containers/Events';
// import AccountSettings from '../Containers/AccountSettings';
export const reactRoutes = (
  <Translate>
    {({ translate }) => (
      <Router history={history}>
        <>
          <Route path="/" component={App} />
          {/* why not change to '/booking' along with some BE config fix */}
          <Route path="/booking/:step?" component={BookingRedirection} />
          <Route exact path="/soothe-plus/subscribe" component={SoothePlus} />
          <Route exact path="/soothe-plus/" component={SubscriptionLanding} />
          <Route exact path="/phone-number-optin/" component={PhoneNumberOptinPage} />
          <Route exact path="/trust/coronavirus/" component={CoronaLandingPage} />
          <Route exact path={translate('routes.userSignin')} component={() => <SignupModal />} />
          <Route exact path="/massage-cards/" component={GiftCardsRedesign} />
          <Route exact path="/massage-cards/checkout/" component={GiftCardsRedesignCheckout} />
          <Route exact path="/wellness-services/" component={GiftCardsRedesign} />
          <Route exact path="/wellness-services/checkout/" component={GiftCardsRedesignCheckout} />
          <Route exact path="/gift-cards/" component={GiftCardsRedesign} />
          <Route exact path="/gift-cards/checkout/" component={GiftCardsRedesignCheckout} />
          <Route exact path="/appointment_management/" component={AppointmentManagement} />
          <Route exact path="/appointments/" component={AppointmentsList} />
          <Route exact path="/inbox/" component={Inbox} />
          {/* <Route exact path='/account/' component={AccountSettings} /> */}
          <Route path="/events-booking" component={Events} />
        </>
      </Router>
    )}
  </Translate>
);
